import React from 'react';
import { TooltipProps } from 'recharts';
import './CustomTooltip.scss';

const CustomTooltip: React.FC<TooltipProps<number, string>> = ({
  active,
  payload,
  label,
  coordinate,
}) => {
  if (active && payload && payload.length && coordinate) {
    const { x, y } = coordinate;

    const tooltipStyle: React.CSSProperties = {
      left: x,
      top: (y as number) - 20,
    };

    return (
      <div className="custom-tooltip" style={tooltipStyle}>
        <p className="tooltip-label">{label}</p>
        <p className="tooltip-item">{`Transactions: ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
