import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { routesClient } from '../../../general/routes';

interface MetaProps {
  title: string | null;
  content: string;
  metaName: string;
}

interface DynamicMetaTagProps {
  isIndexing?: boolean;
}

const DynamicMetaTag: React.FC<DynamicMetaTagProps> = ({ isIndexing = true }) => {
  const location = useLocation();

  const getMeta = (): MetaProps | null => {
    if (!isIndexing) {
      return {
        title: null,
        content: 'noindex,nofollow',
        metaName: 'robots',
      };
    }

    switch (location.pathname) {
      case routesClient.home.path:
        return {
          title: routesClient.home.metaTitle ?? null,
          content: routesClient.home.metaDescription ?? '',
          metaName: 'description',
        };
      case routesClient.aboutUs.path:
        return {
          title: routesClient.aboutUs.metaTitle ?? null,
          content: routesClient.aboutUs.metaDescription ?? '',
          metaName: 'description',
        };
      default:
        return null;
    }
  };

  const meta = getMeta();

  return (
    meta && (
      <Helmet>
        {meta.title && <title>{meta.title}</title>}
        <meta name={meta.metaName} content={meta.content} />
      </Helmet>
    )
  );
};

export default DynamicMetaTag;
