import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsicModelDTO, ProductsStoreAddress } from '../../types/types';

export interface GetInTouchState {
  choosenStores: ProductsStoreAddress[];
  asicModelToEdit: AsicModelDTO | null;
}

const initialState: GetInTouchState = {
  choosenStores: [],
  asicModelToEdit: null,
};

const ProductSlicer = createSlice({
  name: 'product',
  initialState,
  reducers: {
    addChoosenStore: (state, action: PayloadAction<ProductsStoreAddress>) => {
      state.choosenStores.push(action.payload);
    },
    removeChoosenStore: (state, action: PayloadAction<string>) => {
      state.choosenStores = state.choosenStores.filter((store) => store.id !== action.payload);
    },
    resetChoosenStores: (state) => {
      state.choosenStores = [];
    },
    addAsicModeToEdit: (state, action: PayloadAction<AsicModelDTO | null>) => {
      state.asicModelToEdit = action.payload;
    },
  },
});

export const { addChoosenStore, removeChoosenStore, resetChoosenStores, addAsicModeToEdit } =
  ProductSlicer.actions;
export default ProductSlicer.reducer;
