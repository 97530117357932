import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import ArrowLink from '../../../general/assets/svg/ArrowLink';
import CartBucketIncon from '../../../general/assets/svg/CartBucketIncon';
import LightningIcon from '../../../general/assets/svg/LightningIcon';
import ShoppingBasket from '../../../general/assets/svg/ShoppingBasket';
import Preloader from '../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../general/queryKeys';
import { routesClient } from '../../../general/routes';
import createHomePageService from '../../../general/services/homePage';
import { clientInstance } from '../../../general/services/main/axiosInstances';
import { images } from '../../../general/StaticData';
import { ProductMax } from '../../../general/types/types';
import WishlistBtn from '../buttons/wishlist/WishlistBtn';

interface ShowcaseProps {
  setIsAuth: (value: boolean) => void;
}

const ProductPrice: React.FC<{ product: any }> = ({ product }) => {
  const price = product?.price;
  const percentageDiscount = product?.percentageDiscount;

  // Проверяем, существует ли цена и процент скидки перед использованием toFixed
  const priceWithoutDiscount = price !== undefined ? price.toFixed(2) : 'N/A';
  const priceWithDiscount =
    price !== undefined && percentageDiscount !== undefined
      ? (price - price * (percentageDiscount / 100)).toFixed(2)
      : 'N/A';

  return (
    <>
      <span className="banner-price-status__price-without-discount">{`${priceWithoutDiscount}$`}</span>
      <span className="banner-price-status__price-with-discount">{`${priceWithDiscount}$`}</span>
    </>
  );
};

const ProductDetails: React.FC<{ product: any }> = ({ product }) => {
  const price = product?.price;
  const percentageDiscount = product?.percentageDiscount;
  const hashrate = product?.hashrate;
  const countryLocation = product?.countryLocation;

  // Проверяем наличие hashrate перед использованием split
  const hashrateParts = hashrate ? hashrate.split(' ') : [];
  const hashrateValue = hashrateParts[0] ? parseFloat(hashrateParts[0]) : null;
  const hashrateUnit = hashrateParts[1] ? hashrateParts[1].split('/')[0] : null;

  const priceWithDiscount =
    price !== undefined && percentageDiscount !== undefined && hashrateValue !== null
      ? ((price - price * (percentageDiscount / 100)) / hashrateValue).toFixed(2)
      : 'N/A';

  const priceWithoutDiscount =
    price !== undefined && hashrateValue !== null ? (price / hashrateValue).toFixed(2) : 'N/A';

  return (
    <>
      {percentageDiscount !== 0 ? (
        <>
          <span className="banner-price-status__price-without-discount">
            {priceWithoutDiscount}
          </span>
          <span className="banner-price-status__price-with-discount">{priceWithDiscount}$</span>
        </>
      ) : (
        <>
          <span className="banner-price-status__price-without-discount">
            {priceWithoutDiscount}
          </span>
        </>
      )}
      $ per {hashrateUnit ?? 'N/A'} | {countryLocation ?? 'Unknown location'}
    </>
  );
};

const Showcase: React.FC<ShowcaseProps> = ({ setIsAuth }) => {
  const { data: showcase, isLoading: isLoadingShowCase } = useCustomQuery<ProductMax[]>(
    queryKeys.SHOW_CASE,
    () => createHomePageService(clientInstance).getShowcase(),
  );

  return (
    <>
      {showcase && showcase.length >= 1 && !isLoadingShowCase ? (
        <div className="banner-section-content">
          <div className="banner-section-content__element_main">
            <div className="banner-section-content__element_main__container">
              <div className="banner-section-content__element_main__action__title">
                <div className="banner-section-content__element_main__title">
                  <span>
                    {' '}
                    <Link to={routesClient.product.path + `/${showcase[0]?.url}`}>
                      {showcase[0]?.name}
                    </Link>
                  </span>
                  <span>
                    {showcase[0]?.hashrate} | {showcase[0]?.energyConsumption}w
                  </span>
                </div>
                <div className="banner-section-content__element_main__action">
                  <WishlistBtn setIsAuthModalOpen={setIsAuth} productId={showcase[0]?.id} />
                  {/* <CompareBtn product={showcase[0]} categoryId={showcase[0]?.categoryId} /> */}
                </div>
              </div>
              <Link to={routesClient.product.path + `/${showcase[0]?.url}`}>
                <img
                  src={
                    showcase[0]?.image?.url === null || showcase[0]?.image?.url === undefined
                      ? images.defaultImg
                      : showcase[0]?.image?.url
                  }
                  alt=""
                />
              </Link>
              <div className="banner-section-content__element_main__content">
                <div className="banner-section-content__element_main__content__container">
                  <span className="banner-section-content__element_main__content__title">
                    BITMAN
                  </span>
                  <div className="banner-section-content__element_main__content__svg-text">
                    <p className="prodcut-info">
                      <LightningIcon stroke="#B8C6CC" />
                      {showcase[0]?.percentageDiscount !== 0 ? (
                        <>
                          {showcase[0]?.price !== undefined &&
                          showcase[0]?.hashrate &&
                          showcase[0]?.hashrate.split(' ')[0]
                            ? (
                                (showcase[0].price -
                                  showcase[0].price * (showcase[0].percentageDiscount / 100)) /
                                parseFloat(showcase[0].hashrate.split(' ')[0])
                              ).toFixed(2)
                            : 'N/A'}
                        </>
                      ) : (
                        <>
                          {showcase[0]?.price !== undefined &&
                          showcase[0]?.hashrate &&
                          showcase[0]?.hashrate.split(' ')[0]
                            ? (
                                showcase[0].price / parseFloat(showcase[0].hashrate.split(' ')[0])
                              ).toFixed(2)
                            : 'N/A'}
                        </>
                      )}
                      $ per {showcase[0]?.hashrate?.split(' ')[1].split('/')[0]} |{' '}
                      {showcase[0]?.countryLocation}
                    </p>
                  </div>
                  <div className="banner-section-content__element_main__content__price-svg">
                    <span>
                      {showcase[0]?.percentageDiscount === 0 ? (
                        showcase[0]?.price !== undefined ? (
                          `${showcase[0].price.toFixed(2)}$`
                        ) : (
                          'N/A'
                        )
                      ) : (
                        <>
                          {showcase[0]?.price !== undefined &&
                          showcase[0]?.percentageDiscount !== undefined ? (
                            <>
                              <span className="banner-price-status__price-without-discount">
                                {`${showcase[0].price.toFixed(2)}$`}
                              </span>
                              <span className="banner-price-status__price-with-discount">
                                {`${(
                                  showcase[0].price -
                                  showcase[0].price * (showcase[0].percentageDiscount / 100)
                                ).toFixed(2)}$`}
                              </span>
                            </>
                          ) : (
                            'N/A'
                          )}
                        </>
                      )}
                    </span>
                    <ArrowLink />
                  </div>
                </div>
              </div>
              <div className="banner-section-content__element_main__button">
                <Link to={routesClient.product.path + `/${showcase[0]?.url}`}>
                  <CartBucketIncon />
                  Buy Now
                </Link>
              </div>
            </div>
          </div>
          <div className="banner-section-content__elements">
            <div className="banner-section-content__elements__row">
              {showcase?.map((product, index) => (
                <Fragment key={index}>
                  {index !== 0 && index !== 3 && index !== 4 && (
                    <div className="banner-section-content__element">
                      <div className="banner-section-content__element__action__title">
                        <div className="banner-section-content__element__title">
                          <p className="product-creator">{product.manufacturer}</p>
                          <p className="product-name">
                            <Link to={routesClient.product.path + `/${product.url}`}>
                              {product.name}
                            </Link>
                          </p>
                          <p className="prodcut-info">
                            <LightningIcon stroke="#B8C6CC" />
                            {product?.percentageDiscount !== 0 ? (
                              <>
                                {product?.price !== undefined &&
                                product?.hashrate &&
                                product?.hashrate.split(' ')[0]
                                  ? (
                                      (product.price -
                                        product.price * (product.percentageDiscount / 100)) /
                                      parseFloat(product.hashrate.split(' ')[0])
                                    ).toFixed(2)
                                  : 'N/A'}
                              </>
                            ) : (
                              <>
                                {product?.price !== undefined &&
                                product?.hashrate &&
                                product?.hashrate.split(' ')[0]
                                  ? (
                                      product.price / parseFloat(product.hashrate.split(' ')[0])
                                    ).toFixed(2)
                                  : 'N/A'}
                              </>
                            )}
                            $ per{' '}
                            {product?.hashrate && product.hashrate.split(' ')[1]?.split('/')[0]
                              ? product.hashrate.split(' ')[1].split('/')[0]
                              : 'N/A'}{' '}
                            | {product?.countryLocation ?? 'Unknown location'}
                          </p>
                          <p className="product-char">
                            {product.hashrate} | {product.energyConsumption}w
                          </p>
                        </div>
                        <div className="banner-section-content__element__action">
                          <WishlistBtn setIsAuthModalOpen={setIsAuth} productId={product.id} />
                          {/* <CompareBtn product={product} categoryId={product?.categoryId} /> */}
                        </div>
                      </div>
                      <div className="banner-section-content__element__price__btn">
                        <div className="banner-section-content__element__price">
                          {product?.percentageDiscount === 0 ? (
                            `${product?.price.toFixed(2)}$`
                          ) : (
                            <>
                              <ProductPrice product={product} />
                            </>
                          )}
                        </div>
                        <div className="banner-section-content__element__product-3DModel">
                          {/* <Miner3DModel /> */}
                          <Link to={routesClient.product.path + `/${product.url}`}>
                            <img
                              src={
                                product?.image?.url === null || product?.image?.url === undefined
                                  ? images.defaultImg
                                  : product?.image?.url
                              }
                              alt=""
                            />
                          </Link>
                        </div>
                        <div className="banner-section-content__element__btn">
                          <Link to={routesClient.product.path + `/${product.url}`}>
                            <ShoppingBasket />
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
                </Fragment>
              ))}
            </div>
            <div className="banner-section-content__elements__row">
              {showcase?.map((product, index) => (
                <Fragment key={index}>
                  {index !== 0 && index !== 1 && index !== 2 && (
                    <div className="banner-section-content__element">
                      <div className="banner-section-content__element__action__title">
                        <div className="banner-section-content__element__title">
                          <p className="product-creator">{product.manufacturer}</p>
                          <p className="product-name">
                            <Link to={routesClient.product.path + `/${product.url}`}>
                              {product.name}
                            </Link>
                          </p>
                          <p className="prodcut-info">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.52669 1.14001H12.3334L9.24002 6.09335H11.7134L4.90669 13.5267L6.14002 8.57335H3.66669L5.52669 1.14001Z"
                                stroke="white"
                                strokeMiterlimit="10"
                              />
                            </svg>
                            <ProductDetails product={product} />
                          </p>
                          <p className="product-char">
                            {product.hashrate} | {product.energyConsumption}w
                          </p>
                        </div>
                        <div className="banner-section-content__element__action">
                          <WishlistBtn setIsAuthModalOpen={setIsAuth} productId={product.id} />
                          {/* <CompareBtn product={product} categoryId={product?.categoryId} /> */}
                        </div>
                      </div>
                      <div className="banner-section-content__element__price__btn">
                        <div className="banner-section-content__element__price">
                          {product?.percentageDiscount === 0 ? (
                            `${product?.price.toFixed(2)}$`
                          ) : (
                            <>
                              <ProductPrice product={product} />
                            </>
                          )}
                        </div>
                        <div className="banner-section-content__element__product-3DModel">
                          <Link to={routesClient.product.path + `/${product.url}`}>
                            <img
                              src={
                                product?.image?.url === null || product?.image?.url === undefined
                                  ? images.defaultImg
                                  : product?.image?.url
                              }
                              alt=""
                            />
                          </Link>
                        </div>
                        <div className="banner-section-content__element__btn">
                          <Link to={routesClient.product.path + `/${product.url}`}>
                            <CartBucketIncon />
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="banner-section-content_loading">
          <Preloader />
        </div>
      )}
    </>
  );
};

export default Showcase;
