import React, { ChangeEvent } from 'react';

type Option = {
  name: string;
  value: string | boolean;
  label: string;
};

type SelectionControlsProps = {
  options: Option[];
  selectedOption: string | boolean | null;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const SelectionControls: React.FC<SelectionControlsProps> = ({
  options,
  selectedOption,
  onChange,
}) => {
  const handleOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event);
  };

  return (
    <>
      {options.map((option, index) => (
        <label className="radio" key={index}>
          <input
            type="radio"
            name={option.name}
            value={option.value.toString()}
            checked={selectedOption === option.value}
            onChange={handleOptionChange}
          />
          <span className="radio-label">{option.label}</span>
        </label>
      ))}
    </>
  );
};

export default SelectionControls;
