import './AdminNavigation.scss';

import React, { ReactNode, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import ArticlesIcon from '../../../general/assets/svg/adminNavigation/ArticlesIcon';
import ClientsIcon from '../../../general/assets/svg/adminNavigation/ClientsIcon';
import HomeIcon from '../../../general/assets/svg/adminNavigation/HomeIcon';
import InfosIcon from '../../../general/assets/svg/adminNavigation/InfosIcon';
import LogoutIcon from '../../../general/assets/svg/adminNavigation/LogoutIcon';
import LogsIcon from '../../../general/assets/svg/adminNavigation/LogsIcon';
import MessagesIcon from '../../../general/assets/svg/adminNavigation/MessagesIcon';
import OrdersIcon from '../../../general/assets/svg/adminNavigation/OrdersIcon';
import ProductsIcon from '../../../general/assets/svg/adminNavigation/ProductsIcon';
import RefferalIcon from '../../../general/assets/svg/adminNavigation/RefferalIcon';
import ShareIcon from '../../../general/assets/svg/adminNavigation/ShareIcon';
import ShippingAndLocationIcon from '../../../general/assets/svg/adminNavigation/ShippingAndLocationIcon';
import StaffIcon from '../../../general/assets/svg/adminNavigation/StaffIcon';
import DashboardIcon from '../../../general/assets/svg/DashboardIcon';
import useCustomQuery from '../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../general/queryKeys';
import { logOutAdmin } from '../../../general/redux/reducers/Admin-Reducer';
import { setNav } from '../../../general/redux/reducers/AdminPanel-Reducer';
import { RootState } from '../../../general/redux/store';
import { routesAdmin } from '../../../general/routes';
import { adminInstance } from '../../../general/services/main/axiosInstances';
import createMessagesService from '../../../general/services/messages';
import createOrdersService from '../../../general/services/orders';
import { clearStorageForage } from '../../../general/utils/localforage';

// Array navigationURL has multiple URLs that are used in the admin panel menu.

interface NavigationItem {
  mainUrl: string;
  name: string;
  icon: ReactNode;
  isMessage?: boolean;
}

const navigationUrl: NavigationItem[] = [
  {
    mainUrl: routesAdmin.adminPanel.path,
    name: 'Home',
    icon: <HomeIcon />,
  },
  {
    mainUrl: routesAdmin.adminPanel.path + routesAdmin.orders.path,
    name: 'Orders',
    icon: <OrdersIcon />,
    isMessage: true,
  },
  {
    mainUrl: routesAdmin.adminPanel.path + routesAdmin.products.path,
    name: 'Products',
    icon: <ProductsIcon />,
  },
  {
    mainUrl: routesAdmin.adminPanel.path + routesAdmin.articles.path,
    name: 'Articles',
    icon: <ArticlesIcon />,
  },
  {
    mainUrl: routesAdmin.adminPanel.path + routesAdmin.messages.path,
    name: 'Messages',
    icon: <MessagesIcon />,
    isMessage: true,
  },
  {
    mainUrl: routesAdmin.adminPanel.path + routesAdmin.faq.path,
    name: 'Help center',
    icon: <InfosIcon />,
  },
  {
    mainUrl: routesAdmin.adminPanel.path + routesAdmin.projectHashrate.path,
    name: 'Project Hasrate',
    icon: <DashboardIcon stroke="#6E7EA8" />,
  },
  {
    mainUrl: routesAdmin.adminPanel.path + routesAdmin.bloggersAndCompanies.path,
    name: 'Referral program',
    icon: <RefferalIcon />,
  },
  {
    mainUrl: routesAdmin.adminPanel.path + routesAdmin.clients.path,
    name: 'Clients',
    icon: <ClientsIcon />,
  },
  {
    mainUrl: routesAdmin.adminPanel.path + routesAdmin.admins.path,
    name: 'Admins',
    icon: <StaffIcon />,
  },
  {
    mainUrl: routesAdmin.adminPanel.path + routesAdmin.contacts.path,
    name: 'Contacts',
    icon: <ShareIcon />,
  },
  {
    mainUrl: routesAdmin.adminPanel.path + routesAdmin.auditLogs.path,
    name: 'Audit logs',
    icon: <LogsIcon />,
  },
  {
    mainUrl: routesAdmin.adminPanel.path + routesAdmin.shippingInfo.path,
    name: 'Shipping & Location info',
    icon: <ShippingAndLocationIcon />,
  },
];

const AdminNavigation: React.FC = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const isNavOpen = useSelector((state: RootState) => state.adminPanel.isOpenedNav);
  const navRef = useRef<HTMLDivElement>(null); // Реф для навигационного меню

  // Обработчик клика вне навигационного меню
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      // Проверяем, попал ли клик на бургер-меню
      const isBurgerClick = (event.target as HTMLElement).closest('.burger');

      // Если клик не на бургер-меню и не внутри навигации — закрываем навигацию
      if (!isBurgerClick && navRef.current && !navRef.current.contains(event.target as Node)) {
        dispatch(setNav(false)); // Закрыть навигацию
      }
    },
    [dispatch],
  );

  // Добавляем и удаляем обработчик событий
  useEffect(() => {
    if (isNavOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isNavOpen, handleClickOutside]);

  const logOut = async () => {
    dispatch(logOutAdmin());
    await clearStorageForage();
    navigation(routesAdmin.adminPanel.path);
  };

  //const [newOrdersCount, setNewOrdersCount] = useState(0);
  //const [newMessagesCount, setNewMessagesCount] = useState(0);

  const { data: newOrdersCount } = useCustomQuery<number>(queryKeys.NEW_ORDERS_COUNT, () =>
    createOrdersService(adminInstance).getOrdersCount('New'),
  );

  const { data: newMessagesCount } = useCustomQuery<number>(queryKeys.NEW_MESSAGES_COUNT, () =>
    createMessagesService(adminInstance).getMessageCount(),
  );

  const { data: simpleMessagesCount } = useCustomQuery<number>(
    queryKeys.NEW_SIMPLE_MESSAGES_COUNT,
    () => createMessagesService(adminInstance).getSimpleMessageCount(),
  );

  return (
    <>
      <div ref={navRef} className={`navigation-section ${isNavOpen ? 'opened' : 'closed'}`}>
        <div className="navigation-section__links-block">
          <ul>
            {navigationUrl.map((main) => (
              <li key={navigationUrl.indexOf(main)} className="links-block__li">
                <NavLink
                  to={main.mainUrl}
                  className={({ isActive }) =>
                    'links-block__li__link' + (isActive ? ' active-link' : '')
                  }
                >
                  {main.icon}
                  {main.isMessage && main.name === 'Orders' && (
                    <>
                      {(newOrdersCount as number) > 0 && (
                        <div className="new-message">{newOrdersCount}</div>
                      )}
                    </>
                  )}
                  {main.isMessage && main.name === 'Messages' && (
                    <>
                      {(newMessagesCount as number) + (simpleMessagesCount as number) > 0 && (
                        <div className="new-message">{newMessagesCount}</div>
                      )}
                    </>
                  )}
                  <span>{main.name}</span>
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
        <div className="navigation-section__bottom">
          <button
            style={{
              width: '40px',
              height: '35px',
              display: 'flex',
            }}
            onClick={logOut}
          >
            <LogoutIcon />
          </button>
        </div>
      </div>
    </>
  );
};

export default AdminNavigation;
