import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PlusIcon from '../../../../general/assets/svg/PlusIcon';
import useCustomMutation from '../../../../general/hooks/useCustomMutation';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../../general/queryKeys';
import { setInfo, setType, types } from '../../../../general/redux/reducers/Error-Reducer';
import { RootState } from '../../../../general/redux/store';
import createF2PoolService from '../../../../general/services/f2pool/f2Pool';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import createProductsService from '../../../../general/services/products';
import { IManufacturer } from '../../../../general/types/types';
import AdminModalWindow from '../../../components/modal-window/ModalWindow';

interface AsicModelsEditorProps {
  isOpen: (isOpen: boolean) => void;
  refresh: () => void;
}

const AsicModelsEditor: React.FC<AsicModelsEditorProps> = ({ isOpen, refresh }) => {
  const [manufacturerId, setManufacturerId] = useState<string | undefined>();
  const [images, setImages] = useState<File[]>([]);
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);
  const [nameVariants, setNameVariants] = useState<string>('');
  const asic = useSelector((state: RootState) => state.product.asicModelToEdit);
  const dispatch = useDispatch();

  const { data: manufacturers } = useCustomQuery<IManufacturer[]>(
    queryKeys.MANUFACTURERS,
    () => createProductsService(adminInstance).getManufacturers(),
    undefined,
    undefined,
    {
      onSuccess(data) {
        if (data) {
          setManufacturerId(data[0].id);
        }
      },
    },
  );

  const { mutate: addMinerInfo } = useCustomMutation(
    createF2PoolService(adminInstance).addAsicModel,
    undefined,
    {
      onSuccess: () => {
        refresh();
        isOpen(false);
      },
    },
  );

  const { mutate: updateMinerInfo } = useCustomMutation(
    createF2PoolService(adminInstance).updateAsicModel,
    undefined,
    {
      onSuccess: () => {
        refresh();
        isOpen(false);
      },
    },
  );

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      setImages(files);

      // Generate image previews
      const previews = files.map((file) => URL.createObjectURL(file));
      setImagePreviews(previews);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!manufacturerId || images.length === 0 || !nameVariants) {
      dispatch(setType(types.ERROR));
      dispatch(
        setInfo({
          n: types.ERROR,
          r: { s: 'Please fill out all fields and upload at least one image.' },
        }),
      );
      return;
    }

    if (asic) {
      const nameVariantsArray = nameVariants.split(',').map((name) => name.trim());

      const newNameVariants = nameVariantsArray.filter(
        (variant) => !asic.nameVariants.some((nv) => nv.variant === variant),
      );

      const nameVariantsToRemove = asic.nameVariants
        .filter((nv) => !nameVariantsArray.includes(nv.variant))
        .map((nv) => nv.id);

      updateMinerInfo({
        asicModelId: asic.id,
        manufacturerId: manufacturerId,
        newImages: images,
        imagesToRemove: [],
        newNameVariants: newNameVariants.join(', '),
        nameVariantsToRemove: nameVariantsToRemove,
      });
    } else {
      addMinerInfo({
        manufacturerId: manufacturerId,
        images: images,
        nameVariants: nameVariants,
      });
    }
  };

  return (
    <AdminModalWindow isOpen={isOpen} title={'Asic model'} saveCallback={refresh}>
      <div className="asic-models-container">
        <div className="asic-container">
          <div className="image-section">
            <div
              className="image-upload"
              style={{
                backgroundImage:
                  imagePreviews.length > 0
                    ? `url(${imagePreviews[0]})`
                    : asic?.images?.[asic?.images?.length - 1]?.url
                      ? `url(${asic.images[asic?.images?.length - 1].url})`
                      : 'none',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
              onClick={() => document.getElementById('asic-image')?.click()}
            >
              <input
                type="file"
                id="asic-image"
                accept="image/*"
                className="file-input"
                multiple
                onChange={handleImageChange}
                style={{ display: 'none' }}
              />
              {imagePreviews.length <= 0 && !asic?.images?.[asic?.images?.length - 1]?.url && (
                <label htmlFor="asic-image" className="image-label">
                  <PlusIcon />
                </label>
              )}
            </div>
          </div>

          <div className="details-section">
            <div className="manufacturer-select">
              <label htmlFor="manufacturer-select">Manufacturer:</label>
              <select
                id="manufacturer-select"
                value={manufacturerId || asic?.manufacturerName || ''}
                onChange={(e) => setManufacturerId(e.target.value)}
              >
                {manufacturers?.map((manufacturer) => (
                  <option key={manufacturer.id} value={manufacturer.id}>
                    {manufacturer.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="models-input">
              <textarea
                id="models"
                placeholder="Enter model names separated by commas"
                rows={5}
                className="textarea"
                value={nameVariants || asic?.nameVariants?.map((nv) => nv.variant).join(', ') || ''}
                onChange={(e) => setNameVariants(e.target.value)}
              />
            </div>

            <div className="submit-section">
              <button className="section-btn-gradient" onClick={handleSubmit}>
                {asic ? 'Update' : 'Add'} ASIC Model
              </button>
            </div>
          </div>
        </div>
      </div>
    </AdminModalWindow>
  );
};

export default AsicModelsEditor;
