import { ChangeEvent, MouseEventHandler, useState } from 'react';

interface UseCounterProps {
  initialValue?: number;
  min?: number;
  max?: number;
}

const useCounter = ({ initialValue = 1, min = 1, max = 999 }: UseCounterProps) => {
  const [count, setCount] = useState<number>(initialValue);

  const increment: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    if (count < max) {
      setCount(Math.min(count + 1, max));
    }
  };

  const decrement: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    if (count > min) {
      setCount(Math.max(count - 1, min));
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = +e.target.value;
    if (value > max) {
      setCount(max);
    } else if (value >= min) {
      setCount(value);
    } else {
      setCount(min);
    }
  };

  return { count, increment, decrement, handleChange, setCount };
};

export default useCounter;
