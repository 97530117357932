import './Pagination.scss';

import React from 'react';

interface CustomPaginationProps {
  page: number;
  setPage: (page: number) => void;
  isNextPage?: boolean;
  isPrevPage?: boolean;
  total: number;
  pageSize: number;
  isShowMore?: number | null;
  setIsShowMore?: (value: number | null) => void;
}

const CustomPagination: React.FC<CustomPaginationProps> = ({
  page,
  setPage,
  isNextPage,
  isPrevPage,
  total,
  pageSize,
  isShowMore,
  setIsShowMore,
}) => {
  const pageNumbers: (number | string)[] = [];
  const totalSize = Math.ceil(total / pageSize);

  for (let i = 1; i <= totalSize; i++) {
    if (i === 1 || i === totalSize || (i >= page - 2 && i <= page + 2)) {
      pageNumbers.push(i);
    } else if (i < page - 2 && !pageNumbers.includes('...left')) {
      pageNumbers.push('...left');
    } else if (i > page + 2 && !pageNumbers.includes('...right')) {
      pageNumbers.push('...right');
    }
  }

  const setCurrentPage = (newPage: number) => {
    setPage(newPage);
    setIsShowMore && setIsShowMore(null);
    window.scrollTo(0, 0);
  };

  return (
    <div className="pagination">
      {pageNumbers.map((number, index) => {
        if (typeof number === 'string') {
          return (
            <div key={index} className="pagination__btn">
              <span className="pagination__dote" />
            </div>
          );
        }

        return (
          <div key={index} className="pagination__btn">
            <button
              onClick={() => setCurrentPage(number)}
              className={
                (page === 1 && number === 1) ||
                (isShowMore && number >= isShowMore && number <= page) ||
                page === number
                  ? 'is-active-page'
                  : ''
              }
            >
              {number}
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default CustomPagination;
