import React from 'react';

const ImportIconV2: React.FC = () => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.13281 14.1654V15.832C4.13281 16.2741 4.30841 16.698 4.62097 17.0105C4.93353 17.3231 5.35745 17.4987 5.79948 17.4987H15.7995C16.2415 17.4987 16.6654 17.3231 16.978 17.0105C17.2906 16.698 17.4661 16.2741 17.4661 15.832V14.1654M6.63281 7.4987L10.7995 3.33203M10.7995 3.33203L14.9661 7.4987M10.7995 3.33203V13.332"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ImportIconV2;
