import { AxiosInstance, AxiosResponse } from 'axios';
import { AsicModelDTO, PaggingObj, VerificationResult } from '../../types/types';
import {
  AssetF2Pool,
  BalanceInfo,
  DailyIncomeLog,
  MiningInfo,
  TransactionHistory,
} from './interfaces';

interface F2PoolService {
  updateOrderedProductInfo: (body: {
    orderId: string;
    configData: {
      oldSerialNumbers: string[];
      newSerialNumbers: string[];
      accountStatus: number;
      workingCondition: number;
      physicalCondition: number;
      financialStatus: number;
    };
    cuverseMinerId: string;
  }) => Promise<any>;
  getOrderedProductInfo: (orderId: string, cuverseMinerId: string) => Promise<any>;
  getAssets: (
    userId: string,
    pageSize: number,
    page: number,
  ) => Promise<AxiosResponse<PaggingObj<AssetF2Pool>>>;
  getAssetsXLSX: (userId: string) => Promise<AxiosResponse<ArrayBuffer>>;
  getTransactionsHistoryXLSX: (userId: string) => Promise<AxiosResponse<ArrayBuffer>>;
  getMinigInfo: (userId: string) => Promise<AxiosResponse<MiningInfo>>;
  getBalanceInfo: (userId: string) => Promise<AxiosResponse<BalanceInfo>>;
  getBalanceBalanceHistoryInfo: (userId: string) => Promise<AxiosResponse<DailyIncomeLog[]>>;
  getTransactionHistory: (
    userId: string,
    pageSize: number,
    page: number,
  ) => Promise<AxiosResponse<PaggingObj<TransactionHistory>>>;
  getAllActiveKws: (userId: string) => Promise<AxiosResponse<number>>;
  updateBTCWalletAddress: (body: {
    userId: string;
    walletAddress: string;
  }) => Promise<AxiosResponse<VerificationResult>>;
  addBTCWalletAddressAndF2PoolAccount: (body: {
    userId: string;
    walletAddress: string;
  }) => Promise<AxiosResponse<VerificationResult>>;
  setElectricityCost: (body: { infosId: string; elecricityCostPreKW: number }) => Promise<any>;
  addAsicModel: (body: {
    manufacturerId: string;
    images: File[];
    nameVariants: string;
  }) => Promise<any>;
  updateAsicModel: (body: {
    asicModelId: string;
    manufacturerId: string;
    newImages?: File[];
    imagesToRemove?: string[];
    newNameVariants?: string;
    nameVariantsToRemove?: string[];
  }) => Promise<any>;
  getAsicsModels: (pageSize: number, page: number) => Promise<AxiosResponse<AsicModelDTO>>;
  removeAsicModel: (id: string) => Promise<AxiosResponse<any>>;
}

const createF2PoolService = (instance: AxiosInstance): F2PoolService => ({
  async removeAsicModel(id: string): Promise<AxiosResponse<any>> {
    try {
      const response = await instance.delete(`/Product/deleteAsicModelsInfo?Id=${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async getAsicsModels(pageSize: number, page: number): Promise<AxiosResponse<AsicModelDTO>> {
    try {
      const response = await instance.get(
        `/Product/getAsicModelsInfo?PageSize=${pageSize}&Page=${page}`,
      );
      return response;
    } catch (error) {
      throw error;
    }
  },

  async updateAsicModel(body: {
    asicModelId: string;
    manufacturerId: string;
    newImages?: File[];
    imagesToRemove?: string[];
    newNameVariants?: string;
    nameVariantsToRemove?: string[];
  }): Promise<any> {
    const formData = new FormData();

    formData.append('AsicModelId', body.asicModelId);
    formData.append('ManufacturerId', body.manufacturerId);

    body.newImages?.forEach((image) => formData.append('NewImages', image));
    body.imagesToRemove?.forEach((imageId) => formData.append('ImagesToRemove', imageId));

    body.newNameVariants
      ?.split(',')
      .map((name) => name.trim())
      .forEach((name) => formData.append('NewNameVariants', name));
    body.nameVariantsToRemove?.forEach((variantId) =>
      formData.append('NameVariantsToRemove', variantId),
    );

    try {
      const response = await instance.post(`/Product/updateAsicModelInfo`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response;
    } catch (error) {
      console.error('Error updating ASIC model:', error);
      throw error;
    }
  },

  async addAsicModel(body: {
    manufacturerId: string;
    images: File[];
    nameVariants: string;
  }): Promise<any> {
    const formData = new FormData();
    formData.append('ManufacturerId', body.manufacturerId);
    body.images.forEach((image) => formData.append('Images', image));
    body.nameVariants.split(',').forEach((name) => formData.append('NameVariants', name.trim()));

    try {
      const response = await instance.post(`/Product/addAsicModelInfo`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },

  async setElectricityCost(body: {
    infosId: string;
    elecricityCostPreKW: number;
  }): Promise<AxiosResponse<any>> {
    try {
      const response = await instance.post(`/StaticPaymentInfos/setShippingCost`, body);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async addBTCWalletAddressAndF2PoolAccount(body: {
    userId: string;
    walletAddress: string;
  }): Promise<AxiosResponse<VerificationResult>> {
    try {
      const response = await instance.post(`/F2Pool/addBTCWalletAddressAndF2PoolAccount`, body);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async updateBTCWalletAddress(body: {
    userId: string;
    walletAddress: string;
  }): Promise<AxiosResponse<VerificationResult>> {
    try {
      const response = await instance.post(`/F2Pool/updateBTCWalletAddress`, body);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async getAllActiveKws(userId: string): Promise<AxiosResponse<number>> {
    try {
      const response = await instance.get(`/F2Pool/allActiveKws?UserId=${userId}`);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async getAssetsXLSX(userId: string): Promise<AxiosResponse<ArrayBuffer>> {
    const headers: any = {
      'Content-Type': 'blob',
      responseType: 'arraybuffer',
    };

    try {
      const response = await instance.get(`/F2Pool/assets/XLSX?UserId=${userId}`, headers);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async getTransactionsHistoryXLSX(userId: string): Promise<AxiosResponse<ArrayBuffer>> {
    const headers: any = {
      'Content-Type': 'blob',
      responseType: 'arraybuffer',
    };

    try {
      const response = await instance.get(
        `/F2Pool/transactionHistory/XLSX?UserId=${userId}`,
        headers,
      );
      return response;
    } catch (error) {
      throw error;
    }
  },

  async getTransactionHistory(
    userId: string,
    pageSize: number,
    page: number,
  ): Promise<AxiosResponse<PaggingObj<TransactionHistory>>> {
    try {
      const response = await instance.get(
        `/F2Pool/getTransactionHistoryInfo?UserId=${userId}&PageSize=${pageSize}&Page=${page}`,
      );
      return response;
    } catch (error) {
      throw error;
    }
  },

  async getBalanceBalanceHistoryInfo(userId: string): Promise<AxiosResponse<DailyIncomeLog[]>> {
    try {
      const response = await instance.get(`/F2Pool/getBalanceHistoryForUser?UserId=${userId}`);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async getBalanceInfo(userId: string): Promise<AxiosResponse<BalanceInfo>> {
    try {
      const response = await instance.get(`/F2Pool/getBalanceInformation?UserId=${userId}`);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async getMinigInfo(userId: string): Promise<AxiosResponse<MiningInfo>> {
    try {
      const response = await instance.get(`/F2Pool/getMiningInfo?UserId=${userId}`);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async getAssets(
    userId: string,
    pageSize: number,
    page: number,
  ): Promise<AxiosResponse<PaggingObj<AssetF2Pool>>> {
    try {
      const response = await instance.get(
        `/F2Pool/getMinersForAssets?UserId=${userId}&PageSize=${pageSize}&Page=${page}`,
      );
      return response;
    } catch (error) {
      throw error;
    }
  },

  async updateOrderedProductInfo(body: {
    orderId: string;
    configData: {
      oldSerialNumbers: string[];
      newSerialNumbers: string[];
      accountStatus: number;
      workingCondition: number;
      physicalCondition: number;
      financialStatus: number;
    };
    cuverseMinerId: string;
  }) {
    try {
      const response = await instance.put(`/F2Pool/updateOderedProduct`, body);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getOrderedProductInfo(orderId: string, cuverseMinerId: string) {
    try {
      const response = await instance.get(
        `/F2Pool/getOderedProduct?OrderId=${orderId}&CuverseMinerId=${cuverseMinerId}`,
      );
      return response;
    } catch (error) {
      return error;
    }
  },
});

export default createF2PoolService;
