import './Profile.scss';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, Outlet, useNavigate } from 'react-router-dom';

import BusketIcon from '../../../general/assets/svg/BusketIcon';
import DashboardIcon from '../../../general/assets/svg/DashboardIcon';
import DropDownIcon from '../../../general/assets/svg/DropDownIcon';
import LogOutIconClient from '../../../general/assets/svg/LogOutIconClient';
import PersonalDataIcon from '../../../general/assets/svg/PersonalDataIcon';
import ProfileHomeIcon from '../../../general/assets/svg/ProfileHomeIcon';
import ProfileIconEditName from '../../../general/assets/svg/ProfileIconEditName';
import QaFaqOpenQuestionIcon from '../../../general/assets/svg/QaFaqOpenQuestionIcon';
import WishListProfileIcon from '../../../general/assets/svg/WishListProfileIcon';
import Preloader from '../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../general/hooks/useCustomQuerry';
import useScrollToBottom from '../../../general/hooks/useScrollToBottom';
import { queryKeys } from '../../../general/queryKeys';
import {
  clientLogout,
  setActiveDashboardLink,
} from '../../../general/redux/reducers/ClientProfile-Reducer';
import { RootState } from '../../../general/redux/store';
import { routesClient } from '../../../general/routes';
import createClientService from '../../../general/services/client';
import { clientInstance } from '../../../general/services/main/axiosInstances';
import { User } from '../../../general/types/types';
import RepetAuth from '../../components/modals-window/RepeatAuth';
import CanonicalLink from '../../components/SEO/CanonicalLink';

const Profile: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = useSelector((state: RootState) => state.clps.cp2);
  const dashboardLink = useSelector((state: RootState) => state.clps.dashboardLink);
  const dashboardSubLinks: { name: string; link: string }[] = [
    {
      name: 'Dashboard',
      link: routesClient.profileDashboard.path,
    },
    {
      name: 'Assets',
      link: routesClient.profileDashboardAssets.path,
    },
    {
      name: 'Transaction History',
      link: routesClient.profileDashboardTransactionHistory.path,
    },
    {
      name: 'Wallet Setting',
      link: routesClient.profileDashboardViratualWalletSetting.path,
    },
  ];
  const [isVisibleDashboard, setIsVisibleDashboard] = useState<boolean>(false);

  useEffect(() => {
    if (!userId) navigate(routesClient.home.path);
  }, [navigate, userId]);

  const [isVisibleNav, setIsVisibleNav] = useState(false);
  const [isRepetAuth, setIsRepetAuth] = useState<boolean | null>(null);

  const { data: user, isLoading: userLoading } = useCustomQuery<User>(
    queryKeys.USER_INFO,
    () => createClientService(clientInstance).getClientInfo(userId),
    undefined,
    () => {
      navigate(routesClient.home.path);
      setIsRepetAuth(true);
      dispatch(clientLogout());
    },
    {
      enabled: !!userId,
    },
  );

  const logout = () => {
    dispatch(clientLogout());
  };

  const setDashboardLink = (item: number | null) => {
    dispatch(setActiveDashboardLink(item));
  };

  const dropDownBlock = () => {
    setIsVisibleNav(!isVisibleNav);
  };

  const { containerRef } = useScrollToBottom('.sticky', 0, 0);

  return (
    <>
      <div className="user-profile-page page-container">
        {isRepetAuth ? (
          <RepetAuth isOpen={setIsRepetAuth} />
        ) : (
          <>
            {userLoading ? (
              <div className="user-profile-page__preloader">
                <Preloader />
              </div>
            ) : (
              <>
                <div className="user-profile-section">
                  <div className="user-profile-section-top">
                    <div className="user-profile-settings">
                      <div>
                        <Link to={routesClient.profile.path} className="user-profile-container">
                          <div className="user-profile-name">
                            <ProfileHomeIcon />
                            {user?.name === null || user?.surname === null ? (
                              <span>Client profile</span>
                            ) : (
                              <span>{`${user?.name} ${user?.surname}`}</span>
                            )}
                          </div>

                          <ProfileIconEditName />
                        </Link>
                      </div>
                    </div>
                    <div className="drop_down" onClick={dropDownBlock}>
                      <PersonalDataIcon dropDownBlock={dropDownBlock} />
                      <span onClick={dropDownBlock}>Personal data</span>
                      <DropDownIcon
                        dropDownBlock={dropDownBlock}
                        className="drop-down_marker"
                        style={{
                          transform: !isVisibleNav ? 'rotate(180deg)' : 'rotate(0deg)',
                        }}
                      />
                    </div>
                    <div
                      className={isVisibleNav ? 'user-profile-tabs hidden' : 'user-profile-tabs'}
                    >
                      <NavLink
                        to={routesClient.profileOrders.path}
                        className={({ isActive }) => (isActive ? 'active-profile-link' : '')}
                        onClick={() => {
                          setIsVisibleDashboard(false);
                        }}
                      >
                        <BusketIcon />
                        <span>My orders</span>
                      </NavLink>
                      <NavLink
                        to={routesClient.profileWishlist.path}
                        className={({ isActive }) => (isActive ? 'active-profile-link' : '')}
                        onClick={() => {
                          setIsVisibleDashboard(false);
                        }}
                      >
                        <WishListProfileIcon />
                        <span>Selected products</span>
                      </NavLink>
                      <CanonicalLink
                        href={window.location.origin + routesClient.profileDashboard.path}
                      />
                      <div
                        className="dashboard-container"
                        onClick={() => {
                          setDashboardLink(0);
                          setIsVisibleDashboard(!isVisibleDashboard);
                        }}
                      >
                        <NavLink
                          to={routesClient.profileDashboard.path}
                          className={({ isActive }) => (isActive ? 'active-profile-link' : '')}
                        >
                          <DashboardIcon stroke="#25A8CF" />
                          <span>Dashboard</span>
                        </NavLink>
                        <button
                          className={`dashboard-toggle-button ${isVisibleDashboard ? 'opened' : ''}`}
                        >
                          <QaFaqOpenQuestionIcon />
                        </button>
                      </div>
                      <ul className={`dashboard-sub-menu ${isVisibleDashboard ? 'visible' : ''}`}>
                        {dashboardSubLinks.map((item, index) => (
                          <NavLink key={index} to={item.link}>
                            <li
                              className={`sub-link ${dashboardLink === index ? 'active-item' : ''}`}
                              onClick={() => {
                                setDashboardLink(index);
                              }}
                            >
                              <span className="poppins-medium">{item.name}</span>
                            </li>
                          </NavLink>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className={`user-profile-logout sticky `} ref={containerRef}>
                    <Link
                      to={routesClient.home.path}
                      className="user-profile-container"
                      onClick={logout}
                    >
                      <LogOutIconClient />
                      <span>LogOut</span>
                    </Link>
                  </div>
                </div>
                <Outlet />
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Profile;
