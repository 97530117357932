import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import useCustomMutation from '../../../../../general/hooks/useCustomMutation';
import useCustomQuery from '../../../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../../../general/queryKeys';
import { setInfo, setType, types } from '../../../../../general/redux/reducers/Error-Reducer';
import createF2PoolService from '../../../../../general/services/f2pool/f2Pool';
import { adminInstance } from '../../../../../general/services/main/axiosInstances';

interface F2PoolConfigModalProps {
  isF2PoolOffer: boolean;
  orderId: string;
  productId: string;
  quantity: number;
}

interface ConfigData {
  accountStatus: string;
  financialStatus: string;
  physicalCondition: string;
  workingCondition: string;
  serialNumbers: string[][];
}

export const AccountStatusEnum: Record<string, number> = {
  Available: 1,
  InUse: 2,
  Reserved: 3,
  OutOfStock: 4,
};

export const WorkingConditionEnum: Record<string, number> = {
  Active: 1,
  Inactive: 2,
  Operational: 3,
  NonOperational: 4,
};

export const PhysicalConditionEnum: Record<string, number> = {
  Good: 1,
  Fair: 2,
  Poor: 3,
};

export const FinancialStatusEnum: Record<string, number> = {
  Depreciating: 1,
  WrittenOff: 2,
  Appreciating: 3,
};

export const statusColors: Record<string, string> = {
  available: 'green',
  inuse: 'blue',
  reserved: 'yellow',
  outofstock: 'black',
  active: 'green',
  inactive: 'gray',
  operational: 'green',
  nonoperational: 'red',
  good: 'green',
  fair: 'yellow',
  poor: 'red',
  depreciating: 'orange',
  writtenoff: 'gray',
  appreciating: 'green',
};

const F2PoolConfigModal: React.FC<F2PoolConfigModalProps> = ({
  isF2PoolOffer,
  orderId,
  productId,
  quantity,
}) => {
  const dispatch = useDispatch();

  const [serialNumbersFixed, setSerialNumbersFixed] = useState<string[][]>();
  const [serialNumbers, setSerialNumbers] = useState<string[] | null>(null);
  const [accountStatuses, setAccountStatuses] = useState<string[]>(
    Array(quantity).fill('Available'),
  );
  const [workingConditions, setWorkingConditions] = useState<string[]>(
    Array(quantity).fill('Inactive'),
  );
  const [physicalConditions, setPhysicalConditions] = useState<string[]>(
    Array(quantity).fill('Good'),
  );
  const [financialStatuses, setFinancialStatuses] = useState<string[]>(
    Array(quantity).fill('Depreciating'),
  );

  const { data: configData } = useCustomQuery<ConfigData[]>(
    queryKeys.CUVERSE_MINER_CONFIG_DATA + orderId + productId,
    () => createF2PoolService(adminInstance).getOrderedProductInfo(orderId, productId),
    undefined,
    undefined,
    {
      enabled: isF2PoolOffer === true,
      onSuccess(data: any) {
        if (data.length !== 0 && data.serialNumbers !== null) {
          setSerialNumbersFixed(data.map((item: any) => item.serialNumbers) || []);
          setSerialNumbers(data.map((item: any) => item.serialNumbers.join(', ')) || []);
          setAccountStatuses(data.map((item: any) => item.accountStatus || 'Available'));
          setWorkingConditions(data.map((item: any) => item.workingCondition || 'Inactive'));
          setPhysicalConditions(data.map((item: any) => item.physicalCondition || 'Good'));
          setFinancialStatuses(data.map((item: any) => item.financialStatus || 'Depreciating'));
        }
      },
    },
  );

  const { mutate: updateF2PpolOrderedProductInfo } = useCustomMutation(
    createF2PoolService(adminInstance).updateOrderedProductInfo,
    undefined,
    {
      onSuccess() {
        dispatch(setType(types.SUCCESS));
        dispatch(
          setInfo({
            n: types.SUCCESS,
            r: { s: 'Product successfully updated' },
          }),
        );
      },
    },
  );

  const parseSerialNumbers = (value: string): string[] => {
    return value
      .split(/[\n,;\s\t|]+/) // Разделение по различным разделителям
      .map((serial) => serial.trim()) // Убираем пробелы
      .filter((serial) => serial.length > 0); // Убираем пустые строки
  };

  const arraysAreEqual = (arr1: string[] | undefined, arr2: string[] | undefined): boolean => {
    if (!arr1 || !arr2) return false; // Если один из массивов undefined
    if (arr1.length !== arr2.length) return false; // Разные длины

    // Проверяем, что каждый элемент в обоих массивах равен
    return arr1.every((value, index) => value === arr2[index]);
  };

  const saveOrderdProductInfo = (index: number) => {
    const parsedSerials = parseSerialNumbers((serialNumbers as string[])[index]);
    const fixedSerials = (serialNumbersFixed as string[][])[index];
    const currentConfig = (configData as ConfigData[])[index];

    // Проверяем, если данные не изменились
    const isUnchanged =
      arraysAreEqual(parsedSerials, fixedSerials) &&
      accountStatuses[index] === currentConfig.accountStatus &&
      workingConditions[index] === currentConfig.workingCondition &&
      physicalConditions[index] === currentConfig.physicalCondition &&
      financialStatuses[index] === currentConfig.financialStatus;

    if (isUnchanged) {
      dispatch(setType(types.ERROR));
      dispatch(
        setInfo({
          n: types.ERROR,
          r: { s: 'All data is the same' },
        }),
      );
      return;
    }

    // Проверяем, если поле серийных номеров пустое
    if (!parsedSerials.length) {
      dispatch(setType(types.ERROR));
      dispatch(
        setInfo({
          n: types.ERROR,
          r: { s: 'You have to define at least one serial number' },
        }),
      );
      return;
    }

    updateF2PpolOrderedProductInfo({
      orderId,
      configData: {
        oldSerialNumbers: fixedSerials,
        newSerialNumbers: parsedSerials,
        accountStatus: AccountStatusEnum[accountStatuses[index]],
        workingCondition: WorkingConditionEnum[workingConditions[index]],
        physicalCondition: PhysicalConditionEnum[physicalConditions[index]],
        financialStatus: FinancialStatusEnum[financialStatuses[index]],
      },
      cuverseMinerId: productId,
    });
  };

  //#region  handlers
  const handleSerialNumbersChange = (index: number, value: string) => {
    if (serialNumbers) {
      setSerialNumbers((prev) => prev && prev.map((serial, i) => (i === index ? value : serial)));
    }
  };

  const handleAccountStatusChange = (index: number, value: string) => {
    setAccountStatuses((prev) => prev.map((status, i) => (i === index ? value : status)));
  };

  const handleWorkingConditionChange = (index: number, value: string) => {
    setWorkingConditions((prev) => prev.map((condition, i) => (i === index ? value : condition)));
  };

  const handlePhysicalConditionChange = (index: number, value: string) => {
    setPhysicalConditions((prev) => prev.map((condition, i) => (i === index ? value : condition)));
  };

  const handleFinancialStatusChange = (index: number, value: string) => {
    setFinancialStatuses((prev) => prev.map((status, i) => (i === index ? value : status)));
  };
  //#endregion

  return (
    <div className="modal-wrapper">
      {Array.from({ length: quantity }).map((_, index) => (
        <div className="miner-config-wrapper" key={index}>
          <div className="miner-config">
            <span>{index + 1}.</span>
            <div className="serial-number-input">
              <label htmlFor="serial-numbers">Serial Numbers:</label>
              <textarea
                id={`serial-numbers-${index}`}
                value={(serialNumbers && serialNumbers[index]) || ''}
                onChange={(e) => handleSerialNumbersChange(index, e.target.value)}
                placeholder="Enter serial numbers, separated by commas, line breaks, or other separators"
                rows={3}
                style={{ width: '100%', marginTop: '8px' }}
              />
            </div>
            <div className="status-select">
              <label htmlFor={`account-status-${index}`}>Account Status:</label>
              <select
                className={
                  statusColors[accountStatuses[index].toLowerCase().replace(/\s+/g, '')] || ''
                }
                id={`account-status-${index}`}
                value={accountStatuses[index]}
                onChange={(e) => handleAccountStatusChange(index, e.target.value)}
              >
                <option value="Available">Available</option>
                <option value="InUse">In Use</option>
                <option value="Reserved">Reserved</option>
                <option value="OutOfStock">Out of Stock</option>
              </select>
            </div>
            <div className="status-select">
              <label htmlFor={`working-condition-${index}`}>Working Condition:</label>
              <select
                className={
                  statusColors[workingConditions[index].toLowerCase().replace(/\s+/g, '')] || ''
                }
                id={`working-condition-${index}`}
                value={workingConditions[index]}
                onChange={(e) => handleWorkingConditionChange(index, e.target.value)}
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
                <option value="Operational">Operational</option>
                <option value="NonOperational">Non-Operational</option>
              </select>
            </div>
            <div className="status-select">
              <label htmlFor={`physical-condition-${index}`}>Physical Condition:</label>
              <select
                className={
                  statusColors[physicalConditions[index].toLowerCase().replace(/\s+/g, '')] || ''
                }
                id={`physical-condition-${index}`}
                value={physicalConditions[index]}
                onChange={(e) => handlePhysicalConditionChange(index, e.target.value)}
              >
                <option value="Good">Good</option>
                <option value="Fair">Fair</option>
                <option value="Poor">Poor</option>
              </select>
            </div>
            <div className="status-select">
              <label htmlFor={`financial-status-${index}`}>Financial Status:</label>
              <select
                className={
                  statusColors[financialStatuses[index].toLowerCase().replace(/\s+/g, '')] || ''
                }
                id={`financial-status-${index}`}
                value={financialStatuses[index]}
                onChange={(e) => handleFinancialStatusChange(index, e.target.value)}
              >
                <option value="Depreciating">Depreciating</option>
                <option value="WrittenOff">Written Off</option>
                <option value="Appreciating">Appreciating</option>
              </select>
            </div>
          </div>
          {configData?.length !== 0 && (
            <button
              className="section-btn-gradient"
              onClick={() => {
                saveOrderdProductInfo(index);
              }}
            >
              Update miner
            </button>
          )}
        </div>
      ))}
    </div>
  );
};

export default F2PoolConfigModal;
