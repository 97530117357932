import { AxiosInstance } from 'axios';
import { SitemapRoute } from './interfaces';

interface SitemapService {
  saveRoutes: (routes: SitemapRoute[]) => Promise<any>;
}

let isRoutesSaved = false;

const createSitemapService = (instance: AxiosInstance): SitemapService => ({
  async saveRoutes(routes: SitemapRoute[]) {
    if (isRoutesSaved) return; // Предотвращаем повторную отправку
    isRoutesSaved = true;

    try {
      await instance.post('/Sitemap', { routes });
    } catch (error) {
      console.error('Error saving routes:', error);
      throw error;
    }
  },
});

export default createSitemapService;
