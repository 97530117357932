import React from 'react';

const BitcoinIcon: React.FC = () => (
  <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 11.2565H17.4533C18.86 11.2565 20 12.4498 20 13.9232C20 15.3965 18.86 16.5898 17.4533 16.5898C18.86 16.5898 20 17.7832 20 19.2565C20 20.7298 18.86 21.9232 17.4533 21.9232H12M13.3333 16.5898H18.6667M13.3333 9.92318V23.2565V11.2565M17.3333 9.92318V11.2565M17.3333 21.9232V23.2565M4 16.5898C4 18.1657 4.31039 19.7261 4.91345 21.182C5.5165 22.638 6.40042 23.9608 7.51472 25.0751C8.62902 26.1894 9.95189 27.0733 11.4078 27.6764C12.8637 28.2795 14.4241 28.5898 16 28.5898C17.5759 28.5898 19.1363 28.2795 20.5922 27.6764C22.0481 27.0733 23.371 26.1894 24.4853 25.0751C25.5996 23.9608 26.4835 22.638 27.0866 21.182C27.6896 19.7261 28 18.1657 28 16.5898C28 15.014 27.6896 13.4535 27.0866 11.9976C26.4835 10.5417 25.5996 9.21887 24.4853 8.10456C23.371 6.99026 22.0481 6.10635 20.5922 5.50329C19.1363 4.90023 17.5759 4.58984 16 4.58984C14.4241 4.58984 12.8637 4.90023 11.4078 5.50329C9.95189 6.10635 8.62902 6.99026 7.51472 8.10456C6.40042 9.21887 5.5165 10.5417 4.91345 11.9976C4.31039 13.4535 4 15.014 4 16.5898Z"
      stroke="#25A8CF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BitcoinIcon;
