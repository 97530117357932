import './AdminVideo.scss';

import React, { useState } from 'react';

import EditIcon from '../../../../general/assets/svg/EditIconAction';
import VideoIcon from '../../../../general/assets/svg/VideoIcon';
import Preloader from '../../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../../general/queryKeys';
import createHomePageService from '../../../../general/services/homePage';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import VideoModal from './VideoModal';

interface VideoData {
  url: string;
}

const AdminVideoHomePage = () => {
  const [isModal, setIsModal] = useState<boolean>(false);
  const [isPlay, setIsPlay] = useState<boolean>(true);

  const { data: video, isLoading: isLoadingVideo } = useCustomQuery<VideoData>(
    queryKeys.HOME_VIDEO,
    () => createHomePageService(adminInstance).getVideoLink(),
  );

  const videoPause = (e: React.MouseEvent<HTMLVideoElement, MouseEvent>) => {
    e.currentTarget.pause();
    setIsPlay(false);
  };

  const videoPlay = (e: React.MouseEvent<HTMLVideoElement, MouseEvent>) => {
    e.currentTarget.play();
    setIsPlay(true);
  };

  return (
    <>
      {isModal && <VideoModal isOpen={setIsModal} />}
      <div className="home-container__header">
        <div className="home-container__header__icon-name">
          <VideoIcon />
          <span>Video</span>
        </div>
        <button className="home-container__header__button" onClick={() => setIsModal(true)}>
          <EditIcon />
        </button>
      </div>
      <div className="home-container__video">
        {isLoadingVideo ? (
          <Preloader />
        ) : (
          <>
            {!video ? (
              <div className="home-container__video__empty">No video</div>
            ) : (
              <div className="home-container__video__link">
                <video
                  controls={false}
                  muted={true}
                  playsInline={true}
                  autoPlay={true}
                  loop={true}
                  src={video?.url}
                  onContextMenu={(e) => e.preventDefault()}
                  onClick={isPlay ? videoPause : videoPlay}
                />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default AdminVideoHomePage;
