import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ArrowDropDownIcon from '../../../../general/assets/svg/ArrowDropDownIcon';
import ChipIcon from '../../../../general/assets/svg/ChipIcon';
import DailyPaymentIcon from '../../../../general/assets/svg/DailyPaymentIcon';
import ImportIconV2 from '../../../../general/assets/svg/ImportIconV2';
import LightningIcon from '../../../../general/assets/svg/LightningIcon';
import RecentTransactionIcon from '../../../../general/assets/svg/RecentTransactionIcon';
import ReplenishIcon from '../../../../general/assets/svg/ReplenishIcon';
import SpeedIndicatorIcon from '../../../../general/assets/svg/SpeedIndicatorIcon';
import SufficientBalanceIcon from '../../../../general/assets/svg/SufficientBalanceIcon';
import TotalBalanceIcon from '../../../../general/assets/svg/TotalBalanceIcon';
import WalletIcon from '../../../../general/assets/svg/WalletIcon';
import Preloader from '../../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../../general/queryKeys';
import { setActiveDashboardLink } from '../../../../general/redux/reducers/ClientProfile-Reducer';
import { RootState } from '../../../../general/redux/store';
import { routesClient } from '../../../../general/routes';
import createF2PoolService from '../../../../general/services/f2pool/f2Pool';
import {
  BalanceInfo,
  DailyIncomeLog,
  MiningInfo,
  TransactionHistory,
} from '../../../../general/services/f2pool/interfaces';
import { clientInstance } from '../../../../general/services/main/axiosInstances';
import { PaggingObj, User } from '../../../../general/types/types';
import ModalWindow from '../../../components/authorization/modal-window/ModalWindow';
import BalanceIndicator from '../../../components/dashboard/BalanceIndicator/BalanceIndicator';
import CustomLineChart from '../../../components/dashboard/CustomLineChart/CustomLineChart';
import DepositWalletModalContent from '../../../components/dashboard/depositWalletModal/DepositWalletModalContent';

import createClientService from '../../../../general/services/client';
import createStaticPaymentInfosService from '../../../../general/services/staticPayments';
import { formatToTwoPlaces } from '../../../../general/utils/Validations';
import WithdrawModalContent from '../../../components/dashboard/withdrawModal/WithdrawModalContent';
import './ProfileDashboard.scss';

const ProfileDashboard: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = useSelector((state: RootState) => state.clps.cp2);
  const [withdrawal, setWithdrawal] = useState(false);
  const [deposit, setDeposit] = useState(false);
  const [formattedCost, setFormattedCost] = useState<string>('0');

  const { data: user } = useCustomQuery<User>(
    queryKeys.USER_INFO,
    () => createClientService(clientInstance).getClientInfo(userId),
    undefined,
    undefined,
    {
      enabled: !!userId,
    },
  );

  const { data: miningInfo } = useCustomQuery<MiningInfo>(queryKeys.DASHBOARD_MINING_INFO, () =>
    createF2PoolService(clientInstance).getMinigInfo(userId),
  );

  const { data: balanceInfo } = useCustomQuery<BalanceInfo>(queryKeys.DASHBOARD_BALANCE_INFO, () =>
    createF2PoolService(clientInstance).getBalanceInfo(userId),
  );

  const { data: dailyIncomeLog } = useCustomQuery<DailyIncomeLog[]>(
    queryKeys.DASHBOARD_DAILY_INCOM_LOG,
    () => createF2PoolService(clientInstance).getBalanceBalanceHistoryInfo(userId),
    (data: DailyIncomeLog[]) => () => {
      // Группируем данные по дате
      const groupedData = data.reduce(
        (acc, item) => {
          const date = new Date(item.createdAt);
          const formattedDate = `${date.getDate()} ${date.toLocaleString('en', { month: 'short' })}`;

          if (!acc[formattedDate]) {
            acc[formattedDate] = { totalIncome: 0, count: 0 }; // Инициализируем объект для даты
          }

          acc[formattedDate].totalIncome += item.yesterdayIncome; // Суммируем доход
          acc[formattedDate].count += 1; // Увеличиваем счетчик записей

          return acc;
        },
        {} as Record<string, { totalIncome: number; count: number }>,
      );

      // Преобразуем сгруппированные данные в массив с вычисленным средним
      const array = Object.entries(groupedData).map(([date, { totalIncome, count }]) => ({
        date,
        amount: totalIncome / count, // Вычисляем среднее арифметическое
      }));

      return array;
    },
  );

  const { data: electricityCostData } = useCustomQuery(
    queryKeys.ELECTRICITY_COST,
    () => createStaticPaymentInfosService(clientInstance).getInfos(),
    (data: any) => ({ lastId: data[0].id, elecricityCost: data[0].elecricityCost }),
    undefined,
    {
      enabled: !!userId,
    },
  );

  const { data: allActiveKws } = useCustomQuery<number>(
    queryKeys.DASHBOARD_ACTIVE_ASSETS_KWS,
    () => createF2PoolService(clientInstance).getAllActiveKws(userId),
    undefined,
    undefined,
    { enabled: user?.f2PoolUserAccountInfo != null },
  );

  useEffect(() => {
    const calculateCost = () => {
      const cost = (allActiveKws as number) * (electricityCostData?.elecricityCost ?? 1); // Подставляем 1, если данные отсутствуют
      return formatToTwoPlaces(cost).toString(); // Функция форматирования
    };

    setFormattedCost(calculateCost());
  }, [allActiveKws, electricityCostData]); // Перезапуск, если входные данные изменились

  const { data: transactionHistory } = useCustomQuery<PaggingObj<TransactionHistory>>(
    queryKeys.DASHBOARD_TRANSACTION_HISTORY,
    () => createF2PoolService(clientInstance).getTransactionHistory(userId, 6, 1),
  );

  const getModalTitle = () => {
    if (withdrawal) return 'Withdrawl';
    if (deposit) return 'Deposit electricity payment';
    return '';
  };

  const getModalState = () => {
    if (withdrawal) return setWithdrawal;
    if (deposit) return setDeposit;
    return null;
  };

  const getModalContent = () => {
    if (withdrawal) return <WithdrawModalContent />;
    if (deposit) return <DepositWalletModalContent />;
    return null;
  };

  const walletBalanceHistory: any =
    user?.virtualWallet?.balanceHistories.map((history: any) => ({
      date: new Date(history.recordedAt).toLocaleDateString('en', {
        day: '2-digit',
        month: 'short',
      }),
      amount: history.balance,
    })) || [];

  // Находим последнюю транзакцию типа "deposit"
  const lastDeposit = user?.virtualWallet?.transactions
    ?.filter((t) => t.type === 'Deposit')
    .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    .shift(); // Берем первую (последнюю по дате)

  return (
    <>
      {(withdrawal || deposit) && (
        <ModalWindow title={getModalTitle()} isActive={getModalState()}>
          {getModalContent()}
        </ModalWindow>
      )}
      <div className="dashboard-container">
        <div className="dashboard-section wallet">
          <div className="header">
            <WalletIcon />
            <h2 className="poppins-medium">Electricity payment balance</h2>
          </div>
          <div className="dashboard-section-container">
            <div className="top-container">
              <div className="top">
                <TotalBalanceIcon />
                <h3 className="poppins-medium">Toal Balance</h3>
              </div>
              <span className="poppins-medium virtual-wallet-balance">
                $ {user?.virtualWallet?.currentBalance}
              </span>
            </div>
            {dailyIncomeLog && dailyIncomeLog?.length > 0 && (
              <CustomLineChart
                isVisibleAsixes={true}
                data={walletBalanceHistory}
                gradientId="gradient1"
                lineGradientId="lineGradient1"
                graphicGradient={{
                  offset: { first: '0%', second: '80.13%' },
                  stopColor: { first: '#25A8CF', second: '#19151E' },
                  stopOpacity: {
                    first: '1',
                    second: '0',
                  },
                }}
                lineGradient={{
                  offset: { first: '100%', second: '0%' },
                  stopColor: { first: '#25A8CF', second: '#19151E' },
                }}
              />
            )}
            <div className="buttons-actions">
              <button
                className="deposit-btn section-btn-gradient poppins-medium"
                onClick={() => {
                  setDeposit(!deposit);
                }}
              >
                <ReplenishIcon />
                Replenish
              </button>
              <button
                className="withdraw-btn poppins-medium"
                onClick={() => {
                  setWithdrawal(!withdrawal);
                }}
              >
                <ImportIconV2 />
                Withdraw
              </button>
            </div>
          </div>
        </div>
        <div className="dashboard-section mining">
          <div className="header">
            <ChipIcon stroke="#FFFFFF" />
            <h2 className="poppins-medium">Mining</h2>
          </div>
          <div className="dashboard-section-container">
            <div className="hashrate-amount">
              <div className="container-header">
                <SpeedIndicatorIcon />
                <span className="poppins-medium">Hash Rate Amount</span>
              </div>
              <div className="container-numbers">
                <span className="poppins-medium hashrate">
                  {miningInfo?.activeMachinesHashrate} TH/s
                </span>
                <div>
                  <ChipIcon stroke="#04ACF5" />
                  <span className="poppins-medium amount">{miningInfo?.activeMachinesCount}</span>
                </div>
              </div>
            </div>
            <div className="launch-pending">
              <div className="container-header">
                <SpeedIndicatorIcon />
                <span className="poppins-medium">Launch Pending</span>
              </div>
              <div className="container-numbers">
                <span className="poppins-medium hashrate">
                  {miningInfo?.inactiveMachinesHashrate} TH/s
                </span>
                <div>
                  <ChipIcon stroke="#e0964d" />
                  <span className="poppins-medium amount">{miningInfo?.inactiveMachinesCount}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard-section launch-pading">
          <div className="header">
            <ChipIcon stroke="#FFFFFF" />
            <h2 className="poppins-medium">Yesterday Revenue</h2>
          </div>
          <span className="poppins-medium">
            {balanceInfo?.balance_info ? balanceInfo?.balance_info.yesterday_income : 0} BTC
          </span>
          {dailyIncomeLog && dailyIncomeLog.length > 0 && (
            <CustomLineChart
              isVisibleAsixes={false}
              data={dailyIncomeLog}
              gradientId="gradient2"
              lineGradientId="lineGradient2"
              graphicGradient={{
                offset: { first: '0%', second: '91.59%' },
                stopColor: { first: '#98E7FF', second: '#3D338E' },
                stopOpacity: {
                  first: '0.15',
                  second: '0',
                },
              }}
              lineGradient={{
                offset: { first: '100%', second: '0%' },
                stopColor: { first: '#98E7FF26', second: '#3D338E' },
              }}
            />
          )}
        </div>
        <div className="dashboard-section daily-payment">
          <div className="header">
            <DailyPaymentIcon />
            <h2 className="poppins-medium">Daily payment</h2>
          </div>
          <div className="dashboard-section-container">
            <span className="poppins-medium">
              ${formattedCost} per {allActiveKws} kW
            </span>
          </div>
        </div>
        <div className="dashboard-section total-mined">
          <div className="header">
            <ChipIcon stroke="#FFFFFF" />
            <h2 className="poppins-medium">Total mined</h2>
          </div>
          <div className="dashboard-section-container">
            <span className="poppins-medium">
              {balanceInfo?.balance_info ? balanceInfo?.balance_info.total_income : 0} BTC
            </span>
          </div>
        </div>
        <div className="dashboard-section balance-sufficient">
          <div className="header">
            <SufficientBalanceIcon />
            <h2 className="poppins-medium">The balance is sufficient for</h2>
          </div>
          <div className="dashboard-section-container">
            <BalanceIndicator
              currentBalance={user?.virtualWallet ? user?.virtualWallet?.currentBalance : 1}
              dailyElectricityCost={electricityCostData ? electricityCostData.elecricityCost : 1}
              lastDepositDate={lastDeposit ? lastDeposit?.createdAt : ''}
            />
          </div>
        </div>
        <div className="dashboard-section recent-transactions">
          <div className="header">
            <div className="label">
              <RecentTransactionIcon />
              <h2 className="poppins-medium">Recent transactions</h2>
            </div>
            <div
              className="link"
              onClick={() => {
                dispatch(setActiveDashboardLink(2));
                navigate(routesClient.profileDashboardTransactionHistory.path);
              }}
            >
              <span className="poppins-medium">View all transactions</span>
              <ArrowDropDownIcon stroke="#25a8cf" />
            </div>
          </div>
          <div className="dashboard-section-container">
            {transactionHistory ? (
              <>
                {transactionHistory.items.length > 0 ? (
                  transactionHistory?.items.map((transaction, index) => (
                    <div className="transaction-row info" key={index}>
                      <div className="column">
                        <div className="title poppins-medium">Type:</div>
                        <LightningIcon stroke="#746A81" />
                        {transaction.transactionType}
                      </div>
                      <div className="column numbers poppins-medium">
                        <div className="title poppins-medium">Reference:</div>
                        {new Date(transaction.date).toLocaleString('ru', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                      </div>
                      <div className="column poppins-medium">
                        <div className="title poppins-medium">Description:</div>
                        {formatToTwoPlaces(transaction.amount)}
                      </div>
                      <div className="column numbers poppins-medium">
                        <div className="title poppins-medium">Date:</div>
                        {transaction.description}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="no-data">
                    <span className="poppins-semibold">No data</span>
                  </div>
                )}
              </>
            ) : (
              <Preloader />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileDashboard;
