import './CryptoStatistics.scss';

import React from 'react';
import Marquee from 'react-fast-marquee';

import Preloader from '../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../general/queryKeys';
import createCryptosService from '../../../general/services/cryptos';
import { clientInstance } from '../../../general/services/main/axiosInstances';
import { CryptoData } from '../../../general/types/types';

interface CryptoStatisticsProps {
  marqueeClass: string;
}

export const formatCryptoData = (data: CryptoData[]): CryptoData[] => {
  const arrayOfCryptos: CryptoData[] = [];

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      arrayOfCryptos.push({
        name: key,
        usd: data[key].usd,
        usd_24h_change: data[key].usd_24h_change,
      });
    }
  }

  return arrayOfCryptos;
};

const CryptoStatistics: React.FC<CryptoStatisticsProps> = ({ marqueeClass }) => {
  const { data: cryptosList, isLoading: isLoadingCryptos } = useCustomQuery<CryptoData[]>(
    queryKeys.CRYPTOS_LIST,
    () => createCryptosService(clientInstance).getCryptosList(),
    (data) => formatCryptoData(data),
  );

  const formatNumbers = (num: number): string => {
    const numStr = num.toString();

    if (numStr.includes('.')) {
      const parts = numStr.split('.');
      // Если число меньше единицы и имеет высокую точность после запятой
      if (parts[0] === '0') {
        // Оставляем первые пять значащих цифр после запятой
        const match = parts[1].match(/^(0*[^0]{1,5})/);
        const significantDigits = match ? match[0] : '0';
        return `0.${significantDigits}`;
      } else if (parts[0].length >= 2) {
        return `${parts[0]}.${parts[1].substring(0, 2)}`;
      } else {
        return `${parts[0]}.${parts[1].substring(0, 4)}`;
      }
    } else return numStr;
  };

  const isNumNegative = (num: number): boolean => {
    return num < 0;
  };

  const dCountPositive = {
    color: '#65E279',
  };

  const dCountNegative = {
    color: '#F66060',
  };

  return (
    <>
      {isLoadingCryptos || !cryptosList ? (
        <Preloader />
      ) : cryptosList ? (
        <Marquee className={marqueeClass} play={true} autoFill={true} speed={60}>
          {cryptosList.map((crypto: CryptoData, index: number) => (
            <article className="running-line__element" key={index}>
              <h3 className="running-line__name">{crypto.name}</h3>
              <p className="running-line__price">${formatNumbers(crypto.usd)}</p>
              <span
                style={isNumNegative(crypto.usd_24h_change) ? dCountNegative : dCountPositive}
                className="running-line__discount"
              >
                {formatNumbers(crypto.usd_24h_change)}%
              </span>
            </article>
          ))}
        </Marquee>
      ) : null}
    </>
  );
};

export default CryptoStatistics;
