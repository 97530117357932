import React from 'react';

const ArrowDropDownIcon: React.FC<{ stroke: string }> = ({ stroke }) => (
  <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.29749 1.5L5.29749 5.5L9.29749 1.5"
      stroke={stroke}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

export default ArrowDropDownIcon;
