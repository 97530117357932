import React from 'react';

const ChipIcon: React.FC<{ stroke: string }> = ({ stroke }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.33333 6.66667V5.33333H6.66667M10.6667 9.33333V10.6667H9.33333M6.66667 10.6667H5.33333V9.33333M10.6667 6.66667V5.33333H9.33333M2 6.66667H3.33333M2 9.33333H3.33333M6.66667 2V3.33333M9.33333 2V3.33333M14 6.66667H12.6667M14 9.33333H12.6667M9.33333 14V12.6667M6.66667 14V12.6667M3.33333 4C3.33333 3.82319 3.40357 3.65362 3.5286 3.5286C3.65362 3.40357 3.82319 3.33333 4 3.33333H12C12.1768 3.33333 12.3464 3.40357 12.4714 3.5286C12.5964 3.65362 12.6667 3.82319 12.6667 4V12C12.6667 12.1768 12.5964 12.3464 12.4714 12.4714C12.3464 12.5964 12.1768 12.6667 12 12.6667H4C3.82319 12.6667 3.65362 12.5964 3.5286 12.4714C3.40357 12.3464 3.33333 12.1768 3.33333 12V4Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ChipIcon;
