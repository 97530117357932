import React from 'react';
import { useSelector } from 'react-redux';
import CartProdIcon from '../../../../general/assets/svg/CartProdIcon';
import EnergyConsumption from '../../../../general/assets/svg/EnergyConsumption';
import HashRateIcon from '../../../../general/assets/svg/HashRateIcon';
import LightningIcon from '../../../../general/assets/svg/LightningIcon';
import StatisticIcon from '../../../../general/assets/svg/StatisticIcon';
import useAddToCart from '../../../../general/hooks/useAddToCart';
import useCounter from '../../../../general/hooks/useCounter';
import useSnackbar from '../../../../general/hooks/useSnakbar';
import { RootState } from '../../../../general/redux/store';
import { coinMap, CryptoData, CuverseAsicx } from '../../../../general/types/types';
import { calculateDailyIncomeUSD } from '../../../../general/utils/getFormatedValues';
import { formatToTwoPlaces } from '../../../../general/utils/Validations';
import Counter from '../../../components/inputs/Counter';

interface SelectedMinerInfoProps {
  miner: CuverseAsicx;
  cryptosList: CryptoData[];
}

const SelectedMinerInfo: React.FC<SelectedMinerInfoProps> = ({ miner, cryptosList }) => {
  const counterHook = useCounter({ initialValue: 1 });
  const { showSnackbar } = useSnackbar();
  const cart = useSelector((state: RootState) => state.pc.pc);
  const addToCart = useAddToCart({ miner, cart, counterHook, showSnackbar });

  //#region money characteristics
  // Функция для вычисления Annual ROI
  const calculateAnnualROI = (miner: CuverseAsicx) => {
    const cryptoKey: any = coinMap[miner.coin];
    const coinPrice = cryptosList?.[cryptoKey]?.usd || 0;
    const { estDailyOutput, dailyElectricityFee, price } = miner;
    if (!coinPrice) return 0;

    const cashBack = dailyElectricityFee * 0.8; // remove if unecessary

    const annualROI = ((estDailyOutput * coinPrice * 365 - cashBack * 365) / price) * 100;
    return annualROI;
  };

  const calculateMothlyProfit = (miner: CuverseAsicx) => {
    const cryptoKey: any = coinMap[miner.coin];
    const coinPrice = cryptosList?.[cryptoKey]?.usd || 0;
    const { estDailyOutput, dailyElectricityFee } = miner;

    const cashBack = dailyElectricityFee * 0.8; // remove if unecessary

    const monthlyProfit = estDailyOutput * coinPrice * 30 - cashBack * 30;
    return monthlyProfit;
  };
  //#endregion

  return (
    <div className="miner-details">
      <div className="top-section">
        <span className="name poppins-regular">{miner.model}</span>
      </div>

      <div className="miner-specs card-container">
        <div className="card">
          <div className="statistic">
            <HashRateIcon stroke="#5BACC5" />
            <span className="value poppins-regular">{miner.hashrate}</span>
            <span className="unit poppins-regular">{miner.unitSymbol}</span>
          </div>
          <span className="card-text poppins-regular">Daily income</span>
        </div>
        <div className="card">
          <div className="statistic">
            <EnergyConsumption stroke="#5BACC5" />
            <span className="value poppins-regular">{miner.powerConsumption}</span>
            <span className="unit poppins-regular">kW</span>
          </div>
          <span className="card-text poppins-regular">Energy efficiency</span>
        </div>
        <div className="card">
          <div className="statistic">
            <StatisticIcon stroke="#5BACC5" />
            <span className="value poppins-regular">
              {formatToTwoPlaces(calculateAnnualROI(miner))}
            </span>
            <span className="unit poppins-regular">%</span>
          </div>
          <span className="card-text poppins-regular">Annual ROI</span>
        </div>
        <div className="card">
          <div className="statistic">
            <LightningIcon stroke="#5BACC5" />
            <span className="value poppins-regular">
              {formatToTwoPlaces(calculateMothlyProfit(miner))}
            </span>
            <span className="unit poppins-regular">USD</span>
          </div>
          <span className="card-text poppins-regular">Monthly profit</span>
        </div>
      </div>

      <div className="miner-stats">
        <div className="stat">
          <span className="poppins-regular">Daily income:</span>
          <span className="dots"></span>
          <span className="poppins-regular">
            {miner.estDailyOutput} {miner.coin} $
            {formatToTwoPlaces(
              calculateDailyIncomeUSD(miner, coinMap, cryptosList as CryptoData[]),
            )}
          </span>
        </div>
        <div className="stat">
          <span className="poppins-regular">Daily electricity fee:</span>
          <span className="dots"></span>
          <span className="poppins-regular">{formatToTwoPlaces(miner.dailyElectricityFee)} $</span>
        </div>
      </div>

      <div className="miner-actions">
        <div className="miner-price">
          <h4 className="poppins-semibold poppins-semibold">{miner.price * counterHook.count} $</h4>
        </div>
        <div className="product-section__info-characteristics__counts-buttons">
          <div className="product-section__info-characteristics__counts-buttons__counts unselectable">
            <Counter
              productId={miner.id.toString()}
              counterHook={counterHook}
              syncWithCart={false}
            />
          </div>
        </div>
        <div className="buttons">
          <button className="buy-btn poppins-bold" onClick={addToCart}>
            <CartProdIcon />
            Buy
          </button>
          <button className="info-btn poppins-bold">Miner info</button>
        </div>
      </div>
    </div>
  );
};

export default SelectedMinerInfo;
