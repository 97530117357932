import React from 'react';

const ReplenishIcon: React.FC = () => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.4974 1.66797L10.7674 1.6688L11.0324 1.67214L11.5457 1.6863L11.7949 1.69714L12.2774 1.72547L12.7382 1.7638C16.7257 2.15047 18.3482 3.77297 18.7349 7.76047L18.7732 8.2213L18.8016 8.7038C18.8057 8.78547 18.8099 8.8688 18.8124 8.95297L18.8266 9.4663L18.8307 10.0013L18.8266 10.5363L18.8124 11.0496L18.8016 11.2988L18.7732 11.7813L18.7349 12.2421C18.3482 16.2296 16.7257 17.8521 12.7382 18.2388L12.2774 18.2771L11.7949 18.3055C11.7132 18.3096 11.6299 18.3138 11.5457 18.3163L11.0324 18.3305L10.4974 18.3346L9.9624 18.3305L9.44906 18.3163L9.1999 18.3055L8.7174 18.2771L8.25656 18.2388C4.26906 17.8521 2.64656 16.2296 2.2599 12.2421L2.22156 11.7813L2.19323 11.2988C2.18918 11.2158 2.18556 11.1327 2.1824 11.0496L2.16823 10.5363C2.16573 10.3613 2.16406 10.183 2.16406 10.0013L2.1649 9.7313L2.16823 9.4663L2.1824 8.95297L2.19323 8.7038L2.22156 8.2213L2.2599 7.76047C2.64656 3.77297 4.26906 2.15047 8.25656 1.7638L8.7174 1.72547L9.1999 1.69714C9.28156 1.69297 9.3649 1.6888 9.44906 1.6863L9.9624 1.67214C10.1374 1.66964 10.3157 1.66797 10.4974 1.66797ZM10.4974 6.66797C10.2764 6.66797 10.0644 6.75577 9.90814 6.91205C9.75186 7.06833 9.66406 7.28029 9.66406 7.5013V9.16797H7.9974L7.8999 9.1738C7.68898 9.19889 7.49561 9.30356 7.35928 9.46644C7.22296 9.62932 7.15397 9.83811 7.16642 10.0501C7.17887 10.2622 7.27181 10.4615 7.42626 10.6073C7.5807 10.7531 7.785 10.8344 7.9974 10.8346H9.66406V12.5013L9.6699 12.5988C9.69498 12.8097 9.79966 13.0031 9.96254 13.1394C10.1254 13.2757 10.3342 13.3447 10.5462 13.3323C10.7583 13.3198 10.9575 13.2269 11.1034 13.0724C11.2492 12.918 11.3305 12.7137 11.3307 12.5013V10.8346H12.9974L13.0949 10.8288C13.3058 10.8037 13.4992 10.699 13.6355 10.5362C13.7718 10.3733 13.8408 10.1645 13.8284 9.95246C13.8159 9.74043 13.723 9.54115 13.5685 9.39534C13.4141 9.24953 13.2098 9.1682 12.9974 9.16797H11.3307V7.5013L11.3249 7.4038C11.301 7.20109 11.2036 7.0142 11.051 6.87856C10.8985 6.74293 10.7015 6.668 10.4974 6.66797Z"
      fill="white"
    />
  </svg>
);

export default ReplenishIcon;
