import React from 'react';

import GuaranteedSafeTransactionIcon from '../../../general/assets/svg/GuaranteedSafeTransactionIcon';
import IndividualConditionIcon from '../../../general/assets/svg/IndividualConditionIcon';
import PreShipmentTestingIcon from '../../../general/assets/svg/PreShipmentTestingIcon';
import RemoteCustomerSupportIcon from '../../../general/assets/svg/RemoteCustomerSupportIcon';

const Features = () => {
  return (
    <>
      <div className="product-section-guarantee__element">
        <IndividualConditionIcon />
        <h2 className="poppins-regular">Individual conditions</h2>
        <p className="poppins-light">Tailored Solutions for Wholesale</p>
      </div>
      <div className="product-section-guarantee__element">
        <GuaranteedSafeTransactionIcon />
        <h2 className="poppins-regular">Guaranteed safe transaction</h2>
        <p className="poppins-light">Commitment to Quality</p>
      </div>
      <div className="product-section-guarantee__element">
        <RemoteCustomerSupportIcon />
        <h2 className="poppins-regular">Remote customer support</h2>
        <p className="poppins-light">Comprehensive Setup & Guidance</p>
      </div>
      <div className="product-section-guarantee__element">
        <PreShipmentTestingIcon />
        <h2 className="poppins-regular">Pre-shipment testing</h2>
        <p className="poppins-light">Meticulous Preparation & Delivery Coordination</p>
      </div>
    </>
  );
};

export default Features;
