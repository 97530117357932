import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import ExportIconV2 from '../../../../../general/assets/svg/ExportIconV2';
import LightningIcon from '../../../../../general/assets/svg/LightningIcon';
import ScrewIcon from '../../../../../general/assets/svg/ScrewIcon';
import Preloader from '../../../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../../../general/hooks/useCustomQuerry';
import usePagination from '../../../../../general/hooks/usePagination';
import { queryKeys } from '../../../../../general/queryKeys';
import { RootState } from '../../../../../general/redux/store';
import createF2PoolService from '../../../../../general/services/f2pool/f2Pool';
import { clientInstance } from '../../../../../general/services/main/axiosInstances';
import { PaggingObj } from '../../../../../general/types/types';
import getFile from '../../../../../general/utils/GetFile';
import Pagination from '../../../../components/pagination/Pagination';
import './TransactionHistory.scss';

export const transactionDates = ['November 11, 2024', 'November 10, 2024'];

interface TransactionHistoryDTO {
  date: string;
  transactionType: string;
  reference: string;
  description: string;
  amount: string;
}

const ProfileTransactionHistory: React.FC = () => {
  const userId = useSelector((state: RootState) => state.clps.cp2);
  const { page, pageSize, setPage } = usePagination(4);

  const {
    data: transactionHistory,
    isFetching: historyLoading,
    refetch: getTransactionHistory,
  } = useCustomQuery<PaggingObj<TransactionHistoryDTO>>(
    queryKeys.DASHBOARD_TRANSACTION_HISTORY,
    () => createF2PoolService(clientInstance).getTransactionHistory(userId, pageSize, page),
  );

  useEffect(() => {
    getTransactionHistory();
  }, [getTransactionHistory, page]);

  const groupedTransactions = useMemo(() => {
    if (!transactionHistory?.items) return {};
    return transactionHistory.items.reduce<Record<string, TransactionHistoryDTO[]>>(
      (acc, transaction) => {
        const date = new Date(transaction.date).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(transaction);
        return acc;
      },
      {},
    );
  }, [transactionHistory]);

  const { refetch: getXLSX } = useCustomQuery<ArrayBuffer>(
    queryKeys.DASHBOARD_ASSETS_XLSX,
    () => createF2PoolService(clientInstance).getTransactionsHistoryXLSX(userId),
    undefined,
    undefined,
    {
      enabled: false,
      onSuccess(data: ArrayBuffer | undefined) {
        getFile(data as ArrayBuffer, 'Pool Assets');
      },
    },
  );

  return (
    <div className="transaction-history-container">
      <div className="transaction-header">
        <div>
          <ScrewIcon />
          <h2 className="poppins-medium">Transaction History</h2>
        </div>
        <button
          className="download-btn"
          onClick={() => {
            getXLSX();
          }}
        >
          <ExportIconV2 stroke="#919191" />
        </button>
      </div>
      <div className="transaction-table">
        <div className="transaction-row header">
          <div className="column poppins-medium">Transaction type</div>
          <div className="column poppins-medium">Amount</div>
          <div className="column poppins-medium">Description</div>
          <div className="column poppins-medium">Time</div>
        </div>
        {historyLoading ? (
          <Preloader />
        ) : (
          <>
            {transactionHistory && transactionHistory.totalCount > 0 ? (
              groupedTransactions &&
              Object.entries(groupedTransactions).map(([date, transactions]) => (
                <React.Fragment key={date}>
                  <div className="transaction-date poppins-medium">{date}</div>
                  {transactions.map((transaction, index) => (
                    <div className="transaction-row info" key={index}>
                      <div className="column">
                        <div className="title poppins-medium">Type:</div>
                        <LightningIcon stroke="#746A81" />
                        {transaction.transactionType}
                      </div>
                      <div className="column numbers poppins-medium">
                        <div className="title poppins-medium">Amount:</div>$ {transaction.amount}
                      </div>
                      <div className="column poppins-medium">
                        <div className="title poppins-medium">Description:</div>
                        {transaction.description}
                      </div>
                      <div className="column numbers poppins-medium">
                        <div className="title poppins-medium">Date:</div>
                        {new Date(transaction.date).toLocaleTimeString('en-US', {
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: false,
                        })}
                      </div>
                    </div>
                  ))}
                </React.Fragment>
              ))
            ) : (
              <div className="no-data">
                <span className="poppins-semibold">No data</span>
              </div>
            )}
          </>
        )}
        {/* {transactionHistory || !historyLoading ? (
          <>
            {transactionHistory && transactionHistory.totalCount > 0 ? (
              groupedTransactions &&
              Object.entries(groupedTransactions).map(([date, transactions]) => (
                <React.Fragment key={date}>
                  <div className="transaction-date poppins-medium">{date}</div>
                  {transactions.map((transaction, index) => (
                    <div className="transaction-row info" key={index}>
                      <div className="column">
                        <div className="title poppins-medium">Type:</div>
                        <LightningIcon stroke="#746A81" />
                        {transaction.transactionType}
                      </div>
                      <div className="column numbers poppins-medium">
                        <div className="title poppins-medium">Amount:</div>$ {transaction.amount}
                      </div>
                      <div className="column poppins-medium">
                        <div className="title poppins-medium">Description:</div>
                        {transaction.description}
                      </div>
                      <div className="column numbers poppins-medium">
                        <div className="title poppins-medium">Date:</div>
                        {new Date(transaction.date).toLocaleTimeString('en-US', {
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: false,
                        })}
                      </div>
                    </div>
                  ))}
                </React.Fragment>
              ))
            ) : (
              <div className="no-data">
                <span className="poppins-semibold">No data</span>
              </div>
            )}
          </>
        ) : (
          <Preloader />
        )} */}
      </div>
      {transactionHistory && transactionHistory.pageSize < transactionHistory.totalCount && (
        <Pagination
          page={page}
          setPage={setPage}
          isNextPage={transactionHistory.hasNextPage}
          isPrevPage={transactionHistory.hasPreviousPage}
          pageSize={transactionHistory.pageSize}
          total={transactionHistory.totalCount}
        />
      )}
    </div>
  );
};

export default ProfileTransactionHistory;
