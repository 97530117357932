import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DeleteActionIcon from '../../../../general/assets/svg/DeleteActionIcon';
import EditIconAction from '../../../../general/assets/svg/EditIconAction';
import Preloader from '../../../../general/components/preloader/Preloader';
import useCustomMutation from '../../../../general/hooks/useCustomMutation';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import useKey, { keys } from '../../../../general/hooks/useKey';
import usePagination from '../../../../general/hooks/usePagination';
import { useResize } from '../../../../general/hooks/useResize';
import { queryKeys } from '../../../../general/queryKeys';
import { addAsicModeToEdit } from '../../../../general/redux/reducers/Product-Reducer';
import createF2PoolService from '../../../../general/services/f2pool/f2Pool';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import { AsicModelDTO, PaggingObj } from '../../../../general/types/types';
import Confirm from '../../../components/confirm/Confirm';
import CustomPagination from '../../../components/tables/custom-pagination/CustomPagination';
import CustomTable from '../../../components/tables/custom-table/CustomTable';
import AsicModelsEditor from './AsicModelsEditor';

interface ActionButtonsProps {
  item: AsicModelDTO;
  deleteMethod?: (id: string) => void;
  editMethod?: (id: string) => void;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ item, deleteMethod, editMethod }) => {
  const [deleteAffiliateAsk, setDeleteAffiliateAsk] = useState(false);

  useKey((event) => {
    if (event.key === keys.ESCAPE) {
      deleteAffiliateAsk && setDeleteAffiliateAsk(false);
    }
  });

  return (
    <>
      <button
        onClick={() => editMethod && editMethod(item.id)}
        className="table-block__tbody__change-item__button"
      >
        <EditIconAction />
      </button>
      <button
        onClick={() => setDeleteAffiliateAsk(true)}
        className="table-block__tbody__change-item__button"
      >
        <DeleteActionIcon />
      </button>
      {deleteAffiliateAsk && (
        <Confirm
          title="Delete confirm"
          text={'Are you shure want delete this store?'}
          handler={setDeleteAffiliateAsk}
          clickEvent={() => {
            if (deleteMethod) {
              deleteMethod(item.id);
            }
          }}
        />
      )}
    </>
  );
};

const AsicModels = () => {
  const dispatch = useDispatch();
  const size = useResize();
  const { page, pageSize, searchQuery, setPage, setPageSize } = usePagination(4);
  const [isModal, setIsModal] = useState<boolean>(false);
  const headers = ['image', 'models', 'manufacturer'];

  const {
    data: models,
    isLoading: modelsLoading,
    refetch: getModels,
  } = useCustomQuery<PaggingObj<AsicModelDTO>>(queryKeys.ASICS_MODELS, () =>
    createF2PoolService(adminInstance).getAsicsModels(pageSize, page),
  );

  const { mutate: removeMinerInfo } = useCustomMutation(
    createF2PoolService(adminInstance).removeAsicModel,
    undefined,
    {
      onSuccess() {
        getModels();
      },
    },
  );

  useEffect(() => {
    getModels();
  }, [searchQuery, page, pageSize, getModels]);

  const handleEdit = (id: string) => {
    const asicToEdit = models?.items.find((info) => info.id === id);
    asicToEdit && dispatch(addAsicModeToEdit(asicToEdit));
    setIsModal(true);
  };

  const handleRemove = (id: string) => {
    removeMinerInfo(id);
  };

  const actionButtons = (
    item: AsicModelDTO,
    deleteMethod?: (id: any) => void,
    editMethod?: (id: any) => void,
  ) => <ActionButtons item={item} deleteMethod={deleteMethod} editMethod={editMethod} />;

  const addNewModel = () => {
    dispatch(addAsicModeToEdit(null));
    setIsModal(true);
  };

  return (
    <>
      {isModal && <AsicModelsEditor isOpen={setIsModal} refresh={getModels} />}
      {modelsLoading ? (
        <Preloader />
      ) : (
        <>
          <div className="hosting-locations-table">
            <CustomTable
              data={models ? models?.items : []}
              nameOfAction={'actions'}
              headers={headers}
              refreshMethod={handleEdit}
              deleteMethod={handleRemove}
              isAction={true}
              isMobile={size.width < 850}
              actionComponent={actionButtons}
            />
          </div>
          <button
            className="section-btn-gradient add-new"
            onClick={() => {
              addNewModel();
            }}
          >
            Add new asic model
          </button>
          {models && models?.pageSize < models?.totalCount && (
            <CustomPagination
              currentPage={page}
              pageSize={pageSize}
              setPage={setPage}
              setPageSize={setPageSize}
              isNextPage={models.hasNextPage}
              isPrevPage={models.hasPreviousPage}
              total={models.totalCount}
            />
          )}
        </>
      )}
    </>
  );
};

export default AsicModels;
