import React from 'react';

const TwoArrowIcon: React.FC = () => (
  <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.0013 9.33333L16.668 4M16.668 4L11.3346 9.33333M16.668 4V11.192C16.6679 12.6709 17.0329 14.127 17.7307 15.4309C18.4285 16.7348 19.4374 17.8463 20.668 18.6667C21.8985 19.487 22.9074 20.5985 23.6052 21.9024C24.303 23.2064 24.6681 24.6624 24.668 26.1413V28M8.66797 28V28.0133M8.66797 24.013V24.0264M10.0013 20.026V20.0394M14.0013 17.3724V17.3857"
      stroke="#25A8CF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TwoArrowIcon;
