import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import Confirm from '../../../../admin/components/confirm/Confirm';
import BucketIcon from '../../../../general/assets/svg/BucketIcon';
import useCounter from '../../../../general/hooks/useCounter';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../../general/queryKeys';
import { removeCartItem } from '../../../../general/redux/reducers/ClientCart-Reducer';
import { RootState } from '../../../../general/redux/store';
import { routesClient } from '../../../../general/routes';
import createF2PoolService from '../../../../general/services/f2pool/f2Pool';
import { clientInstance } from '../../../../general/services/main/axiosInstances';
import { AsicModelDTO, PaggingObj, ProductMax } from '../../../../general/types/types';
import getMinerFieldValue, { partners } from '../../../../general/utils/getFormatedValues';
import { formatToTwoPlaces } from '../../../../general/utils/Validations';
import Counter from '../../../components/inputs/Counter';

interface OrderProps {
  order: {
    c: number;
    p: ProductMax;
  };
}

const Order: React.FC<OrderProps> = ({ order }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const counterHook = useCounter({ initialValue: order.c });
  const userCart = useSelector((state: RootState) => state.pc);
  const cartSeller = useSelector((state: RootState) => state.pc.seller);

  const [removeLastItemQuestion, setRemoveLastItemQuestion] = useState(false);

  const removeProdFromCart = () => {
    if (userCart.pc.length <= 1) {
      setRemoveLastItemQuestion(true);
    } else {
      dispatch(removeCartItem(order.p.id));
    }
  };

  const discountedPrice = useMemo(() => {
    const discount = order.p.price * counterHook.count * (order.p.percentageDiscount / 100);
    return order.p.price * counterHook.count - discount;
  }, [order.p.price, counterHook.count, order.p.percentageDiscount]);

  const price = useMemo(() => {
    return order.p.price * counterHook.count;
  }, [order.p.price, counterHook.count]);

  const { data: models } = useCustomQuery<PaggingObj<AsicModelDTO>>(queryKeys.ASICS_MODELS, () =>
    createF2PoolService(clientInstance).getAsicsModels(100, 1),
  );

  return (
    <>
      <div className="prod" style={{ display: 'flex' }}>
        <Link to={getMinerFieldValue(order.p, 'url')}>
          <div
            className="prod-img"
            style={{
              backgroundImage: `url(${
                order.p.seller === partners.cuversePartners
                  ? models?.items
                      .find((info) =>
                        info.nameVariants.some((variant) =>
                          order.p.model.toLowerCase().includes(variant.variant.toLowerCase()),
                        ),
                      )
                      ?.images.at(-1)?.url
                  : getMinerFieldValue(order.p, 'image')
              })`,
            }}
          />
        </Link>
        <div className="prod-info">
          <div className="condition">
            {cartSeller === null || cartSeller === partners.cuversePartners
              ? 'New'
              : order.p.condition}
          </div>
          <Link to={getMinerFieldValue(order.p, 'url')}>
            <p>{getMinerFieldValue(order.p, 'name')}</p>
          </Link>
          <div className="price-section">
            <div className="product-section__info-characteristics__counts-buttons__counts">
              <Counter productId={order.p.id} counterHook={counterHook} />
            </div>
            {order.p.percentageDiscount === 0 ? (
              <span style={{ fontWeight: 600 }}>
                ${formatToTwoPlaces(order.p.price * counterHook.count)}
              </span>
            ) : (
              <div className="prices-section">
                <span className="price-without-discount">{`$${formatToTwoPlaces(price)}`}</span>
                {discountedPrice > 0 && (
                  <span className="price-with-discount" style={{ fontWeight: 600 }}>
                    {`$${formatToTwoPlaces(discountedPrice)}`}
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
        <div onClick={removeProdFromCart}>
          <BucketIcon />
        </div>
        {removeLastItemQuestion && (
          <Confirm
            title="Delete confirm"
            text={'Are you sure you want to delete last position and go to cart?'}
            handler={setRemoveLastItemQuestion}
            clickEvent={() => {
              dispatch(removeCartItem(order.p.id));
              navigate(routesClient.cart.path);
            }}
          />
        )}
      </div>
    </>
  );
};

export default Order;
