import { AxiosInstance } from 'axios';

interface CuverseService {
  getCuverseMiners: () => Promise<any>;
  getCuverseMinerById: (ids: string[]) => Promise<any>;
}

const createCuverseService = (instance: AxiosInstance): CuverseService => ({
  async getCuverseMiners() {
    try {
      const response = await instance.get(`/Cuverse/getMiners`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getCuverseMinerById(ids: string[]) {
    try {
      const idsParam = ids.join(',');
      const response = await instance.get(`/Cuverse/getMinersByIds?Ids=${idsParam}`);
      return response;
    } catch (error) {
      return error;
    }
  },
});

export default createCuverseService;
