import React from 'react';

const DashboardIcon: React.FC<{ stroke: string }> = ({ stroke }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6227_3659)">
      <path
        d="M1 0.332031V14.9987H15.6667"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path d="M12.4766 6.71484V13.0882" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" />
      <path d="M8.65625 1.60547V13.0855" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" />
      <path d="M4.82812 4.16016V13.0868" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" />
    </g>
    <defs>
      <clipPath id="clip0_6227_3659">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default DashboardIcon;
