import React, { Fragment, useEffect } from 'react';
import QaFaqOpenQuestionIcon from '../../../../general/assets/svg/QaFaqOpenQuestionIcon';
import QaFaqTitleDecorationIcon from '../../../../general/assets/svg/QaFaqTitleDecorationIcon';
import useDynamicHeight from '../../../../general/hooks/useDinamicHeight';
import { Question } from './FAQ';

interface QuestionItemProps {
  question: Question;
  isOpen: boolean;
  onToggle: () => void;
}

const QuestionItem: React.FC<QuestionItemProps> = ({ question, isOpen, onToggle }) => {
  const { ref, style, setIsOpen } = useDynamicHeight();

  // Изменяем локальное состояние в зависимости от внешнего
  useEffect(() => {
    setIsOpen(isOpen);
  }, [isOpen, setIsOpen]);

  return (
    <div
      className={`help-center__aq__question ${isOpen ? 'question-open' : ''}`}
      onClick={onToggle}
    >
      <div className="help-center__aq__question__title">
        <article className="help-center__aq__question__title__decoration">
          <QaFaqTitleDecorationIcon />
        </article>
        <h1 className="help-center__aq__question__title__text">
          Q: {question.question.toUpperCase()}
        </h1>
        <button className="help-center__aq__question__title__button">
          <QaFaqOpenQuestionIcon />
        </button>
      </div>
      <div ref={ref} className="help-center__aq__question__content" style={style}>
        <div className="help-center__aq__question__content__text">
          A:{' '}
          {question.answer.split('\n').map((line, index) => (
            <Fragment key={index}>
              {line}
              <br />
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default QuestionItem;
