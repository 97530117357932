import { FieldConfig, ValidationError } from '../types/types';

export const errorMessages: string[] = [
  'Field cannot be empty or contain Cyrillic characters',
  'Invalid email format',
  'Field cannot be empty',
  'Invalid phone number, only digits, + , and - are allowed',
  'Invalid input',
];

export const regexp = {
  numbers: /^[A-Za-z0-9 ]+$/,
  string: /^[\p{L}0-9\s,.'\-/:\\@_]+$|^https:\/\/t\.me\/[\p{L}0-9]+$/u,
  email_regexp: /^[A-Z0-9][A-Z0-9._-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  phone_regexp: /^\+?(\d{1,3}[-\s]?)?(\(\d{1,3}\)|\d{1,3})[-\s]?\d{1,4}[-\s]?\d{1,4}[-\s]?\d{1,4}$/,
  telegram_regexp: /^https:\/\/t\.me\/[a-zA-Z0-9_]{5,32}$/,
  whatsapp_regexp: /^https:\/\/wa\.me\/\d{10,15}$/,
};

export const validate = (value: string, regex: RegExp, errorMessage: string): void => {
  if (!regex.test(value)) {
    throw new Error(errorMessage);
  }
};

const setFieldError = (input: HTMLInputElement | HTMLTextAreaElement, array: boolean[]): void => {
  input.style.border = '1px solid rgb(246, 96, 96)';
  array.push(false);
};

const validateField = (
  input: HTMLInputElement | HTMLTextAreaElement,
  regex: RegExp,
  errorField: string,
  errorMessage: string,
  required: boolean[],
  optional = false,
  errors: ValidationError[],
): void => {
  if (!optional && input.value.trim() === '') {
    setFieldError(input, required);
    errors.push({ field: errorField, message: errorMessages[2] });
    return;
  }

  if (optional && input.value.trim() === '') return;

  if (!regex.test(input.value.trim())) {
    setFieldError(input, required);
    errors.push({ field: errorField, message: errorMessage });
  }
};

export const validatePhoneNumber = (
  input: HTMLInputElement | HTMLTextAreaElement,
  errorField: string,
  required: boolean[],
  optional = false,
  errors: ValidationError[],
): void => {
  if (optional && input.value.trim() === '') return;

  if (input.value.trim() === '') {
    setFieldError(input, required);
    errors.push({ field: errorField, message: 'Cannot be empty' });
  } else if (!regexp.phone_regexp.test(input.value.trim())) {
    setFieldError(input, required);
    errors.push({
      field: errorField,
      message: errorMessages[3],
    });
  }
};

export const validateFields = (fields: FieldConfig[], required: boolean[]): ValidationError[] => {
  const errors: ValidationError[] = [];

  fields.forEach((field) => {
    if (field.validateFunc) {
      field.validateFunc(field.input, field.errorField, required, field.optional || false, errors);
    } else if (field.regex && field.errorMessage) {
      validateField(
        field.input,
        field.regex,
        field.errorField,
        field.errorMessage,
        required,
        field.optional || false,
        errors,
      );
    }
  });

  return errors;
};

export const formatToTwoPlaces = (value: number) => {
  if (value) {
    return value.toFixed(2).toString();
  } else {
    return 0;
  }
};
