import { ChangeEvent, useState } from 'react';

type UseOptionSelectorProps<T> = {
  initialOption: T;
  onChange?: (selectedOption: T) => void;
};

// Custom hook for selecting options
export const useOptionSelector = <T>({ initialOption, onChange }: UseOptionSelectorProps<T>) => {
  const [selectedOption, setSelectedOption] = useState<T>(initialOption);

  const handleOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    let newOption: T;

    // Преобразуем значение в boolean, если оно равно "true" или "false"
    if (event.target.value === 'true') {
      newOption = true as T;
    } else if (event.target.value === 'false') {
      newOption = false as T;
    } else {
      newOption = event.target.value as unknown as T;
    }

    setSelectedOption(newOption);

    if (onChange) {
      onChange(newOption);
    }
  };

  return { selectedOption, handleOptionChange, setSelectedOption };
};
