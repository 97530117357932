import './Cart.scss';

import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import BucketIcon from '../../../general/assets/svg/BucketIcon';
import useCounter from '../../../general/hooks/useCounter';
import useCustomQuery from '../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../general/queryKeys';
import { removeCartItem } from '../../../general/redux/reducers/ClientCart-Reducer';
import createF2PoolService from '../../../general/services/f2pool/f2Pool';
import { clientInstance } from '../../../general/services/main/axiosInstances';
import { AsicModelDTO, CartItem, PaggingObj } from '../../../general/types/types';
import getMinerFieldValue, { minerFrom, partners } from '../../../general/utils/getFormatedValues';
import { formatToTwoPlaces } from '../../../general/utils/Validations';
import Counter from '../../components/inputs/Counter';

interface TableRowProps {
  row: CartItem;
  className: string;
}

const TableRow: React.FC<TableRowProps> = ({ row, className }) => {
  const dispatch = useDispatch();
  const counterHook = useCounter({ initialValue: row.c });

  const deleterRow = () => {
    dispatch(removeCartItem(getMinerFieldValue(row.p, 'id')));
  };

  const getFormattedPrice = (row: CartItem) => {
    const price = row.p.price;
    return price !== undefined ? `$${formatToTwoPlaces(price)}` : '$0.00';
  };

  const { data: models } = useCustomQuery<PaggingObj<AsicModelDTO>>(queryKeys.ASICS_MODELS, () =>
    createF2PoolService(clientInstance).getAsicsModels(100, 1),
  );

  return (
    <tr className={className}>
      <td className="tbody__modal">
        <Link
          to={getMinerFieldValue(row.p, 'url')}
          onClick={() => {
            localStorage.setItem('24setupOfferAnchor', 'miners');
            localStorage.setItem('24setupOfferSelectedMinerId', row.p.id.toString());
          }}
        >
          <div className="tbody__modal__image">
            {models && (
              <div
                className="img"
                style={{
                  backgroundImage: `url(${
                    row.p.seller === partners.cuversePartners
                      ? models?.items
                          .find((info) =>
                            info.nameVariants.some((variant) =>
                              row.p.model.toLowerCase().includes(variant.variant.toLowerCase()),
                            ),
                          )
                          ?.images.at(-1)?.url
                      : getMinerFieldValue(row.p, 'image')
                  })`,
                }}
              ></div>
            )}
          </div>
          <div className="tbody__modal__text">
            <span className="body_modal_text__name">{getMinerFieldValue(row.p, 'name')}</span>
            <div className="body_modal_text__ch">
              {row.p.seller === partners.cuversePartners
                ? models?.items.find((info) =>
                    info.nameVariants.some((variant) =>
                      row.p.model.toLowerCase().includes(variant.variant.toLowerCase()),
                    ),
                  )?.manufacturerName
                : getMinerFieldValue(row.p, 'manufacturer')}
            </div>
            <span className="body_modal_text__seller">{minerFrom(row.p)}</span>
          </div>
        </Link>
      </td>
      <td>
        <div className="tbody__modal__td__div">
          {getMinerFieldValue(row.p, 'amount') ? 'Pre-order' : 'Available'}
        </div>
      </td>
      <td>
        <div className="tbody__modal__td__div">
          <div className="tbody__modal__td__div__price">
            {getMinerFieldValue(row.p, 'percentageDiscount') === 0 ? (
              getFormattedPrice(row)
            ) : (
              <>
                <span className="price-without-discount">{getFormattedPrice(row)}</span>
                <span className="price-with-discount">{`$${formatToTwoPlaces(
                  row.p.price -
                    row.p.price * (getMinerFieldValue(row.p, 'percentageDiscount') / 100),
                )}`}</span>
              </>
            )}
          </div>
        </div>
      </td>
      <td>
        <div
          style={{ justifyContent: 'center' }}
          className="product-section__info-characteristics__counts-buttons__counts unselectable"
        >
          <Counter productId={row.p.id.toString()} counterHook={counterHook} />
        </div>
      </td>
      <td>
        <div className="tbody__modal__td__div">
          <div className="tbody__modal__td__div__price">
            {getMinerFieldValue(row.p, 'percentageDiscount') === 0 ? (
              <span style={{ fontWeight: 600 }}>
                ${formatToTwoPlaces(row.p.price * counterHook.count)}
              </span>
            ) : (
              <>
                <span className="price-without-discount">{`$${formatToTwoPlaces(
                  row.p.price * counterHook.count,
                )}`}</span>
                <span className="price-with-discount" style={{ fontWeight: 600 }}>
                  {`$${formatToTwoPlaces(
                    row.p.price * counterHook.count -
                      row.p.price *
                        counterHook.count *
                        (getMinerFieldValue(row.p, 'percentageDiscount') / 100),
                  )}`}
                </span>
              </>
            )}
          </div>
        </div>
      </td>
      <td>
        <div className="tbody__modal__td__div">
          <button onClick={deleterRow}>
            <BucketIcon />
          </button>
        </div>
      </td>
    </tr>
  );
};

export default TableRow;
