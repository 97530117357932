import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ClientPanelState {
  isNestedHeader: boolean;
  cuverseMinerSelectedId: string | null;
}

const initialState: ClientPanelState = {
  isNestedHeader: false,
  cuverseMinerSelectedId: null,
};

const ClientPanelSlice = createSlice({
  name: 'clientPanel',
  initialState,
  reducers: {
    setIsNestedHeader: (state, action: PayloadAction<boolean>) => {
      state.isNestedHeader = action.payload;
    },
    setSelectedCuverseMinerId: (state, action: PayloadAction<string | null>) => {
      state.cuverseMinerSelectedId = action.payload;
    },
  },
});

export const { setIsNestedHeader, setSelectedCuverseMinerId } = ClientPanelSlice.actions;
export default ClientPanelSlice.reducer;
