import React from 'react';

const SpeedIndicatorIcon: React.FC = () => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.69667 16.6353C3.64779 15.5864 2.93349 14.25 2.64411 12.7952C2.35473 11.3403 2.50326 9.83232 3.07092 8.46188C3.63858 7.09144 4.59987 5.92011 5.83324 5.096C7.0666 4.2719 8.51665 3.83203 10 3.83203C11.4834 3.83203 12.9334 4.2719 14.1668 5.096C15.4001 5.92011 16.3614 7.09144 16.9291 8.46188C17.4968 9.83232 17.6453 11.3403 17.3559 12.7952C17.0665 14.25 16.3522 15.5864 15.3033 16.6353M13.3333 7.9987L10 11.332"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SpeedIndicatorIcon;
