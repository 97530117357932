import React from 'react';
import ElectricityCost from './electrisity-cost/ElectricityCost';

import './ProjectHashrate.scss';
import AsicModels from './asic-models/AsicModels';

const ProjectHasrate = () => {
  return (
    <div className="page-section__project-hasrate">
      <div className="page-section__top">
        <div className="page-section__top__title">Project Hasrate</div>
      </div>
      <div className="electricity-price__block">
        <ElectricityCost />
      </div>
      <div className="asic-models__block">
        <AsicModels />
      </div>
    </div>
  );
};

export default ProjectHasrate;
