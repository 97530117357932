import React from 'react';
import ExportIconV2 from '../../../../general/assets/svg/ExportIconV2';
import './DepositWalletModalContent.scss';

const DepositWalletModalContent = () => {
  return (
    <div className="deposit-wallet-container">
      <span className="poppins-medium sub-header">select amount</span>
      <ul>
        <li className="poppins-regular">$50</li>
        <li className="poppins-regular">$100</li>
        <li className="poppins-regular">$300</li>
        <li className="poppins-regular">$500</li>
      </ul>
      <span className="poppins-medium sub-header">or enter another amount</span>
      <div className="input-container">
        <input
          className="client-text-field"
          type="number"
          placeholder="type a current value"
          name="deposit"
          defaultValue={0}
        />
        <span className="poppins-regular">$</span>
      </div>
      <button className="section-btn-gradient">
        <ExportIconV2 stroke="white" />
        Deposit
      </button>
    </div>
  );
};

export default DepositWalletModalContent;
