import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CartItem, CuverseAsicx, ProductMax } from '../../types/types';

interface CartState {
  pc: CartItem[];
  seller: string | null;
  count: number;
}

const initialState: CartState = {
  pc: [],
  seller: '',
  count: 0,
};

const CartSlice = createSlice({
  name: 'pc',
  initialState,
  reducers: {
    setSellerInCart: (state, action: PayloadAction<string | null>) => {
      state.seller = action.payload;
    },
    addCartItem: (
      state,
      action: PayloadAction<{ p: ProductMax | CuverseAsicx; c: number; url?: string }>,
    ) => {
      const index = state.pc.findIndex((item) => item.p.id === action.payload.p.id);
      if (index !== -1) {
        state.pc[index].c += 1;
        state.count += 1;
      } else {
        state.pc.push({
          p: action.payload.p,
          c: 1,
          url: action.payload.url,
        });
        state.count += 1;
      }
    },
    addCartItemWithCount: (
      state,
      action: PayloadAction<{ p: ProductMax | CuverseAsicx; c: number; url?: string }>,
    ) => {
      const index = state.pc.findIndex((item) => item.p.id === action.payload.p.id);
      if (index !== -1) {
        state.pc[index].c += action.payload.c;
        state.count += action.payload.c;
      } else {
        state.pc.push({
          p: action.payload.p,
          c: action.payload.c,
          url: action.payload.url,
        });
        state.count += action.payload.c;
      }
    },
    changeCartItemCount: (state, action: PayloadAction<{ p: string; c: '++' | '--' }>) => {
      const index = state.pc.findIndex((item) => item.p.id === action.payload.p);
      if (index !== -1) {
        if (action.payload.c === '++') {
          state.pc[index].c += 1;
          state.count += 1;
        } else if (action.payload.c === '--') {
          state.pc[index].c -= 1;
          state.count -= 1;
        }
      }
    },
    setCartItemCount: (state, action: PayloadAction<{ p: string; c: number }>) => {
      const index = state.pc.findIndex((item) => item.p.id === action.payload.p);
      if (index !== -1) {
        state.count -= state.pc[index].c;
        state.pc[index].c = action.payload.c;
        state.count += state.pc[index].c;
      }
    },
    removeCartItem: (state, action: PayloadAction<string | null>) => {
      const index = state.pc.findIndex((item) => item.p.id.toString() === action.payload);
      if (index !== -1) {
        state.count -= state.pc[index].c;
        state.pc.splice(index, 1);
      }
    },
    clearCart: (state) => {
      state.pc = [];
      state.count = 0;
    },
  },
});

export const {
  setSellerInCart,
  addCartItem,
  addCartItemWithCount,
  changeCartItemCount,
  setCartItemCount,
  removeCartItem,
  clearCart,
} = CartSlice.actions;

export default CartSlice.reducer;
