import React from 'react';

const BasicVerificationIcon: React.FC = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 8.44141H17M15 12.4414H17M7 16.4414H17M3 7.44141C3 6.64576 3.31607 5.8827 3.87868 5.32009C4.44129 4.75748 5.20435 4.44141 6 4.44141H18C18.7956 4.44141 19.5587 4.75748 20.1213 5.32009C20.6839 5.8827 21 6.64576 21 7.44141V17.4414C21 18.2371 20.6839 19.0001 20.1213 19.5627C19.5587 20.1253 18.7956 20.4414 18 20.4414H6C5.20435 20.4414 4.44129 20.1253 3.87868 19.5627C3.31607 19.0001 3 18.2371 3 17.4414V7.44141ZM7 10.4414C7 10.9718 7.21071 11.4805 7.58579 11.8556C7.96086 12.2307 8.46957 12.4414 9 12.4414C9.53043 12.4414 10.0391 12.2307 10.4142 11.8556C10.7893 11.4805 11 10.9718 11 10.4414C11 9.91097 10.7893 9.40227 10.4142 9.02719C10.0391 8.65212 9.53043 8.44141 9 8.44141C8.46957 8.44141 7.96086 8.65212 7.58579 9.02719C7.21071 9.40227 7 9.91097 7 10.4414Z"
      stroke="#B8C6CC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BasicVerificationIcon;
