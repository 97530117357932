import React from 'react';

import BasicVerificationIcon from '../../../../general/assets/svg/BasicVerificationIcon';
import FullVerificationIcon from '../../../../general/assets/svg/FullVerificationIcon';
import './WithdrawModalContent.scss';

const WithdrawModalContent = () => {
  return (
    <div className="witdraw-container">
      <span className="poppins-medium sub-header">verify your profile to withdraw rewards</span>
      <div className="basic-verification">
        <BasicVerificationIcon />
        <div className="text">
          <span className="poppins-regular">Basic verification</span>
          <p className="poppins-regular">
            We verify your identification document (ID card, passport or driver’s license).
            Withdrawals are limited to $400 000.
          </p>
        </div>
      </div>
      <button className="section-btn-gradient">Verify my profile</button>
      <div className="full-verification">
        <FullVerificationIcon />
        <div className="text">
          <span className="poppins-regular">Full verification</span>
          <p className="poppins-regular">
            We verify your identification document + proof of address. Withdrawals are unlimited
          </p>
        </div>
      </div>
      <button className="full-verification-btn">Go through the basic verification first</button>
    </div>
  );
};

export default WithdrawModalContent;
