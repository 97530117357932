import React from 'react';

const LikeIcon: React.FC = () => (
  <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.543 13.334V26.6673"
      stroke="#25A8CF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.0248 26.667H11.5443H6.21094V13.3336H11.5443L18.4717 5.25162C19.1291 4.48473 20.1632 4.15505 21.1432 4.40002L21.2065 4.41587C22.9956 4.86314 23.8015 6.94775 22.7787 8.48213L19.5443 13.3336H25.6248C27.3076 13.3336 28.5697 14.8731 28.2397 16.5233L26.6397 24.5233C26.3904 25.7698 25.296 26.667 24.0248 26.667Z"
      stroke="#25A8CF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LikeIcon;
