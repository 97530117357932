import React from 'react';
import './BalanceIndicator.scss';

interface BalanceIndicatorProps {
  currentBalance: number; // Текущий баланс пользователя
  dailyElectricityCost: number; // Ежедневные расходы
  lastDepositDate: string; // Дата последнего депозита
}

const BalanceIndicator: React.FC<BalanceIndicatorProps> = ({
  currentBalance,
  dailyElectricityCost,
  lastDepositDate,
}) => {
  // Рассчитываем, сколько дней покрывает баланс
  const days = Math.floor(currentBalance / dailyElectricityCost);

  // Рассчитываем количество дней в расчетном месяце
  const depositDate = new Date(lastDepositDate);
  const maxDays = new Date(depositDate.getFullYear(), depositDate.getMonth() + 1, 0).getDate();

  // Рассчитываем процент заполнения прогресс-бара
  const percentage = Math.min((days / maxDays) * 100, 100);

  return (
    <div className="balance-indicator-container">
      <span className="poppins-medium">{days} days</span>
      <div className="progress-bar">
        <div className="progress-bar-fill" style={{ width: `${percentage}%` }}></div>
      </div>
    </div>
  );
};

export default BalanceIndicator;
