import { useEffect, useRef, useState } from 'react';

const useScrollToBottom = (stickyClass: string, topOffset = 160, bottomOffset = 160) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isFixed, setIsFixed] = useState(false);
  const [isBottom, setIsBottom] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const container = containerRef.current;
        const sticky = container.querySelector(stickyClass) as HTMLDivElement;

        if (!sticky) return;

        const containerTop = container.getBoundingClientRect().top + window.scrollY;
        const containerBottom = container.getBoundingClientRect().bottom + window.scrollY;
        const stickyHeight = sticky.clientHeight;
        const containerHeight = container.clientHeight;
        if (window.scrollY === 0) {
          setIsFixed(false);
          setIsBottom(false);
          return;
        }

        if (containerHeight > stickyHeight) {
          if (
            window.scrollY >= containerTop - topOffset &&
            window.scrollY + stickyHeight + bottomOffset <= containerBottom
          ) {
            setIsFixed(true);
            setIsBottom(false);
          } else if (window.scrollY + stickyHeight + bottomOffset > containerBottom) {
            setIsFixed(false);
            setIsBottom(true);
          } else {
            setIsFixed(false);
            setIsBottom(false);
          }
        } else {
          setIsFixed(false);
          setIsBottom(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [stickyClass, topOffset, bottomOffset]);

  return { containerRef, isFixed, isBottom, setIsBottom, setIsFixed };
};

export default useScrollToBottom;
