import './ModalWindow.scss';

import React, { ReactNode, useEffect } from 'react';

import CloseModalCrossIcon from '../../../../general/assets/svg/CloseModalCrossIcon';
import useKey, { keys } from '../../../../general/hooks/useKey';
import { useResize } from '../../../../general/hooks/useResize';

interface ModalWindowProps {
  children: ReactNode;
  title: string;
  isActive: ((isOpen: boolean) => void) | null;
}

const ModalWindow: React.FC<ModalWindowProps> = ({ children, title, isActive }) => {
  const resize = useResize();
  const closeModal = () => {
    isActive && isActive(false);
    setHeader();
  };

  useKey((event) => {
    if (event.key === keys.ESCAPE) {
      closeModal();
    }
  });

  const setHeader = () => {
    const header = document.querySelector('.header-container');

    if (header) {
      header.classList.remove('header-hidden');
    }
  };

  useEffect(() => {
    const header = document.querySelector('.header-container');

    if (header && resize.width <= 500) {
      header.classList.add('header-hidden');
    }
  }, [resize.width]);

  return (
    <>
      <div className="overlay" onClick={closeModal}></div>
      <div className="modal-window ">
        <div className="modal-window-section">
          <div className="modal-window-close" onClick={closeModal}>
            <CloseModalCrossIcon />
          </div>
          <div className="modal-window-section__top">
            <div className="modal-window-section__top-title">{title}</div>
          </div>
          <div className="modal-window-section__bottom"></div>
          {children}
        </div>
      </div>
    </>
  );
};

export default ModalWindow;
