import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';

import EnergyConsumption from '../../../general/assets/svg/EnergyConsumption';
import EnergyEfficiency from '../../../general/assets/svg/EnergyEfficiency';
import HashRateIcon from '../../../general/assets/svg/HashRateIcon';
import LocationMarkerIcon from '../../../general/assets/svg/LocationMarkerIcon';
import RoundArrow45 from '../../../general/assets/svg/RoundArrow45';
import useCustomQuery from '../../../general/hooks/useCustomQuerry';
import useDynamicHeight from '../../../general/hooks/useDinamicHeight';
import { useResize } from '../../../general/hooks/useResize';
import { queryKeys } from '../../../general/queryKeys';
import { setIdOpened } from '../../../general/redux/reducers/HostingLocations-Reducer';
import { RootState } from '../../../general/redux/store';
import { clientInstance } from '../../../general/services/main/axiosInstances';
import createShippingService from '../../../general/services/shipping';
import { HostingLocationStates, IHostingLocation } from '../../../general/types/types';
import LocationImage from './LocationImage';

interface HostingLocationProps {
  hosting: IHostingLocation;
}

const HostingLocation: React.FC<HostingLocationProps> = ({ hosting }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const idOpenOne = useSelector((state: RootState) => state.hosting.idInfoOpened);
  const resize = useResize();

  const { data: states } = useCustomQuery<HostingLocationStates>(
    queryKeys.HOSTING_LOCATION_STATES,
    () => createShippingService(clientInstance).getHostingLocationStates(),
  );

  // Хук для анимации высоты блока `other-characteristics`
  const { ref: otherRef, style: otherStyle, setIsOpen: setOtherIsOpen } = useDynamicHeight();

  // Устанавливаем состояние для `other-characteristics`
  useEffect(() => {
    setOtherIsOpen(idOpenOne === hosting.id);
  }, [idOpenOne, setOtherIsOpen, hosting.id]);

  const width = resize.width <= 768 ? 450 : resize.width <= 1024 ? 522 : 622;
  const height =
    resize.width <= 500 ? 220 : resize.width <= 768 ? 230 : resize.width <= 1024 ? 264 : 354;
  const cutoutWidth = resize.width <= 768 ? 110 : resize.width <= 1024 ? 125 : 130;
  const cutoutHeight = resize.width <= 768 ? 105 : resize.width <= 1024 ? 120 : 130;
  const radius = 60;

  const getOpenClass = (className: string) => {
    if (resize.width <= 700) {
      return idOpenOne === hosting.id ? className : '';
    } else {
      return isOpen ? className : '';
    }
  };

  return (
    <>
      {resize.width > 500 ? (
        <LocationImage
          src={hosting.images[0].imageUrl}
          width={width}
          height={height}
          cutoutWidth={cutoutWidth}
          cutoutHeight={cutoutHeight}
          radius={radius}
        />
      ) : (
        <>
          <div
            className="phone-hosting-location-image"
            style={{
              backgroundImage: `url(${hosting.images[0].imageUrl})`,
              height: height,
            }}
          ></div>
          <span
            className={`phone-arrow ${getOpenClass('phone-arrow-opened')}`}
            onClick={() => {
              setIsOpen(!isOpen);

              if (idOpenOne === hosting.id) {
                dispatch(setIdOpened(null));
              } else {
                dispatch(setIdOpened(hosting.id));
              }
            }}
          >
            {idOpenOne === hosting.id ? 'Less' : 'Show more'}
          </span>
        </>
      )}

      <div className="text-content">
        <div className={`hosting-header ${getOpenClass('hosting-header-open')}`}>
          <div className="location">
            <LocationMarkerIcon fill="white" />
            <span className={`histing${hosting.id}`}>{hosting.locationName}</span>
            <Tooltip anchorSelect={`.histing${hosting.id}`} place="top">
              {hosting.locationName}
            </Tooltip>
          </div>
          <span
            className="state"
            style={{
              color:
                hosting.state === states?.Available
                  ? '#65E279'
                  : hosting.state === states?.ComingSoon
                    ? '#F6B760'
                    : '#F66060',
            }}
          >
            {hosting.state}
          </span>
        </div>
        <div className={`main-characteristics ${getOpenClass('main-characteristics-open')}`}>
          <div className="main-characteristic">
            <HashRateIcon stroke="#B8C6CC" />
            <span className={`hosting${hosting.id}mainCharacteristic1`}>
              {hosting.characteristics[0]?.value}
            </span>
          </div>
          <div className="main-characteristic">
            <EnergyConsumption stroke="#B8C6CC" />
            <span className={`hosting${hosting.id}mainCharacteristic2`}>
              {hosting.characteristics[1]?.value}
            </span>
          </div>
          <div className="main-characteristic">
            <EnergyEfficiency />
            <span className={`hosting${hosting.id}mainCharacteristic3`}>
              {hosting.characteristics[2]?.value}
            </span>
          </div>
        </div>
        <div
          ref={otherRef} // Добавляем ссылку на элемент для хука
          className={`other-characteristics ${getOpenClass('other-characteristics-open')}`}
          style={otherStyle} // Применяем динамический стиль высоты
        >
          {hosting.characteristics.map(
            (characteristic, index) =>
              index > 2 && (
                <div className="other-characteristic" key={index}>
                  <span className={`characteristic${characteristic.id}key`}>
                    {characteristic.key}
                  </span>
                  <Tooltip anchorSelect={`.characteristic${characteristic.id}key`} place="top">
                    {characteristic.key}
                  </Tooltip>
                  <span className="dots"></span>
                  <span className={`characteristic${characteristic.id}value`}>
                    {characteristic.value}
                  </span>
                </div>
              ),
          )}
        </div>
      </div>
      <div
        className={`arrow ${getOpenClass('arrow-opened')}`}
        onClick={() => {
          setIsOpen(!isOpen);

          if (idOpenOne === hosting.id) {
            dispatch(setIdOpened(null));
          } else {
            dispatch(setIdOpened(hosting.id));
          }
        }}
      >
        <div>
          <RoundArrow45
            stroke={
              resize.width <= 700
                ? idOpenOne === hosting.id
                  ? '#25a8cf'
                  : 'white'
                : isOpen
                  ? '#25a8cf'
                  : 'white'
            }
          />
        </div>
      </div>
    </>
  );
};

export default HostingLocation;
