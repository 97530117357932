import {
  CloseReason,
  useSnackbar as notistackUseSnackbar,
  OptionsObject,
  SnackbarKey,
  SnackbarMessage,
} from 'notistack';
import { useCallback, useState } from 'react';

export enum VariantTypes {
  Default = 'default',
  Error = 'error',
  Success = 'success',
  Warning = 'warning',
  Info = 'info',
}

export interface SnackbarOptions extends OptionsObject<VariantTypes> {
  onClose?: (
    event: React.SyntheticEvent<any> | null,
    reason: CloseReason,
    key?: SnackbarKey,
  ) => void;
}

const useSnackbar = () => {
  const [isSnackbarActive, setIsSnackbarActive] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = notistackUseSnackbar();

  const showSnackbar = useCallback(
    (message: SnackbarMessage, options: SnackbarOptions) => {
      if (!isSnackbarActive) {
        setIsSnackbarActive(true);

        enqueueSnackbar(message, {
          ...options,
          onClose: (event, reason, key) => {
            setIsSnackbarActive(false);
            if (options.onClose) {
              options.onClose(event, reason, key as SnackbarKey);
            }
          },
        });
      }
    },
    [isSnackbarActive, enqueueSnackbar],
  );

  return { showSnackbar, closeSnackbar };
};

export default useSnackbar;
