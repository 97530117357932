import { SnackbarProvider } from 'notistack';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { useMutation } from 'react-query';
import packageJson from '../package.json';
import AdminHeader from './admin/components/header/Header';
import AdminNavigation from './admin/components/navigation/Navigation';
import AdminEmails from './admin/pages/admins/admin-emails/AdminEmails';
import AdminList from './admin/pages/admins/admin-list/AdminList';
import AdminAdmins from './admin/pages/admins/Admins';
import AdminArticle from './admin/pages/articles/article/Article';
import AdminArticles from './admin/pages/articles/Articles';
import AuditLogs from './admin/pages/audit-log/AuditLog';
import AdminAuthorization from './admin/pages/authorization/Authorization';
import AdminClient from './admin/pages/clients/client/Client';
import AdminClients from './admin/pages/clients/Clients';
import AdminClientList from './admin/pages/clients/clients-list/ClientList';
import NewsletterWorkshop from './admin/pages/clients/mailing/newsletterWorkshop/NewsletterWorkshop';
import RecipientList from './admin/pages/clients/mailing/recipientList/RecipientList';
import AdminSubscriberList from './admin/pages/clients/subscriber-list/SubscriberList';
import AdminContacts from './admin/pages/contacts/Contacts';
import AdminFrequentlyAskedQuestion from './admin/pages/help-center/faq/AdminFrequentlyAskedQuestion';
import AdminHelpCenter from './admin/pages/help-center/HelpCenter';
import AdminQuestionAndAnswer from './admin/pages/help-center/q&a/AdminQuestionAndAnswer';
import AdminHome from './admin/pages/home/Home';
import AdminContactUs from './admin/pages/messages/contact-us/ContactUs';
import AdminMessages from './admin/pages/messages/Messages';
import AdminMessagesList from './admin/pages/messages/messages-list/MessagesList';
import AdminOrder from './admin/pages/orders/order/Order';
import AdminOrders from './admin/pages/orders/Orders';
import AdminProductsCategories from './admin/pages/products/product-categories/AdminProductsCategories';
import AdminProductList from './admin/pages/products/product-list/ProductList';
import AdminProduct from './admin/pages/products/product/Product';
import AdminProducts from './admin/pages/products/Products';
import AdminProfile from './admin/pages/profile/Profile';
import ProjectHasrate from './admin/pages/project-hasrate/ProjectHasrate';
import BloggersAndCompanies from './admin/pages/referralProgram/bloggersAndCompanies/BloggersAndCompanies';
import ClientTracking from './admin/pages/referralProgram/clientTracking/ClientTracking';
import ReferralCodes from './admin/pages/referralProgram/referralCodes/ReferralCodes';
import ReferralTabs from './admin/pages/referralProgram/ReferralTabs';
import ShippingInfo from './admin/pages/shippingInfo/ShippingAndLocations';
import CookieModal from './client/components/cookie-modal/CookieModal';
import AddItemToCart from './client/components/custom-snackbar/add-to-cart/AddToCart';
import Footer from './client/components/footer/Footer';
import Header from './client/components/header/Header';
import ProductTable from './client/components/product-table/ProductTable';
import DynamicMetaTag from './client/components/SEO/DynamicMetaTag';
import FFHourSetup from './client/pages/48HourSetup/48HourSetup';
import About from './client/pages/about/About';
import PostPage from './client/pages/blog-post-page/PostPage';
import Blog from './client/pages/blog/Blog';
import Cart from './client/pages/cart/Cart';
import Information from './client/pages/cart/proccesing/information/Information';
import Payment from './client/pages/cart/proccesing/payment/Payment';
import Proccesing from './client/pages/cart/proccesing/Proccesing';
import Shipping from './client/pages/cart/proccesing/shipping/Shipping';
import Categories from './client/pages/categories/Categories';
import Compare from './client/pages/compare/Compare';
import ContactsPage from './client/pages/contacts/Contacts';
import EmptyPage from './client/pages/emptyPage/EmptyPage';
import FAQ from './client/pages/help-center/faq-and-qa/FAQ';
import QA from './client/pages/help-center/faq-and-qa/QA';
import Home from './client/pages/home/Home';
import Hosting from './client/pages/hosting/Hosting';
import Pool from './client/pages/pool/Pool';
import Product from './client/pages/product/Product';
import Products from './client/pages/products/Products';
import Profile from './client/pages/profile/Profile';
import ProfileAssets from './client/pages/profile/profile-dashboard/profile-assets/ProfileAssets';
import ProfileTransactionHistory from './client/pages/profile/profile-dashboard/profile-transaction-hitory/ProfileTransactionHistory';
import ProfileDashboard from './client/pages/profile/profile-dashboard/ProfileDashboard';
import ProfileWalletSettings from './client/pages/profile/profile-dashboard/wallet-settings/ProfileWalletSettings';
import ProfileData from './client/pages/profile/profile-data/ProfileData';
import ProfileOrders from './client/pages/profile/profile-orders/ProfileOrders';
import ProfileWishlist from './client/pages/profile/profile-wishlist/ProfileWishlist';
import CookiesPolicy from './client/pages/static-agreements/cookies-police/CookiesPolicy';
import PublicOffer from './client/pages/static-agreements/public-offer/PublicOffer';
import UserAgreement from './client/pages/static-agreements/user-agreement/UserAgreement';
import Unsubscribe from './client/pages/usubscribe/Unsubscribe';
import ThemeProvider from './client/providers/ThemeProvider';
import { ThemeContext, themes } from './general/contexts/ThemeContext';
import CustomErorr from './general/error/CustomErorr';
import useCustomMutation from './general/hooks/useCustomMutation';
import useCustomQuery from './general/hooks/useCustomQuerry';
import useScrollToBottom from './general/hooks/useScrollToBottom';
import { queryKeys } from './general/queryKeys';
import { clientLogout } from './general/redux/reducers/ClientProfile-Reducer';
import { setIsNestedHeader } from './general/redux/reducers/ClinetPanel-Reducer';
import { RootState } from './general/redux/store';
import { routesAdmin, routesClient } from './general/routes';
import createClientService from './general/services/client';
import { createCloudFlareService } from './general/services/cloudFlare';
import { clientInstance } from './general/services/main/axiosInstances';
import createSitemapService from './general/services/sitemap/sitemap';
import { User } from './general/types/types';

const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const saveRefCode = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const referralCode = urlParams.get('referralCode');

  if (referralCode) {
    const currentTime = new Date().getTime();

    localStorage.setItem('referralCode', referralCode);
    localStorage.setItem('referralTimestamp', currentTime.toString());
  }
};

const checkRefCodeExpiration = () => {
  const referralCode = localStorage.getItem('referralCode');
  const referralTimestamp = localStorage.getItem('referralTimestamp');

  if (referralCode && referralTimestamp) {
    const currentTime = new Date().getTime();
    const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000;

    if (currentTime - parseInt(referralTimestamp) > oneWeekInMilliseconds) {
      localStorage.removeItem('referralCode');
      localStorage.removeItem('referralTimestamp');
    }
  }
};

// AppClient
const Client: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isNestedHeader = useSelector((state: RootState) => state.clientPanel.isNestedHeader);

  const isCookiesAccept = useSelector((state: RootState) => state.acp.acp);
  const isUserLogin = useSelector((state: RootState) => state.clps.cp2);
  const userRef = useSelector((state: RootState) => state.clps.userRefCode);
  const themeContext = useContext(ThemeContext);
  const { containerRef, isFixed } = useScrollToBottom('.sticky-header');

  const { mutate: clear } = useCustomMutation(
    createCloudFlareService(clientInstance).clearClientCache,
  );

  if (!isUserLogin) {
    checkRefCodeExpiration();
    saveRefCode();
  }

  const { data: currentUser } = useCustomQuery<User>(
    queryKeys.USER_INFO,
    () => createClientService(clientInstance).getClientInfo(isUserLogin),
    undefined,
    undefined,
    {
      enabled: !!isUserLogin,
    },
  );

  useEffect(() => {
    // Проверяем наличие вложенного заголовка на странице
    const nestedHeader = document.querySelector('.header.inline');
    if (nestedHeader) {
      dispatch(setIsNestedHeader(true));
    } else {
      dispatch(setIsNestedHeader(false));
    }
  });

  useEffect(() => {
    clear(undefined);
  }, [clear]);

  useEffect(() => {
    if (themeContext) {
      themeContext.setTheme(themes.dark);
    }
  }, [themeContext]);

  useEffect(() => {
    const headerElement = document.querySelector('.header-container') as HTMLDivElement;
    const headerPlaseHolder = document.querySelector('.header-placeholder') as HTMLDivElement;

    if (headerElement) {
      if (isFixed) {
        headerPlaseHolder.style.height = `${headerElement.clientHeight}px`;
        headerElement.classList.add('fixed');
      } else {
        headerPlaseHolder.style.height = `0px`;
        headerElement.classList.remove('fixed');
      }
    }
  }, [isFixed]);

  useEffect(() => {
    const isUserDetailsIncomplete =
      !currentUser?.name || !currentUser?.surname || !currentUser?.phoneNumber;

    if (
      currentUser &&
      userRef &&
      isUserLogin &&
      isUserDetailsIncomplete &&
      !location.pathname.includes('profile')
    ) {
      navigate(routesClient.profile.path);
    }
  });

  useEffect(() => {
    if (!isUserLogin && location.pathname.includes('profile')) {
      navigate(routesClient.home.path);
      return;
    }

    //#region routes case redirections
    if (location.pathname === routesClient.product.path) {
      navigate(routesClient.home.path);
    }

    if (
      location.pathname === routesClient.productRetailCategoryTable.path ||
      location.pathname === routesClient.retail.path ||
      location.pathname === routesClient.products.path
    ) {
      navigate(routesClient.productsRetail.path);
    }

    if (
      location.pathname === routesClient.productWholesaleCategoryTable.path ||
      location.pathname === routesClient.wholesale.path
    ) {
      navigate(routesClient.productsWholesale.path);
    }

    if (location.pathname === routesClient.helpCenter.path) {
      navigate(routesClient.helpCenterFAQ.path);
    }
    //#endregion
  }, [navigate, isUserLogin, location]);

  return (
    <ThemeContext.Consumer>
      {(context) => {
        if (!context) {
          throw new Error('App must be used within a ThemeProvider');
        }
        const { theme, setTheme } = context;
        return (
          <SnackbarProvider
            autoHideDuration={5000}
            Components={{
              success: AddItemToCart,
            }}
            maxSnack={1}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            classes={{
              root: 'modal-position centered-snackbar',
            }}
            dense
          >
            <DynamicMetaTag />
            <div ref={containerRef} className="general-container">
              {isCookiesAccept === 'No' && <CookieModal />}
              {!isNestedHeader && <Header theme={theme} setTheme={setTheme} />}
              <CustomErorr root={'/'} />
              <Routes>
                <Route
                  path={routesClient.home.path}
                  element={
                    <Home
                      title={routesClient.home.metaTitle as string}
                      description={routesClient.home.metaDescription as string}
                    />
                  }
                />
                <Route path={routesClient.unsubscribe.path} element={<Unsubscribe />} />
                <Route path={routesClient.aboutUs.path} element={<About />} />
                <Route
                  path={routesClient.productsRetail.path}
                  element={<Categories isRetail={true} />}
                />

                <Route
                  path={routesClient.productsWholesale.path}
                  element={<Categories isRetail={false} />}
                />
                <Route
                  path={routesClient.productRetailCategory.path}
                  element={<Products isRetail={true} />}
                />
                <Route
                  path={routesClient.productWholesaleCategory.path}
                  element={<Products isRetail={false} />}
                />
                <Route
                  path={routesClient.productRetailCategoryTable.path}
                  element={<ProductTable isRetail={true} />}
                />
                <Route
                  path={routesClient.productWholesaleCategoryTable.path}
                  element={<ProductTable isRetail={false} />}
                />
                <Route path={routesClient.productDetails.path} element={<Product />} />
                <Route path={routesClient.pool.path} element={<Pool />} />
                <Route path={routesClient.hosting.path} element={<Hosting />} />
                <Route path={routesClient.blog.path} element={<Blog />} />
                <Route path={routesClient.blogPost.path} Component={PostPage} />
                <Route path={routesClient.helpCenterQA.path} element={<QA />} />
                <Route path={routesClient.helpCenterFAQ.path} element={<FAQ />} />
                <Route path={routesClient.contacts.path} element={<ContactsPage />} />
                <Route path={routesClient.cart.path} element={<Cart />} />
                <Route path={routesClient.cartInformation.path} element={<Proccesing />}>
                  <Route index element={<Information />} />
                  <Route path={routesClient.cartShipping.path} element={<Shipping />} />
                  <Route path={routesClient.cartPayment.path} element={<Payment />} />
                </Route>
                <Route path={routesClient.profile.path} element={<Profile />}>
                  <Route index element={<ProfileData />} />
                  <Route path={routesClient.profileWishlist.path} element={<ProfileWishlist />} />
                  <Route path={routesClient.profileOrders.path} element={<ProfileOrders />} />
                  <Route path={routesClient.profileDashboard.path} element={<ProfileDashboard />} />
                  <Route
                    path={routesClient.profileDashboardAssets.path}
                    element={<ProfileAssets />}
                  />
                  <Route
                    path={routesClient.profileDashboardTransactionHistory.path}
                    element={<ProfileTransactionHistory />}
                  />
                  <Route
                    path={routesClient.profileDashboardViratualWalletSetting.path}
                    element={<ProfileWalletSettings />}
                  />
                </Route>
                <Route path={routesClient.compare.path} element={<Compare />} />
                <Route path={routesClient.cookiesPolicy.path} element={<CookiesPolicy />} />
                <Route path={routesClient.publicOffer.path} element={<PublicOffer />} />
                <Route path={routesClient.userAgreement.path} element={<UserAgreement />} />

                <Route path={routesClient.emptyPage.path} element={<EmptyPage />} />
                <Route path={routesClient.notFound.path} element={<EmptyPage />} />

                <Route path={routesClient.projectHashrate.path} element={<FFHourSetup />} />
              </Routes>
              <Footer />
            </div>
          </SnackbarProvider>
        );
      }}
    </ThemeContext.Consumer>
  );
};

// AppAdminpanel
const Admin: React.FC = () => {
  const location = useLocation();
  const admin_access_data = useSelector((state: RootState) => state.a4.a4d);

  // Хук для изменения стиля body при переходе на /admin
  useEffect(() => {
    if (location.pathname.startsWith('/admin')) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    // Очистка стиля при размонтировании компонента
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [location.pathname]);

  if (admin_access_data === '') {
    return (
      <>
        <DynamicMetaTag isIndexing={false} />
        <div className="admin-web">
          <CustomErorr root={'/admin'} />
          <Routes>
            <Route path={routesAdmin.authorization.path} element={<AdminAuthorization />} />
          </Routes>
        </div>
      </>
    );
  }

  return (
    <>
      <DynamicMetaTag isIndexing={false} />
      <div className="admin-web">
        <div className="main-content">
          <AdminHeader />
          <AdminNavigation />
          <CustomErorr root={'/admin'} />
          <Routes>
            <Route path={routesAdmin.home.path} element={<AdminHome />} />

            <Route path={routesAdmin.profile.path} element={<AdminProfile />} />

            <Route path={routesAdmin.orders.path} element={<AdminOrders />} />
            <Route path={routesAdmin.order.path} element={<AdminOrder />} />

            <Route path={routesAdmin.products.path} element={<AdminProducts />}>
              <Route index element={<AdminProductList />} />
              <Route
                path={routesAdmin.productCategories.path}
                element={<AdminProductsCategories />}
              />
            </Route>

            <Route path={routesAdmin.product.path} element={<AdminProduct isEdit={false} />} />
            <Route path={routesAdmin.productEdit.path} element={<AdminProduct isEdit={true} />} />

            <Route path={routesAdmin.articles.path} element={<AdminArticles />} />
            <Route path={routesAdmin.article.path} element={<AdminArticle isEdit={false} />} />
            <Route path={routesAdmin.articleEdit.path} element={<AdminArticle isEdit={true} />} />

            <Route path={routesAdmin.clients.path} element={<AdminClients />}>
              <Route index element={<AdminClientList />} />
              <Route path={routesAdmin.subscribers.path} element={<AdminSubscriberList />} />
              <Route path={routesAdmin.newsletterWorkshop.path} element={<NewsletterWorkshop />} />
              <Route path={routesAdmin.recipientList.path} element={<RecipientList />} />
            </Route>
            <Route path={routesAdmin.client.path} element={<AdminClient />} />

            <Route path={routesAdmin.admins.path} element={<AdminAdmins />}>
              <Route index element={<AdminList />} />
              <Route path={routesAdmin.adminEmails.path} element={<AdminEmails />} />
            </Route>

            <Route path={routesAdmin.messages.path} element={<AdminMessages />}>
              <Route index element={<AdminMessagesList />} />
              <Route path={routesAdmin.contactUs.path} element={<AdminContactUs />} />
            </Route>

            <Route path={routesAdmin.faq.path} element={<AdminHelpCenter />}>
              <Route index element={<AdminFrequentlyAskedQuestion />} />
              <Route path={routesAdmin.qa.path} element={<AdminQuestionAndAnswer />} />
            </Route>

            <Route path={routesAdmin.bloggersAndCompanies.path} element={<ReferralTabs />}>
              <Route index element={<BloggersAndCompanies />} />
              <Route path={routesAdmin.referralCodes.path} element={<ReferralCodes />} />
              <Route path={routesAdmin.clientTracking.path} element={<ClientTracking />} />
            </Route>

            <Route path={routesAdmin.contacts.path} element={<AdminContacts />} />
            <Route path={routesAdmin.auditLogs.path} element={<AuditLogs />} />
            <Route path={routesAdmin.shippingInfo.path} element={<ShippingInfo />} />

            <Route path={routesAdmin.notFound.path} element={<AdminHome />} />

            <Route path={routesAdmin.projectHashrate.path} element={<ProjectHasrate />} />
          </Routes>
        </div>
      </div>
    </>
  );
};

const SitemapUpdater = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { mutate: saveRoutes } = useMutation(createSitemapService(clientInstance).saveRoutes);

  useEffect(() => {
    const routesToSave = Object.values(routesClient)
      .filter(({ indexing }) => indexing)
      .map(({ path, changefreq, priority }) => ({
        path,
        changefreq: changefreq || 'daily',
        priority: priority || 0.5,
      }));

    saveRoutes(routesToSave);
  }, [saveRoutes]);

  useEffect(() => {
    const hasEffectRun = localStorage.getItem('hasEffectRun');

    if (!hasEffectRun) {
      dispatch(clientLogout());
      navigate(routesClient.home.path);

      const version = packageJson.version;
      document.querySelector('meta[name="version"]')?.setAttribute('content', version);

      localStorage.setItem('hasEffectRun', 'true');
    }
  }, [dispatch, navigate]);

  return null;
};

const App: React.FC = () => {
  return (
    <>
      <SitemapUpdater />
      <ThemeProvider>
        <ScrollToTop />
        <Routes>
          <Route path="/*" element={<Client />} />
          <Route path="/admin/*" element={<Admin />} />
        </Routes>
      </ThemeProvider>
    </>
  );
};

export default App;
