import React, { ChangeEvent, DragEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setInfo } from '../../../../../general/redux/reducers/Error-Reducer';
import Confirm from '../../../../components/confirm/Confirm';

interface EditImage {
  url: string;
  id: string;
}

interface UploadImagesProps {
  filesArray: (File | EditImage)[];
  dragedImgs: { goal: string | null; targetIndex: number }[];
  editImages: { url: string; id: string }[];
  removeArray: string[];
}

const UploadImages: React.FC<UploadImagesProps> = ({
  filesArray,
  dragedImgs,
  editImages,
  removeArray,
}) => {
  const dispatch = useDispatch();
  const [imageSlots, setImageSlots] = useState<Array<File | { id: string; url: string } | null>>(
    Array(10).fill(null),
  );
  const [deleteImgAsk, setDeleteImgAsk] = useState<boolean>(false);
  const [deleteImgId, setDeleteImgId] = useState<string | null>(null);

  const disableInput = {
    display: 'none',
  };

  useEffect(() => {
    if (editImages.length > 0) {
      setImageSlots((prevImageSlots) => {
        const updatedSlots = [...prevImageSlots];
        let slotIndex = updatedSlots.findIndex((slot) => slot === null);

        editImages.forEach((file) => {
          // Проверяем, есть ли изображение уже в filesArray или slots
          const isAlreadyAdded = filesArray.some((f) => 'id' in f && f.id === file.id);

          if (!isAlreadyAdded) {
            const fileWithId = { id: file.id, url: file.url };
            filesArray.push(fileWithId);

            if (slotIndex !== -1) {
              updatedSlots[slotIndex] = fileWithId;
              slotIndex = updatedSlots.findIndex((slot) => slot === null);
            }
          }
        });

        return updatedSlots;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadImages = (data: FileList | File[]) => {
    const errors: { f: string; s: string }[] = [];
    const files = Array.from(data);

    files.forEach((file) => {
      const sizeInMB = file.size / 1024;
      if (sizeInMB > 200) {
        errors.push({ f: file.name, s: sizeInMB.toFixed(2) });
      }
    });

    if (errors.length > 0) {
      dispatch(
        setInfo({
          n: 'Uploading images',
          r: {
            d: '',
            s: `Some images weigh more than 200 kB.\n\n${errors
              .map((error) => `${error.f} - ${error.s} kB`)
              .join('\n')}`,
          },
        }),
      );
    }

    setImageSlots((prevImageSlots) => {
      const updatedSlots = [...prevImageSlots];
      let slotIndex = updatedSlots.findIndex((slot) => slot === null);

      files.forEach((file) => {
        if (slotIndex === -1) return;

        const sizeInMB = file.size / 1024;
        if (sizeInMB < 200) {
          if (!filesArray.includes(file)) {
            filesArray.push(file);
          }
          updatedSlots[slotIndex] = file; // Add file directly to slots
          slotIndex = updatedSlots.findIndex((slot) => slot === null);
        }
      });

      return updatedSlots;
    });
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      loadImages(event.target.files);
    }
  };

  const handleDropImages = (event: DragEvent<HTMLLabelElement>) => {
    if (event.dataTransfer.files) {
      loadImages(event.dataTransfer.files);
    }
  };

  const deleteImg = () => {
    if (!deleteImgId) return;

    setImageSlots((prevImageSlots) => {
      const updatedSlots = [...prevImageSlots];
      const targetIndex = updatedSlots.findIndex(
        (slot) => slot && ('id' in slot ? slot.id === deleteImgId : filesArray.includes(slot)),
      );

      if (targetIndex !== -1) {
        const fileToDelete = updatedSlots[targetIndex];
        if (fileToDelete) {
          if ('id' in fileToDelete) {
            removeArray.push(fileToDelete.id); // Preloaded image
          } else {
            filesArray.splice(filesArray.indexOf(fileToDelete), 1); // File object
          }
          updatedSlots[targetIndex] = null;
        }
      }

      return [
        ...updatedSlots.filter((slot) => slot !== null),
        ...updatedSlots.filter((slot) => slot === null),
      ];
    });

    setDeleteImgId(null);
  };

  const isEditImage = (
    slot: File | { id: string; url: string } | null,
  ): slot is { id: string; url: string } => {
    return slot !== null && 'id' in slot;
  };

  const handleDrop = (e: DragEvent<HTMLLIElement>, targetId: string) => {
    e.preventDefault();
    const draggedId = e.dataTransfer.getData('draggedImageId');

    if (draggedId === targetId) return;

    setImageSlots((prevImageSlots) => {
      const newSlots = [...prevImageSlots];
      const draggedIndex = newSlots.findIndex((slot) => isEditImage(slot) && slot.id === draggedId);
      const targetIndex = newSlots.findIndex((slot) => isEditImage(slot) && slot.id === targetId);

      if (draggedIndex !== -1 && targetIndex !== -1) {
        [newSlots[draggedIndex], newSlots[targetIndex]] = [
          newSlots[targetIndex],
          newSlots[draggedIndex],
        ];

        // Add a null check before accessing properties
        const targetSlot = newSlots[targetIndex];
        dragedImgs.push({
          goal: isEditImage(targetSlot) ? (targetSlot as { id: string; url: string }).url : null,
          targetIndex,
        });
      }
      return newSlots;
    });
  };

  return (
    <>
      <ul className="product-content-section__side-bar__image-loaders">
        {imageSlots.map((slot, index) => (
          <li
            key={index}
            onClick={() => {
              if (isEditImage(slot)) {
                setDeleteImgId(slot.id);
                setDeleteImgAsk(true);
              } else if (slot instanceof File) {
                setDeleteImgId(index.toString()); // Handle File by index
                setDeleteImgAsk(true);
              }
            }}
            className="image-loaders"
            draggable={slot !== null}
            onDragStart={(e) =>
              isEditImage(slot) && e.dataTransfer.setData('draggedImageId', slot.id)
            }
            onDrop={(e) => isEditImage(slot) && handleDrop(e, slot.id)}
            onDragOver={(e) => e.preventDefault()}
          >
            {slot ? (
              <div
                className="img"
                style={{
                  backgroundImage: `url(${
                    isEditImage(slot) ? slot.url : URL.createObjectURL(slot as File)
                  })`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                }}
              />
            ) : (
              <label
                htmlFor={`upload-photo-${index}`}
                onDrop={handleDropImages}
                onDragOver={(e) => e.preventDefault()}
                className="img-loader"
              >
                +
              </label>
            )}
            <input
              id={`upload-photo-${index}`}
              type="file"
              multiple
              onChange={handleImageChange}
              style={slot ? disableInput : undefined}
            />
          </li>
        ))}
      </ul>
      {deleteImgAsk && (
        <Confirm
          title="Delete confirm"
          text={'Are you shure want delete this image?'}
          handler={setDeleteImgAsk}
          clickEvent={() => {
            deleteImg();
          }}
        />
      )}
    </>
  );
};

export default UploadImages;
