import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { statusColors } from '../../../../../admin/pages/orders/order/f2PoolConfig/F2PoolConfigModal';
import ChipIcon from '../../../../../general/assets/svg/ChipIcon';
import ExportIconV2 from '../../../../../general/assets/svg/ExportIconV2';
import ScrewIcon from '../../../../../general/assets/svg/ScrewIcon';
import Preloader from '../../../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../../../general/hooks/useCustomQuerry';
import usePagination from '../../../../../general/hooks/usePagination';
import { queryKeys } from '../../../../../general/queryKeys';
import { RootState } from '../../../../../general/redux/store';
import createCryptosService from '../../../../../general/services/cryptos';
import createF2PoolService from '../../../../../general/services/f2pool/f2Pool';
import { AssetF2Pool } from '../../../../../general/services/f2pool/interfaces';
import { clientInstance } from '../../../../../general/services/main/axiosInstances';
import { coinMap, CryptoData, PaggingObj } from '../../../../../general/types/types';
import getFile from '../../../../../general/utils/GetFile';
import getMinerFieldValue, {
  calculateDailyIncomeUSD,
} from '../../../../../general/utils/getFormatedValues';
import { formatToTwoPlaces } from '../../../../../general/utils/Validations';
import Pagination from '../../../../components/pagination/Pagination';
import './ProfileAssets.scss';

const ProfileAssets: React.FC = () => {
  const userId = useSelector((state: RootState) => state.clps.cp2);
  const { page, pageSize, setPage } = usePagination(4);

  const {
    data: assets,
    isFetching: assetsLoading,
    refetch: getAssets,
  } = useCustomQuery<PaggingObj<AssetF2Pool>>(queryKeys.DASHBOARD_ASSETS, () =>
    createF2PoolService(clientInstance).getAssets(userId, pageSize, page),
  );

  useEffect(() => {
    getAssets();
  }, [getAssets, page]);

  const { data: cryptosList } = useCustomQuery<CryptoData[]>(queryKeys.CRYPTOS_LIST_OBJ, () =>
    createCryptosService(clientInstance).getCryptosList(),
  );

  const { refetch: getXLSX } = useCustomQuery<ArrayBuffer>(
    queryKeys.DASHBOARD_ASSETS_XLSX,
    () => createF2PoolService(clientInstance).getAssetsXLSX(userId),
    undefined,
    undefined,
    {
      enabled: false,
      onSuccess(data: ArrayBuffer | undefined) {
        getFile(data as ArrayBuffer, 'Pool Assets');
      },
    },
  );

  return (
    <div className="assets-container">
      <div className="assets-header">
        <div>
          <ScrewIcon />
          <h2 className="poppins-medium">Assets</h2>
        </div>
        <button
          className="download-btn"
          onClick={async () => {
            getXLSX();
          }}
        >
          <ExportIconV2 stroke="#919191" />
        </button>
      </div>
      <div className="assets-table">
        <div className="assets-row header">
          <div className="column poppins-medium">Type and name</div>
          <div className="column poppins-medium">Hash rate & quantity</div>
          <div className="column poppins-medium">Power consumption</div>
          <div className="column poppins-medium">Daily income</div>
          <div className="column poppins-medium">Statuses</div>
        </div>
        {assetsLoading ? (
          <Preloader />
        ) : (
          <>
            {assets && assets.totalCount > 0 ? (
              assets?.items?.map((asset, index) => (
                <div className="assets-row info" key={index}>
                  <div className="column">
                    <ChipIcon stroke="#746A81" />
                    {getMinerFieldValue(asset.miner, 'name')}
                  </div>
                  <div className="column numbers poppins-medium">
                    <div className="title poppins-medium">Hash rate:</div>
                    {getMinerFieldValue(asset.miner, 'hashrate')}
                    <br />
                    <div className="title poppins-medium">Quantity:</div>
                    {asset.quantity} miners
                  </div>
                  <div className="column poppins-medium">
                    <div className="title poppins-medium">Power consumption:</div>
                    {getMinerFieldValue(asset.miner, 'powerConsumption')} kW
                    <br />
                    {formatToTwoPlaces(
                      getMinerFieldValue(asset.miner, 'energyEfficiency') * 1000,
                    )}{' '}
                    W/TH
                  </div>
                  <div className="column numbers poppins-medium">
                    <div className="title poppins-medium">Daily income:</div>$
                    {getMinerFieldValue(asset.miner, 'estDailyOutput')}
                    <br /> ≈{' '}
                    {formatToTwoPlaces(
                      calculateDailyIncomeUSD(asset.miner, coinMap, cryptosList as CryptoData[]),
                    )}
                  </div>
                  <div className="column poppins-medium">
                    <div className="title poppins-medium">Statuses:</div>
                    <div>
                      <div className="status account-status">
                        <span>Account status:</span>
                        <div
                          className={`marker ${
                            statusColors[asset.accountStatus.toLowerCase().replace(/\s+/g, '')] ||
                            ''
                          }`}
                        />
                      </div>
                      <div className="status financialStatus-status">
                        <span>Financial status:</span>
                        <div
                          className={`marker ${
                            statusColors[asset.financialStatus.toLowerCase().replace(/\s+/g, '')] ||
                            ''
                          }`}
                        />
                      </div>
                      <div className="status working-condition">
                        <span>Working condition:</span>
                        <div
                          className={`marker ${
                            statusColors[
                              asset.workingCondition.toLowerCase().replace(/\s+/g, '')
                            ] || ''
                          }`}
                        />
                      </div>
                      <div className="status physical-condition">
                        <span>Physical condition:</span>
                        <div
                          className={`marker ${
                            statusColors[
                              asset.physicalCondition.toLowerCase().replace(/\s+/g, '')
                            ] || ''
                          }`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="no-data">
                <span className="poppins-semibold">No data</span>
              </div>
            )}
          </>
        )}
      </div>
      {assets && assets.pageSize < assets.totalCount && (
        <Pagination
          page={page}
          setPage={setPage}
          isNextPage={assets.hasNextPage}
          isPrevPage={assets.hasPreviousPage}
          pageSize={assets.pageSize}
          total={assets.totalCount}
        />
      )}
    </div>
  );
};

export default ProfileAssets;
