import React, { ChangeEvent, FC, MouseEventHandler } from 'react';
import { useDispatch } from 'react-redux';
import {
  changeCartItemCount,
  setCartItemCount,
} from '../../../general/redux/reducers/ClientCart-Reducer';

const exceptThisSymbols = ['e', 'E', '+', '-', '.', '0'];

interface CounterHook {
  count: number;
  increment: MouseEventHandler<HTMLButtonElement>;
  decrement: MouseEventHandler<HTMLButtonElement>;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  setCount: (value: number) => void;
}

interface CounterProps {
  productId: string;
  counterHook: CounterHook;
  syncWithCart?: boolean;
}

const Counter: FC<CounterProps> = ({ productId, counterHook, syncWithCart = true }) => {
  const dispatch = useDispatch();
  const { count, increment, decrement, handleChange, setCount } = counterHook;

  const setDefaultCount = () => setCount(1);

  const handleIncrement: MouseEventHandler<HTMLButtonElement> = (e) => {
    increment(e);
    if (syncWithCart) dispatch(changeCartItemCount({ p: productId, c: '++' }));
  };

  const handleDecrement: MouseEventHandler<HTMLButtonElement> = (e) => {
    decrement(e);
    if (syncWithCart) dispatch(changeCartItemCount({ p: productId, c: '--' }));
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
    if (syncWithCart) dispatch(setCartItemCount({ p: productId, c: +e.target.value }));
  };

  return (
    <>
      <button className="counts-buttons__counts-minus" onClick={handleDecrement} data-minus>
        -
      </button>
      <input
        type="number"
        className="counts-buttons__counts-count"
        value={count}
        onChange={handleInputChange}
        onBlur={setDefaultCount}
        onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
      />
      <button className="counts-buttons__counts-plus" onClick={handleIncrement} data-plus>
        +
      </button>
    </>
  );
};

export default Counter;
