import './PostPage.scss';

import { intervalToDuration } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ArticleCloseIcon from '../../../general/assets/svg/ArticleCloseIcon';
import InstagranFooterIcon from '../../../general/assets/svg/InstagranFooterIcon';
import TelegramLinkFooterIcon from '../../../general/assets/svg/TelegramLinkFooterIcon';
import TwitterFooterIcon from '../../../general/assets/svg/TwitterFooterIcon';
import Preloader from '../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../general/queryKeys';
import { routesClient } from '../../../general/routes';
import createArticlesService from '../../../general/services/articles';
import createContactsService from '../../../general/services/contacts';
import { clientInstance } from '../../../general/services/main/axiosInstances';
import { Article, Contacts } from '../../../general/types/types';
import BreadcrumbSchema from '../../components/SEO/BreadcrumbSchemaProps';
import CanonicalLink from '../../components/SEO/CanonicalLink';

export const getTimeInterval = (createdAt: any) => {
  const date: any = intervalToDuration({
    start: new Date(createdAt),
    end: new Date(),
  });

  const keys = Object.keys(date);
  let interval = `by ASICXchange ${date[keys[0]]} ${keys[0]} ago`;

  return interval;
};

const PostPage: React.FC = () => {
  const { url } = useParams();
  const contentContainer = useRef<HTMLDivElement>(null);
  const [interval, setInterval] = useState<string>('');
  const navigate = useNavigate();

  const { data: contacts } = useCustomQuery<Contacts>(queryKeys.COMPANY_CONTACTS, () =>
    createContactsService(clientInstance).getContacts(),
  );

  const { data: article, isLoading: articleLoading } = useCustomQuery<Article>(
    'asd',
    () => {
      if (!url) {
        throw new Error('url is not defined');
      }
      return createArticlesService(clientInstance).getArticleByUrl(url);
    },
    undefined,
    undefined,
    {
      keepPreviousData: false,
    },
  );

  useEffect(() => {
    const doc = document.implementation.createHTMLDocument();
    doc.body.innerHTML = article?.content || '';

    const myContent = contentContainer.current;
    const container = document.createElement('div');

    container.innerHTML = doc.body.innerHTML;
    container.innerHTML = container.innerHTML.replace(/percent/gi, '%');

    if (myContent) {
      myContent.insertAdjacentElement('afterbegin', container);
    }

    // set time interval
    const interval = `${getTimeInterval(article?.createdAt)}`;
    setInterval(interval);
  }, [article, article?.content, article?.createdAt]);

  const closePost = () => {
    navigate('/blog');
  };

  const backgroundImg = {
    backgroundImage: `url(${article?.imageUrl})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  };

  const breadcrumbItems = [
    { name: 'Home', url: window.location.origin },
    { name: 'Blog', url: window.location.origin + routesClient.blog.path },
    { name: article?.title as string, url: window.location.href },
  ];

  return (
    <>
      <CanonicalLink href={window.location.href} />
      {articleLoading ? (
        <div className="page-containe post-page__preloader">
          <Preloader />
        </div>
      ) : (
        <>
          <BreadcrumbSchema items={breadcrumbItems} />
          <div className="post-page__close-btn">
            <button onClick={closePost}>
              <ArticleCloseIcon />
            </button>
          </div>
          <div className="post-page">
            <div className="post-page__header" style={backgroundImg}>
              <div className="post-page__header__container">
                <div className="post-page__header__blur"></div>
                <div className="post-page__header__info">
                  <div className="post-page__header__icons">
                    <a href={contacts?.instagramLink} rel="noopener noreferrer">
                      <InstagranFooterIcon color="#0E0E0F" />
                    </a>
                    <a href={contacts?.xLink} rel="noopener noreferrer">
                      <TwitterFooterIcon color="#0E0E0F" />
                    </a>
                    <a href={contacts?.telegramLink} rel="noopener noreferrer">
                      <TelegramLinkFooterIcon color="#0E0E0F" />
                    </a>
                  </div>
                  <div className="post-page__header__title">{article?.title}</div>
                  <div className="post-page__header__creator">{interval}</div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="post-page">
        <div ref={contentContainer} className="post-page__content"></div>
      </div>
    </>
  );
};

export default PostPage;
