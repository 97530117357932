import './Staff.scss';

import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SelectionControls from '../../../../client/components/inputs/SelectionControls';
import Preloader from '../../../../general/components/preloader/Preloader';
import useCustomMutation from '../../../../general/hooks/useCustomMutation';
import { useOptionSelector } from '../../../../general/hooks/useOptionSelector';
import { setInfo, setType, types } from '../../../../general/redux/reducers/Error-Reducer';
import { RootState } from '../../../../general/redux/store';
import createHomePageService from '../../../../general/services/homePage';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import getCachedImageUrl from '../../../../general/utils/getCachedImageUrl';
import Confirm from '../../../components/confirm/Confirm';
import AdminModalWindow from '../../../components/modal-window/ModalWindow';

interface FormData {
  order: number;
  fullName: string;
  position: string;
  linkedIn: string;
  isFired: boolean;
  currentIMG: string | File | null; // Обновленный тип
}

interface StaffEditorProps {
  isOpen: (isOpen: boolean) => void;
  refresh: () => void;
}

const StaffEditor: React.FC<StaffEditorProps> = ({ isOpen, refresh }) => {
  const dispatch = useDispatch();
  const staff = useSelector((state: RootState) => state.homeStatic.staffToEdit);

  const [deleteArticleAsk, setDeleteArticleAsk] = useState(false);
  const [currentAva, setCurrentAva] = useState<string>('');

  const isFilredOptions = [
    { name: 'status', value: true, label: 'Fired' },
    { name: 'status', value: false, label: 'Not Fired' },
  ];

  const { selectedOption: isFired, handleOptionChange } = useOptionSelector<boolean>({
    initialOption: staff?.isFired || false,
    onChange: (selectedOption) => {
      const newValue = selectedOption === true;
      setFormData({
        ...formData,
        isFired: newValue,
      });
    },
  });

  const [formData, setFormData] = useState<FormData>({
    order: staff?.orderNumber || 0,
    fullName: staff?.fullName || '',
    position: staff?.position || '',
    linkedIn: staff?.linkedIn || '',
    isFired: isFired,
    currentIMG: staff?.imageURL || '',
  });

  const { mutate: addNewSataff, isLoading: addStaffLoading } = useCustomMutation(
    createHomePageService(adminInstance).createNewStaff,
    undefined,
    {
      onSuccess: () => refresh(),
    },
  );

  const { mutate: updateSataff, isLoading: updateStaffLoading } = useCustomMutation(
    createHomePageService(adminInstance).updateStaff,
    undefined,
    {
      onSuccess: () => {
        dispatch(setType(types.SUCCESS));
        dispatch(
          setInfo({
            n: types.SUCCESS,
            r: { s: 'Staff successfully updated' },
          }),
        );

        refresh();
      },
    },
  );

  const { mutate: deleteSataff, isLoading: deleteStaffLoading } = useCustomMutation(
    createHomePageService(adminInstance).deleteStaff,
    undefined,
    {
      onSuccess: (data: any) => {
        dispatch(setType(types.WARNING));
        dispatch(
          setInfo({
            n: types.WARNING,
            r: { s: data },
          }),
        );

        isOpen(false);
        refresh();
      },
    },
  );

  useEffect(() => {
    if (staff) {
      setFormData({
        order: staff.orderNumber,
        fullName: staff.fullName,
        position: staff.position,
        linkedIn: staff.linkedIn,
        isFired: staff.isFired,
        currentIMG: staff.imageURL,
      });
    }
  }, [staff]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: name === 'order' ? parseInt(value, 10) || 0 : value,
    });
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({
          ...formData,
          currentIMG: file,
        });
        setCurrentAva(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
    event.target.value = '';
  };

  const isFormValid = () => {
    const { fullName, position, linkedIn, currentIMG } = formData;
    return fullName && position && linkedIn && currentIMG;
  };

  const handleSubmit = () => {
    if (isFormValid()) {
      staff === null
        ? addNewSataff({
            order: formData.order,
            body: {
              ...formData,
              currentIMG: formData.currentIMG as string,
            },
          })
        : updateSataff({
            id: staff?.id,
            order: formData.order,
            body: {
              ...formData,
              currentIMG: formData.currentIMG as string,
            },
          });
      isOpen(false);
    } else {
      dispatch(setType(types.WARNING));
      dispatch(
        setInfo({
          n: types.WARNING,
          r: { s: 'All fields must be filled, including the image' },
        }),
      );
    }
  };

  const cachedImageUrl = useMemo(
    () => getCachedImageUrl(formData.currentIMG as string),
    [formData.currentIMG],
  );

  return (
    <AdminModalWindow isOpen={isOpen} title={'Staff'} saveCallback={handleSubmit}>
      {(addStaffLoading || deleteStaffLoading || updateStaffLoading) && (
        <div className="modal-window-action__modal__home__loading">
          <Preloader />
        </div>
      )}
      <div className="modal-window-action__modal__staff">
        <div
          className="modal-window-action__modal__staff__ava"
          style={{
            backgroundImage: `url(${currentAva ? currentAva : cachedImageUrl})`,
          }}
          onClick={() => {
            setFormData({
              ...formData,
              currentIMG: null,
            });
          }}
        >
          {!formData.currentIMG && (
            <label htmlFor="upload-photo" className="img-loader">
              +
            </label>
          )}
          <input id="upload-photo" type="file" accept="image/*" onChange={handleImageChange} />
        </div>
        <div className="modal-window-action__modal__staff__inputs">
          <div className="top-section">
            <input
              type="number"
              placeholder="order"
              name="order"
              value={formData.order}
              onChange={handleInputChange}
            />
            <input
              type="text"
              placeholder="name"
              name="fullName"
              value={formData.fullName}
              onChange={handleInputChange}
            />
          </div>
          <input
            type="text"
            placeholder="position"
            name="position"
            value={formData.position}
            onChange={handleInputChange}
          />
          <input
            type="text"
            placeholder="linked in"
            name="linkedIn"
            value={formData.linkedIn}
            onChange={handleInputChange}
          />

          <div className="isFired_radio">
            <SelectionControls
              options={isFilredOptions}
              selectedOption={isFired}
              onChange={handleOptionChange}
            />
          </div>
        </div>
      </div>
      <div className="modal-window-action__modal__button">
        {staff && (
          <>
            <button className="delete-btn" onClick={() => setDeleteArticleAsk(true)}>
              Delete
            </button>
            {deleteArticleAsk && (
              <Confirm
                title="Delete confirm"
                text={'Are you shure want to delete staff?'}
                handler={setDeleteArticleAsk}
                clickEvent={() => {
                  deleteSataff(staff.id);
                }}
              />
            )}
          </>
        )}
        <button className="section-btn-gradient" onClick={handleSubmit}>
          Save
        </button>
      </div>
    </AdminModalWindow>
  );
};

export default StaffEditor;
