import React from 'react';

const TotalBalanceIcon: React.FC = () => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.8333 10.5C11.2754 10.5 11.6993 10.6756 12.0118 10.9882C12.3244 11.3007 12.5 11.7246 12.5 12.1667C12.5 12.6087 12.3244 13.0326 12.0118 13.3452C11.6993 13.6577 11.2754 13.8333 10.8333 13.8333H8.33333V10.5M10.8333 10.5H8.33333M10.8333 10.5C11.2754 10.5 11.6993 10.3244 12.0118 10.0118C12.3244 9.69928 12.5 9.27536 12.5 8.83333C12.5 8.39131 12.3244 7.96738 12.0118 7.65482C11.6993 7.34226 11.2754 7.16667 10.8333 7.16667H8.33333V10.5M10 18C11.9891 18 13.8968 17.2098 15.3033 15.8033C16.7098 14.3968 17.5 12.4891 17.5 10.5C17.5 8.51088 16.7098 6.60322 15.3033 5.1967C13.8968 3.79018 11.9891 3 10 3C8.01088 3 6.10322 3.79018 4.6967 5.1967C3.29018 6.60322 2.5 8.51088 2.5 10.5C2.5 12.4891 3.29018 14.3968 4.6967 15.8033C6.10322 17.2098 8.01088 18 10 18Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TotalBalanceIcon;
