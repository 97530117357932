import React from 'react';
import { Area, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import './CustomLineChart.scss';
import CustomTooltip from './CustomTooltrip';

interface CustomLineChartProps {
  isVisibleAsixes: boolean;
  data: any[];
  gradientId: string;
  graphicGradient: {
    offset: {
      first: string;
      second: string;
    };
    stopColor: {
      first: string;
      second: string;
    };
    stopOpacity: {
      first: string;
      second: string;
    };
  };
  lineGradientId: string;
  lineGradient: {
    offset: {
      first: string;
      second: string;
    };
    stopColor: {
      first: string;
      second: string;
    };
  };
}

const CustomLineChart: React.FC<CustomLineChartProps> = ({
  isVisibleAsixes,
  data,
  graphicGradient,
  gradientId,
  lineGradientId,
}) => {
  return (
    <ResponsiveContainer width="100%" height={180}>
      <ComposedChart data={data}>
        <defs>
          {/* Градиент для основной заливки */}
          <linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
            <stop
              offset={graphicGradient.offset.first}
              stopColor={graphicGradient.stopColor.first}
              stopOpacity={graphicGradient.stopOpacity.first}
            />
            <stop
              offset={graphicGradient.offset.second}
              stopColor={graphicGradient.stopColor.second}
              stopOpacity={graphicGradient.stopOpacity.second}
            />
          </linearGradient>

          {/* Градиент для линии */}
          <linearGradient id={lineGradientId} x1="0" y1="0" x2="0" y2="1">
            <stop
              offset={graphicGradient.offset.first}
              stopColor={graphicGradient.stopColor.first}
              stopOpacity="1"
            />
            <stop
              offset={graphicGradient.offset.second}
              stopColor={graphicGradient.stopColor.second}
              stopOpacity="1"
            />
          </linearGradient>
        </defs>

        {isVisibleAsixes && (
          <>
            <YAxis
              tick={isVisibleAsixes ? { fill: '#746A81', dy: -5 } : false}
              axisLine={isVisibleAsixes}
              tickLine={false}
            />
          </>
        )}

        <XAxis
          dataKey="date"
          tick={isVisibleAsixes ? { fill: '#746A81', dx: 5 } : false}
          axisLine={isVisibleAsixes}
          tickLine={false}
        />

        <Tooltip cursor={false} content={<CustomTooltip />} />

        {/* Основная заливка под графиком */}
        <Area
          type="monotone"
          dataKey="amount"
          stroke="none"
          fill={`url(#${gradientId})`}
          fillOpacity={1}
        />

        {/* Линия с тенью */}
        <Line
          type="monotone"
          dataKey="amount"
          stroke={`url(#${lineGradientId})`}
          strokeWidth={3}
          fill="none"
          dot={false}
          activeDot={{
            r: isVisibleAsixes ? 10 : 10,
            fill: '#25A8CF',
            strokeWidth: isVisibleAsixes ? 0 : 13,
            stroke: '#6976EB29',
          }}
          clipPath="none"
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default CustomLineChart;
