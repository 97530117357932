import React from 'react';

const ShoppingBasket: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.2299 20.6H6.76992C6.08873 20.6021 5.429 20.3618 4.90871 19.9221C4.38841 19.4824 4.04147 18.872 3.92992 18.2L2.41992 9.09998H21.5799L20.0699 18.2C19.9584 18.872 19.6114 19.4824 19.0911 19.9221C18.5708 20.3618 17.9111 20.6021 17.2299 20.6Z"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path d="M23.5 9.09998H0.5" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
    <path
      d="M7.20996 11.02V9.10002L10.08 2.40002"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M16.7899 11.02V9.10002L13.9199 2.40002"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path d="M12 12.9399V17.7299" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M8.16992 12.9399V17.7299" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M15.8301 12.9399V17.7299" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
  </svg>
);

export default ShoppingBasket;
