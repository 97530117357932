import React from 'react';

const FullVerificationIcon: React.FC = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 8.86719V6.86719C4 6.33675 4.21071 5.82805 4.58579 5.45297C4.96086 5.0779 5.46957 4.86719 6 4.86719H8M4 16.8672V18.8672C4 19.3976 4.21071 19.9063 4.58579 20.2814C4.96086 20.6565 5.46957 20.8672 6 20.8672H8M16 4.86719H18C18.5304 4.86719 19.0391 5.0779 19.4142 5.45297C19.7893 5.82805 20 6.33675 20 6.86719V8.86719M16 20.8672H18C18.5304 20.8672 19.0391 20.6565 19.4142 20.2814C19.7893 19.9063 20 19.3976 20 18.8672V16.8672M8 16.8672C8 16.3368 8.21071 15.828 8.58579 15.453C8.96086 15.0779 9.46957 14.8672 10 14.8672H14C14.5304 14.8672 15.0391 15.0779 15.4142 15.453C15.7893 15.828 16 16.3368 16 16.8672M10 9.86719C10 10.3976 10.2107 10.9063 10.5858 11.2814C10.9609 11.6565 11.4696 11.8672 12 11.8672C12.5304 11.8672 13.0391 11.6565 13.4142 11.2814C13.7893 10.9063 14 10.3976 14 9.86719C14 9.33675 13.7893 8.82805 13.4142 8.45297C13.0391 8.0779 12.5304 7.86719 12 7.86719C11.4696 7.86719 10.9609 8.0779 10.5858 8.45297C10.2107 8.82805 10 9.33675 10 9.86719Z"
      stroke="#B8C6CC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FullVerificationIcon;
