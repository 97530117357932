import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import Preloader from '../../../../../general/components/preloader/Preloader';
import useCustomMutation from '../../../../../general/hooks/useCustomMutation';
import useCustomQuery from '../../../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../../../general/queryKeys';
import { setInfo, setType, types } from '../../../../../general/redux/reducers/Error-Reducer';
import { RootState } from '../../../../../general/redux/store';
import createClientService from '../../../../../general/services/client';
import createF2PoolService from '../../../../../general/services/f2pool/f2Pool';
import { clientInstance } from '../../../../../general/services/main/axiosInstances';
import { User, VerificationResult } from '../../../../../general/types/types';
import './ProfileWalletSettings.scss';

const ProfileWalletSettings = () => {
  const dispatch = useDispatch();
  const userId = useSelector((state: RootState) => state.clps.cp2);
  const [walletAddress, setWalletAddress] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(true);
  const [message, setMessage] = useState<string>('');

  const { data: user } = useCustomQuery<User>(
    queryKeys.USER_INFO,
    () => createClientService(clientInstance).getClientInfo(userId),
    undefined,
    undefined,
    {
      enabled: !!userId,
      onSuccess(data) {
        if (data?.f2PoolUserAccountInfo) {
          setWalletAddress(data?.f2PoolUserAccountInfo?.wallets[0].address);
        }
      },
    },
  );

  const updatingResult = (verificationResult: VerificationResult) => {
    if (verificationResult?.isValid) {
      dispatch(setType(types.SUCCESS));
      dispatch(
        setInfo({
          n: types.SUCCESS,
          r: { s: 'Wallet address successfully updated' },
        }),
      );
      setMessage('Wallet address successfully varificated');
      setIsValid(true);
    } else {
      dispatch(setType(types.ERROR));
      dispatch(
        setInfo({
          n: types.ERROR,
          r: { s: 'Wallet address invalid. Try another address' },
        }),
      );
      setMessage('Wallet address is not varificated');
      setIsValid(false);
    }
  };

  const { mutate: updateWalletAddress, isLoading: varificating } = useCustomMutation(
    createF2PoolService(clientInstance).updateBTCWalletAddress,
    undefined,
    {
      onSuccess(data: VerificationResult | undefined) {
        if (data) {
          updatingResult(data);
        }
      },
    },
  );

  const { mutate: addWalletAddressAndF2poolAccount, isLoading: adding } = useCustomMutation(
    createF2PoolService(clientInstance).addBTCWalletAddressAndF2PoolAccount,
    undefined,
    {
      onSuccess(data: VerificationResult | undefined) {
        if (data) {
          updatingResult(data);
        }
      },
    },
  );

  const validateWalletAddress = (address: string): boolean => {
    // Простая валидация: длина 34 символа и только латинские буквы/цифры (пример для BTC)
    const isValidAddress = /^bc1[qpzry9x8gf2tvdw0s3jn54khce6mua7l]{25,61}$/.test(address);
    setIsValid(isValidAddress);
    setMessage(
      isValidAddress ? 'Wallet address is valid.' : 'Invalid wallet address. Please try again.',
    );
    return isValidAddress;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newAddress = e.target.value;
    setWalletAddress(newAddress);
    validateWalletAddress(newAddress);
  };

  const handleSave = () => {
    if (validateWalletAddress(walletAddress)) {
      if (user?.f2PoolUserAccountInfo?.wallets.some((wallet) => wallet.address === walletAddress)) {
        setIsValid(false);
        dispatch(setType(types.ERROR));
        dispatch(
          setInfo({
            n: types.ERROR,
            r: { s: 'Wallet address is the same as previous. Try another address' },
          }),
        );
        setMessage('Wallet address is the same as previous. Try another address');
        return;
      }

      if (user?.isF2PoolForClient || user?.f2PoolUserAccountInfo) {
        updateWalletAddress({
          userId: userId,
          walletAddress: walletAddress,
        });
      } else {
        addWalletAddressAndF2poolAccount({
          userId: userId,
          walletAddress: walletAddress,
        });
      }
    }
  };

  return (
    <div className="wallet-settings-container">
      <div className="wallet-address-settings">
        <input
          id="walletAddress"
          className={`client-text-field ${isValid ? 'valid' : 'invalid'}`}
          type="text"
          value={walletAddress}
          onChange={handleChange}
          placeholder="Enter your wallet address"
        />
        <button onClick={handleSave} className="section-btn-gradient" disabled={!isValid}>
          {varificating || adding ? <Preloader /> : 'Save Address'}
        </button>
      </div>
      <p className={`${isValid ? 'valid' : 'invalid'}`}>{message}</p>
    </div>
  );
};

export default ProfileWalletSettings;
