import React, { useEffect, useRef, useState } from 'react';

import PauseVideoIcon from '../../../general/assets/svg/PauseVideoIcon';
import ResumeVideoIcon from '../../../general/assets/svg/ResumeVideoIcon';
import Preloader from '../../../general/components/preloader/Preloader';
import { VideoData } from '../../../general/types/types';

//import { HomePageServices } from '../../services/HomePageServices';

interface VideoBlockProps {
  video: VideoData;
  isLoading: boolean;
}

const VideoBlock: React.FC<VideoBlockProps> = ({ video, isLoading }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlay, setIsPlay] = useState<boolean>(true);

  useEffect(() => {
    if (videoRef.current && video) {
      videoRef.current.load();

      // Устанавливаем звук в беззвучный режим
      videoRef.current.muted = true;

      // Эмулируем клик по видео, чтобы обойти ограничения браузеров
      videoRef.current.dispatchEvent(new Event('click'));

      videoRef.current.play().catch((error) => {
        console.error('Error trying to play the video: ', error);
      });
    }
  }, [video]);

  const videoPause = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      setIsPlay(false);
    }
  };

  const videoPlay = () => {
    if (videoRef.current) {
      // Убеждаемся, что звук отключен при воспроизведении
      videoRef.current.muted = true;
      videoRef.current.play();
      setIsPlay(true);
    }
  };

  return (
    <div className="video-block-container">
      {isLoading || !video ? (
        <>
          <Preloader />
        </>
      ) : (
        <>
          <div className="introduction-section__video-action">
            {isPlay ? (
              <div onClick={videoPause}>
                <PauseVideoIcon />
              </div>
            ) : (
              <div onClick={videoPlay}>
                <ResumeVideoIcon />
              </div>
            )}
          </div>
          {video.url && (
            <video
              ref={videoRef}
              controls={false}
              muted={true}
              playsInline={true}
              autoPlay={true}
              loop={true}
              src={video?.url}
              onClick={isPlay ? videoPause : videoPlay}
              onContextMenu={(e) => e.preventDefault()}
            />
          )}
        </>
      )}
    </div>
  );
};

export default VideoBlock;
