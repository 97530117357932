import { MouseEvent, MouseEventHandler, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addCartItemWithCount } from '../redux/reducers/ClientCart-Reducer';
import { SnackbarOptions, VariantTypes } from './useSnakbar';

interface UseAddToCartParams {
  miner: any;
  cart: any[];
  counterHook: { count: number };
  showSnackbar: (message: string, options: SnackbarOptions) => void;
}

const useAddToCart = ({
  miner,
  cart,
  counterHook,
  showSnackbar,
}: UseAddToCartParams): MouseEventHandler<HTMLButtonElement> => {
  const dispatch = useDispatch();

  return useCallback(
    (e: MouseEvent) => {
      e.preventDefault();

      if (miner && cart.findIndex((item: any) => item.p.id === miner.id) === -1) {
        showSnackbar('', { variant: VariantTypes.Success });
        dispatch(addCartItemWithCount({ p: miner, c: counterHook.count }));
        return;
      }

      const cartProduct = cart.find((item: any) => item.p.id === miner?.id);
      if (miner && cartProduct && cartProduct.c < 999) {
        showSnackbar('', { variant: VariantTypes.Success });
        if (cartProduct.c + counterHook.count > 999) {
          dispatch(addCartItemWithCount({ p: miner, c: 999 - cartProduct.c }));
          return;
        }
        dispatch(addCartItemWithCount({ p: miner, c: counterHook.count }));
      }
    },
    [miner, cart, counterHook, showSnackbar, dispatch],
  );
};

export default useAddToCart;
