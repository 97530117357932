import React from 'react';

const RecentTransactionIcon: React.FC = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.5 7H13.59C14.645 7 15.5 7.895 15.5 9C15.5 10.105 14.645 11 13.59 11C14.645 11 15.5 11.895 15.5 13C15.5 14.105 14.645 15 13.59 15H9.5M10.5 11H14.5M10.5 6V16V7M13.5 6V7M13.5 15V16M5.5 21V5C5.5 4.46957 5.71071 3.96086 6.08579 3.58579C6.46086 3.21071 6.96957 3 7.5 3H17.5C18.0304 3 18.5391 3.21071 18.9142 3.58579C19.2893 3.96086 19.5 4.46957 19.5 5V21L16.5 19L14.5 21L12.5 19L10.5 21L8.5 19L5.5 21Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RecentTransactionIcon;
