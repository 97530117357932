export interface Order {
  idFormatted: string;
  createdAt: string;
  totalProductsQuantity: number;
  totalPrice: number;
  isPaid: boolean;
  isQuickOrder: boolean;
  isCuverseMiners: boolean;
  cuverseMinersIds?: string[];
  firstName: string;
  lastName: string;
  userId: string;
  phoneNumber: string;
  email: string;
  contacts: string;
  receipt: string;
  stabelCoinReciept: string;
  orderStatuses: { name: string; createdAt: string }[];
  transaction: {
    addressTo: Address;
    billingAddress: Address;
    status: string;
    updatingDateTime: string;
  };
  productsStoresTransaction: {
    billingAddress: Address;
    productsStores: ProductsStoreAddress[];
  };
  orderedProducts: {
    id: string;
    url: string;
    image: { url: string };
    name: string;
    quantity: number;
    price: number;
    percentageDiscount: number;
  }[];
  orderNumber: string;
  id: string;
  isUploadLaterBankTransactionReciept: boolean;
  tax: number;
}

export interface HttpResponse<T> {
  code: string;
  config: any;
  data: T;
  headers: any;
  request: any;
  status: number;
  statusText: string;
  message: string;
  name: string;
  response: {
    config: any;
    data: {
      detail: string;
      status: number;
      title: string;
    };
    headers: any;
    request: any;
    status: number;
    statusText: string;
  };
}

export interface Subscriber {
  id: string;
  isActive: boolean;
  email: string;
}

export interface Newsletter {
  id: string;
  title: string;
  content: string;
  wasPosted: boolean;
  timeToPost: string;
}

export interface VideoData {
  url: string;
}

export interface FieldConfig {
  input: HTMLInputElement | HTMLTextAreaElement;
  regex?: RegExp;
  validateFunc?: (
    input: HTMLInputElement | HTMLTextAreaElement,
    errorField: string,
    required: boolean[],
    optional: boolean,
    errors: ValidationError[],
  ) => void;
  errorField: string;
  errorMessage?: string;
  optional?: boolean;
}

export interface Address {
  name: string;
  lastName: string;
  phone: string;
  email: string;
  country: string;
  city: string;
  state: string;
  street1: string;
  street2?: string;
  street3?: string;
  streetNumber?: string;
  zip: string;
}

export interface TransactionProcessAddress {
  name: string;
  lastName: string;
  company: string;
  phone?: string;
  email?: string;
  country: string;
  city: string;
  state: string;
  street1: string;
  street2?: string;
  street3?: string;
  streetNumber?: string;
  zip: string;
  metadata?: string;

  [key: string]: any;
}

export interface CartItem {
  p: ProductMax | CuverseAsicx;
  c: number;
  url?: string;
}

export interface UserCartForOrde {
  pc: CartItem[];
  count: number;
}

export interface ContactMethod {
  name: string;
  value: string;
}

export interface OrderProduct {
  productId: string;
  productName: string;
  quantity: number;
}

export interface InfoBlcok {
  id: string;
  title: string;
  content: string;
  buttonText: string;
  buttonIconUrl: string;
  imageUrl: string;
}

export interface CompanyPertner {
  id: string;
  name: string;
  url: string;
  imageId: string;
  imageUrl: string;
}

export interface ToResponceAddresses {
  addressFrom: TransactionProcessAddress;
  addressTo: TransactionProcessAddress;
  parcelTables?: any[];
  billingAddress?: TransactionProcessAddress;
}

export interface AboutUsImg {
  id: string;
  url: string;
}

export interface TransactionDTO {
  billingAddressId: string;
  addressFromId: string;
  addressToId: string;
  objectId?: string;
  labelFileType?: string;
  parcelsIds?: string[];
}

export interface TransactionBody {
  transactionDTO: TransactionDTO;
}

export interface OrderBody {
  userId: string;
  orderedProducts: OrderProduct[];
  transactionId: string;
  contacts: { name: string; value: string };
  shippingPriceForUnit: number;
}

export interface HostingLocationStates {
  Available: string;
  ComingSoon: string;
  FullyBooked: string;
}

export interface IHostingLocation {
  id: string;
  locationName: string;
  state: string;
  characteristics: {
    id: string;
    key: string;
    value: string;
  }[];
  images: {
    id: string;
    imageUrl: string;
  }[];
  mapPoinerLocationX: number;
  mapPoinerLocationY: number;
  isActive: boolean;
}

export interface Contacts {
  addressWeb: string;
  phoneWeb: string;
  emailWeb: string;
  telegramLink: string;
  instagramLink: string;
  facebookLink: string;
  linkedinLink: string;
  xLink: string;
  whatsappLink: string;
}

export interface Rates {
  billingAddressId: string;
  idAddressFrom: string;
  idAddressTo: string;
  parselsIds?: string[];
}

export interface Testimonial {
  imageUrl: string;
  username: string;
  country: string;
  rating: number;
  content: string;
}

export interface TestimonialFull {
  id: string;
  content: string;
  username: string;
  country: string;
  rating: number;
  imageId: string;
  imageUrl: string;
}

export interface ProductMin {
  id: string;
  url: string;
  manufacturer: string;
  name: string;
  price: number;
  hashrate: string;
  amount: number;
  energyConsumption: string;
  energyEfficiency: string;
  condition: string;
  algorithm: string;
  countryLocation: string;
}

export interface ProductMax {
  id: string;
  url: string;
  condition?: string;
  description: string;
  characteristics: { name: string; value: string }[];
  percentageDiscount: number;

  manufacturer: string;
  name: string;
  price: number;
  amount: number;
  batch?: string;
  countryLocation?: string;
  hashrate: string;
  energyConsumption: number;
  energyEfficiency: number;
  stores?: ProductsStoreAddress[];
  [key: string]: any;

  categoryId: string;
  images: { url: string }[];
  image: {
    url: string;
  };
}

export interface Admin {
  id: string;
  userName: string;
  name: string;
  surname: string;
  email: string;
}

export interface Link {
  affiliateId: string;
  name: string;
  link: string;
  isActive: boolean;
}

export interface Refferal {
  id: string;
  code: string;
  createdAt: string;
  referralLink: string;
  affiliate: {
    id: string;
    firstName: string;
    lastName: string;
    nickName: string;
  };
  isActive: boolean;
}

export interface ReferralCode {
  id: number;
  referralLink: string;
  // Add other properties as needed
}

export interface Links {
  [key: string]: Link[];
}

export interface Affiliate {
  id?: string;
  firstName: string;
  lastName: string;
  nickName: string;
  socialNetworks: any[];
}

export interface Client {
  id: string;
  name: string;
  surname: string;
  phoneNumber: string;
  email: string;
}

export interface ICategory {
  id: string;
  name: string;
  url: string;
  imageUrl: string | null;
  retailProductsCount: number;
  wholesaleProductsCount: number;
  areMiningCharacteristics: boolean;
}

export interface IManufacturer {
  id: string;
  name: string;
}

export interface Staff {
  currentIMG: string;
  fullName: string;
  position: string;
  linkedIn: string;
  isFired: boolean;
}

export interface StaffView {
  id: string;
  orderNumber: number;
  imageURL: string;
  fullName: string;
  position: string;
  linkedIn: string;
  isFired: boolean;
}

export interface Admin {
  id: string;
  userName: string;
  name: string;
  surname: string;
  email: string;
}

export interface PaggingObj<T> {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  items: T[];
  page: number;
  pageSize: number;
  totalCount: number;
}

export interface ShippingCost {
  lastId: string;
  shippingCost: string;
}

export interface AvailableProdLocations {
  id: string;
  country: string;
}

export interface ProductsStoreAddress {
  id: string;
  country: string;
  address: string;
  status: boolean;
  mapFrame: string;
}

export interface AdminEmail {
  id: string;
  email: string;
  orderNotification: boolean;
  contactMessageNotification: boolean;
}

export interface Article {
  content: string;
  createdAt: string;
  description: string;
  id: string;
  imageUrl: string;
  isActive: boolean;
  minutesToRead: number;
  title: string;
  url: string;
}

export interface User {
  id: string;
  name: string;
  surname: string;
  phoneNumber?: string;
  email?: string;
  emailConfirmed: boolean;
  isF2PoolForClient: boolean;
  telegram?: string;
  createdAt: string;
  f2PoolUserAccountInfo?: {
    wallets: {
      address: string;
      currency: string;
    }[];
  };
  virtualWallet?: {
    id: string;
    userId: string;
    currentBalance: number;
    balanceHistories: Array<{
      balance: number;
      recordedAt: string;
      walletId: string;
    }>;
    transactions?: Array<{
      amount: number;
      createdAt: string;
      description: string;
      type: string; // Например, 'Deposit', можно сделать строковым литералом
      walletId: string;
      wallet?: any; // Если структура `wallet` известна, можно уточнить
    }>;
  };
}

export interface VerificationResult {
  isValid: boolean;
  address: string;
  type: string;
  network: string;
  format: string;
  length: number;
  error: string;
}

export interface ValidationError {
  field: string;
  message: string;
}

export interface Location {
  id: string;
  country: string;
}

export interface Characteristic {
  id: string;
  name: string;
  value: string;
}

export interface AdditionalCharacteristicProd {
  id: number;
  order?: number;
  name?: string;
  value?: string;
}

export interface ShopLink {
  priceCategoryCount: number;
  machineCategoryCount: string | null;
}

export interface SimpleMessage {
  id: string;
  contactName: string;
  phone: string;
  date: string;
  isProcessed: boolean;
}

export interface Message {
  id: string;
  name: string;
  companyName: string;
  phoneNumber: string;
  email: string;
  message: string;
  createdAt: string;
  isProcessed: boolean;
}

export interface CuverseAsicx {
  cashback: number;
  code: string;
  coin: string;
  count: number;
  dailyElectricityFee: number;
  efficiency: string;
  estDailyOutput: number;
  hashrate: number;
  id: string;
  isNew: boolean;
  managementFee: string;
  model: string;
  oldPrice: number;
  period: number;
  picture: string;
  poolName: string;
  powerConsumption: number;
  price: number;
  pricePerKw: string;
  sellLeader: boolean;
  seller?: string;
  soldOut: boolean;
  sort: number;
  startDelay: number;
  startsAt: number;
  unitSymbol: string;
}

export interface AsicModelDTO {
  id: string;
  manufacturerName: string;
  images: {
    id: string;
    url: string;
  }[];
  nameVariants: { id: string; variant: string }[];
}

export interface CryptoData {
  name: string;
  usd: number;
  usd_24h_change: number;
}

export const coinMap: { [key: string]: string } = {
  BTC: 'bitcoin',
  ETH: 'ethereum',
  BCH: 'bitcoin-cash',
  LTC: 'litecoin',
  DOGE: 'dogecoin',
  DOT: 'polkadot',
  ADA: 'cardano',
  SOL: 'solana',
  LINK: 'chainlink',
  XLM: 'stellar',
  MATIC: 'matic-network',
  TRX: 'tron',
  AVAX: 'avalanche-2',
  TON: 'the-open-network',
  ETC: 'ethereum-classic',
  XMR: 'monero',
  OKB: 'okb',
  XRP: 'ripple',
  SHIB: 'shiba-inu',
  ATOM: 'cosmos',
  UNI: 'uniswap',
};
