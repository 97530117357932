import React, { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';

import useCustomMutation from '../../../../general/hooks/useCustomMutation';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../../general/queryKeys';
import { setInfo, setType, types } from '../../../../general/redux/reducers/Error-Reducer';
import createF2PoolService from '../../../../general/services/f2pool/f2Pool';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import createStaticPaymentInfosService from '../../../../general/services/staticPayments';

const ElectricityCost: React.FC = () => {
  const dispatch = useDispatch();
  const [electricityCost, setElectricityCost] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(true);

  const { data: electricityCostData } = useCustomQuery(
    queryKeys.ELECTRICITY_COST,
    () => createStaticPaymentInfosService(adminInstance).getInfos(),
    (data: any) => ({ lastId: data[0].id, elecricityCost: data[0].elecricityCost }),
    undefined,
    {
      onSuccess(data) {
        setElectricityCost(data.elecricityCost);
      },
    },
  );

  const { mutate: saveElectricityCost } = useCustomMutation(
    createF2PoolService(adminInstance).setElectricityCost,
    undefined,
    {
      onSuccess: () => {
        dispatch(setType(types.SUCCESS));
        dispatch(
          setInfo({
            n: types.SUCCESS,
            r: { s: 'Electricity cost successfully updated' },
          }),
        );
      },
    },
  );

  const handleElectricityCost = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setElectricityCost(value);

    // Проверка, содержит ли значение только цифры
    const valid = /^[0-9]*[.,]?[0-9]+$/.test(value);
    setIsValid(valid);
  };

  const handleSaveClick = async () => {
    if (isValid && electricityCost) {
      let cost = parseFloat(electricityCost);

      if (!Number.isInteger(cost)) {
        cost = parseFloat(cost.toFixed(2));
      }

      if (electricityCostData) {
        saveElectricityCost({
          infosId: electricityCostData?.lastId,
          elecricityCostPreKW: cost,
        });
      }
    } else {
      dispatch(setType(types.WARNING));
      dispatch(
        setInfo({
          n: types.WARNING,
          r: { s: 'Must contains only numbers' },
        }),
      );
    }
  };

  return (
    <>
      <span className="block-header">Electricity cost pre kw/s</span>
      <div>
        <input
          type="number"
          placeholder="Electricity cost pre kw/s"
          value={electricityCost}
          onChange={handleElectricityCost}
        />
        {electricityCost && (
          <button className="section-btn-gradient" onClick={handleSaveClick}>
            Save
          </button>
        )}
      </div>
    </>
  );
};

export default ElectricityCost;
