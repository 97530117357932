import React from 'react';

const ThinArrow: React.FC = () => (
  <svg width="60" height="15" viewBox="0 0 60 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M59.5001 8.20662C59.8906 7.81609 59.8906 7.18293 59.5001 6.7924L53.1361 0.428444C52.7456 0.0379195 52.1124 0.0379195 51.7219 0.428444C51.3314 0.818968 51.3314 1.45213 51.7219 1.84266L57.3788 7.49951L51.7219 13.1564C51.3314 13.5469 51.3314 14.1801 51.7219 14.5706C52.1124 14.9611 52.7456 14.9611 53.1361 14.5706L59.5001 8.20662ZM0.792969 8.49951H58.793V6.49951H0.792969V8.49951Z"
      fill="white"
    />
  </svg>
);

export default ThinArrow;
